import React, { useState, useEffect } from 'react';
import { Text, Group, Stack, Paper } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import { IconChevronRight } from '@tabler/icons-react';

import { ExperimentsChannelPicker } from '../../../experiments/components/FinanceChannelPicker';
import { ExperimentsVideoPicker } from '../../../experiments/components/FinanceVideoPicker';
import { ExperimentsChartPicker } from '../../../experiments/components/FinanceChartPicker';
import WeibullVisualization from '../../../experiments/components/WeibullChart';
import DailyWeibullVisualization from '../../../experiments/components/DailyWeibullChart';
import AlphaVisualization from '../../../experiments/components/AlphaChart';
import BetaVisualization from '../../../experiments/components/BetaChart';
import { SkeletonLoader } from '../../../core/SkeletonLoader';

import { 
  getVideoWeibullAnalysis, 
  getVideoDailyWeibullAnalysis,
  getVideoAlphaAnalysis, 
  getVideoBetaAnalysis,
  WeibullAnalysisResponse,
  DailyWeibullAnalysisResponse,
  AlphaAnalysisResponse,
  BetaAnalysisResponse 
} from '../../../../utils/api';

const navItems = [
  { title: 'Channels', href: '/channels/channel' },
  { title: 'Videos', href: '/channels/video/video' },
  { title: 'Video', href: '/channels/video/video' },
  { title: 'Compare Videos', href: '/channels/video/video-comparison' },
  { title: 'Video Analysis', href: '/channels/video/video-analysis' },
  { title: 'Notes', href: 'https://brick-river-8a5.notion.site/Video-Analyses-12c6777fc95580ed9a51c5a6948c208b', isExternal: true },
];

type ChartType = 'weibull' | 'daily-weibull' | 'alpha' | 'beta';

export function ExperimentsPatternAnalysis() {
  const [selectedChannel, setSelectedChannel] = useState<string | null>(null);
  const [selectedVideo, setSelectedVideo] = useState<string | null>(null);
  const [selectedChart, setSelectedChart] = useState<ChartType>('alpha');
  const [weibullData, setWeibullData] = useState<WeibullAnalysisResponse | null>(null);
  const [dailyWeibullData, setDailyWeibullData] = useState<DailyWeibullAnalysisResponse | null>(null);
  const [alphaData, setAlphaData] = useState<AlphaAnalysisResponse | null>(null);
  const [betaData, setBetaData] = useState<BetaAnalysisResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (selectedChannel && selectedVideo) {
      fetchAnalysisData();
    }
  }, [selectedChannel, selectedVideo]);

  const fetchAnalysisData = async () => {
    if (!selectedChannel || !selectedVideo) return;

    setLoading(true);
    setError(null);

    try {
      const [weibull, dailyWeibull, alpha, beta] = await Promise.all([
        getVideoWeibullAnalysis(selectedChannel, selectedVideo),
        getVideoDailyWeibullAnalysis(selectedChannel, selectedVideo),
        getVideoAlphaAnalysis(selectedChannel, selectedVideo),
        getVideoBetaAnalysis(selectedChannel, selectedVideo)
      ]);

      setWeibullData(weibull.data);
      setDailyWeibullData(dailyWeibull.data);
      setAlphaData(alpha.data);
      setBetaData(beta.data);
    } catch (err) {
      console.error('Error fetching analysis data:', err);
      setError('Failed to fetch analysis data');
    } finally {
      setLoading(false);
    }
  };

  const renderVisualization = () => {
    if (!selectedChannel || !selectedVideo) {
      return (
        <Paper p="md" radius="md" withBorder>
          <Text ta="center" size="lg" c="dimmed">
            Select a channel and video to begin analysis
          </Text>
        </Paper>
      );
    }

    if (loading) {
      return <SkeletonLoader count={1} height={600} radius="sm" />;
    }

    if (error) {
      return (
        <Paper p="md" radius="md" withBorder>
          <Text ta="center" size="lg" color="red">
            {error}
          </Text>
        </Paper>
      );
    }

    // Render appropriate visualization based on selected chart type
    const renderChart = () => {
      switch (selectedChart) {
        case 'weibull':
          return weibullData ? <WeibullVisualization data={weibullData} /> : null;
        case 'daily-weibull':
          return dailyWeibullData ? <DailyWeibullVisualization data={dailyWeibullData} /> : null;
        case 'alpha':
          return alphaData ? <AlphaVisualization data={alphaData} /> : null;
        case 'beta':
          return betaData ? <BetaVisualization data={betaData} /> : null;
        default:
          return null;
      }
    };

    return (
      <Paper
        p="md"
        radius="md"
        withBorder
        style={{
          transition: 'box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out',
        }}
        onMouseEnter={(e) => {
          e.currentTarget.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.1)';
          e.currentTarget.style.transform = 'translateY(-5px)';
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.boxShadow = '';
          e.currentTarget.style.transform = '';
        }}
      >
        {renderChart()}
      </Paper>
    );
  };

  return (
    <Stack gap="xs">
      <Group gap={5}>
        {navItems.map((item, index) => (
          <React.Fragment key={item.title}>
            {index > 0 && index < 3 && <IconChevronRight size={14} color='#868e96'/>}
            {index > 2 && <Text size="sm" color="dimmed">|</Text>}
            {item.isExternal ? (
              <Text
                component="a"
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
                size="sm"
                c="#0982eb"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {item.title}
              </Text>
            ) : (
              <Text
                component={Link}
                to={item.href}
                size="sm"
                fw={index >= 2 && location.pathname === item.href ? 500 : 'normal'}
                td={index >= 2 && location.pathname === item.href ? 'underline' : 'none'}
                c={index < 2 ? 'dimmed' : '#0982eb'}
              >
                {item.title}
              </Text>
            )}
          </React.Fragment>
        ))}
      </Group>

      {/* Selection Controls */}
      <Group align="flex-start" mb="md">
        <ExperimentsChannelPicker
          value={selectedChannel}
          onChange={setSelectedChannel}
        />
        <ExperimentsVideoPicker
          channelId={selectedChannel}
          onChange={setSelectedVideo}
        />
        <ExperimentsChartPicker
          value={selectedChart}
          onChange={setSelectedChart}
        />
      </Group>

      {/* Visualization */}
      {renderVisualization()}
    </Stack>
  );
}

export default ExperimentsPatternAnalysis;