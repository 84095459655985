import { SimulationNodeDatum, SimulationLinkDatum } from 'd3-force';

export interface TrendingNetworkNode extends SimulationNodeDatum {
  id: string;
  title: string;
  channel_id: string;
  channel_name: string;
  publish_date: { value: string };
  views: number;
  trending_region: string;
  trending_date: { value: string };
  isTrending: boolean;
  rank?: number;
  x?: number;
  y?: number;
  vx?: number;
  vy?: number;
  fx?: number | undefined;
  fy?: number | undefined;
}

export interface TrendingNetworkLink extends SimulationLinkDatum<TrendingNetworkNode> {
  source: string | TrendingNetworkNode;
  target: string | TrendingNetworkNode;
  edge_type: string;
}

export interface NodeNeighborMap {
  [nodeId: string]: {
    neighbors: string[];
    links: TrendingNetworkLink[];
  };
}

export function processTrendingNetworkData(
  data: any,
  selectedRegion: string,
  selectedDate: string
): { nodes: TrendingNetworkNode[], links: TrendingNetworkLink[] } {
  console.log("Processing data:", data);
  console.log("Region:", selectedRegion, "Date:", selectedDate);

  if (!data || !data.nodes || !data.links) {
    console.error("Invalid data structure:", data);
    return { nodes: [], links: [] };
  }

  const nodes: TrendingNetworkNode[] = data.nodes.map((node: any) => ({
    ...node,
    id: node.id || node.videoId,
    isTrending: Boolean(node.rank), // Assume any node with a rank is a trending video
    x: node.x || undefined,
    y: node.y || undefined,
    vx: node.vx || 0,
    vy: node.vy || 0,
    fx: node.fx || undefined,
    fy: node.fy || undefined
  }));

  const nodeMap = new Map(nodes.map(node => [node.id, node]));

  const links: TrendingNetworkLink[] = data.links.map((link: any) => ({
    source: nodeMap.get(link.source) || link.source,
    target: nodeMap.get(link.target) || link.target,
    edge_type: link.edge_type
  }));

  console.log("Processed result. Nodes:", nodes.length, "Links:", links.length);

  return { nodes, links };
}

export function createNodeNeighborMap(nodes: TrendingNetworkNode[], links: TrendingNetworkLink[]): NodeNeighborMap {
  const neighborMap: NodeNeighborMap = {};

  nodes.forEach(node => {
    neighborMap[node.id] = { neighbors: [], links: [] };
  });

  links.forEach(link => {
    const sourceId = typeof link.source === 'string' ? link.source : link.source.id;
    const targetId = typeof link.target === 'string' ? link.target : link.target.id;

    if (!neighborMap[sourceId].neighbors.includes(targetId)) {
      neighborMap[sourceId].neighbors.push(targetId);
      neighborMap[sourceId].links.push(link);
    }

    if (!neighborMap[targetId].neighbors.includes(sourceId)) {
      neighborMap[targetId].neighbors.push(sourceId);
      neighborMap[targetId].links.push(link);
    }
  });

  return neighborMap;
}