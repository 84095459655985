import React from 'react';
import { Badge, Tooltip } from '@mantine/core';
import { VideoClassification, VideoClassificationCategory } from '../../../utils/api';

interface ClassificationBadgeProps {
  classification: VideoClassification;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  style?: React.CSSProperties;
}

const classificationConfig: Record<VideoClassificationCategory, {
  emoji: string;
  color: string;
  description: string;
}> = {
  'UNICORN': {
    emoji: '🦄',
    color: '#FF1493',
    description: "This video is in the top 10% of tracked videos by views for its channel."
  },
  'STAR': {
    emoji: '⭐️',
    color: '#FFD700',
    description: "A standout. A video that has above median total views and above median first 7 day views. Yesterday's view gain was not above the median."
  },
  'EVERGREEN_PLUS': {
    emoji: '🎄',
    color: '#228B22',
    description: 'Continues to perform in the long run and is performing very well now. A video with above median total views and below median first 7 day views which gained above median views yesterday.'
  },
  'EVERGREEN': {
    emoji: '🌲',
    color: '#006400',
    description: 'Continues to perform in the long run. A video with above median total views and below median first 7 day views.'
  },
  'ROCKET': {
    emoji: '🚀',
    color: '#FF4500',
    description: 'Takes off but burns up. A video with less than median total views but which has above median first 7 day views.'
  },
  'ZOMBIE': {
    emoji: '🧟‍♂️',
    color: '#9370DB',
    description: 'Looks dead, but might be coming back to life. Less than median total views, less than median first 7 day views, but above median daily change yesterday.'
  },
  'FOSSIL': {
    emoji: '🪨',
    color: '#808080',
    description: 'Looks dead, acts dead. Less than median total, first 7 day and daily views yesterday.'
  }
};

export const ClassificationBadge: React.FC<ClassificationBadgeProps> = ({
  classification,
  size = 'sm',
  style
}) => {
  const { category, isTracked } = classification.classification;
  const config = classificationConfig[category];
  
  const badgeContent = (
    <Badge
      size={size}
      radius="sm"
      variant="filled"
      color={config.color}
      style={{
        ...style,
        opacity: isTracked ? 1 : 0.7
      }}
    >
      <span style={{ fontSize: '1.5em', marginRight: '4px' }}>{config.emoji}</span>
      <span>{category.replace('_', ' ')}</span>
    </Badge>
  );

  const tooltipText = isTracked 
    ? config.description
    : `When last tracked: ${config.description}`;

  return (
    <Tooltip
      label={tooltipText}
      multiline
      maw={300}
      position="top"
      withinPortal
    >
      {badgeContent}
    </Tooltip>
  );
};