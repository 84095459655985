import { SimulationNodeDatum, SimulationLinkDatum } from 'd3-force';

export interface VideoSubgraphNode extends SimulationNodeDatum {
  id: string;
  title: string;
  channel_id: string;
  channel_name: string;
  publish_date: { value: string };
  node_type: string;
  x?: number;
  y?: number;
  vx?: number;
  vy?: number;
  fx?: number | undefined;
  fy?: number | undefined;
}

export interface VideoSubgraphLink extends SimulationLinkDatum<VideoSubgraphNode> {
  source: string | VideoSubgraphNode;
  target: string | VideoSubgraphNode;
  edge_type: string;
}

export interface NodeNeighborMap {
  [nodeId: string]: {
    neighbors: string[];
    links: VideoSubgraphLink[];
  };
}

export function processVideoSubgraphData(
  data: any
): { nodes: VideoSubgraphNode[], links: VideoSubgraphLink[] } {
  console.log("Processing video subgraph data:", data);

  if (!data || !data.nodes || !data.links) {
    console.error("Invalid data structure:", data);
    return { nodes: [], links: [] };
  }

  const nodes: VideoSubgraphNode[] = data.nodes.map((node: any) => ({
    id: node.id,
    title: node.title,
    channel_id: node.channelId,
    channel_name: node.channelTitlePretty,
    publish_date: { value: node.publishDate },
    node_type: node.type,
    x: Math.random() * 1000,
    y: Math.random() * 1000,
    vx: 0,
    vy: 0,
    fx: undefined,
    fy: undefined
  }));

  const nodeMap = new Map(nodes.map(node => [node.id, node]));

  const links: VideoSubgraphLink[] = data.links.map((link: any) => ({
    source: nodeMap.get(link.source) || link.source,
    target: nodeMap.get(link.target) || link.target,
    edge_type: link.edgeType
  }));

  console.log("Processed result. Nodes:", nodes.length, "Links:", links.length);

  return { nodes, links };
}

export function createNodeNeighborMap(nodes: VideoSubgraphNode[], links: VideoSubgraphLink[]): NodeNeighborMap {
  const neighborMap: NodeNeighborMap = {};

  nodes.forEach(node => {
    neighborMap[node.id] = { neighbors: [], links: [] };
  });

  links.forEach(link => {
    const sourceId = typeof link.source === 'string' ? link.source : link.source.id;
    const targetId = typeof link.target === 'string' ? link.target : link.target.id;

    if (!neighborMap[sourceId].neighbors.includes(targetId)) {
      neighborMap[sourceId].neighbors.push(targetId);
      neighborMap[sourceId].links.push(link);
    }

    if (!neighborMap[targetId].neighbors.includes(sourceId)) {
      neighborMap[targetId].neighbors.push(sourceId);
      neighborMap[targetId].links.push(link);
    }
  });

  return neighborMap;
}