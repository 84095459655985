import React, { useState, useEffect } from 'react';
import { Stack, Text, Group, Paper } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import { IconChevronRight, IconExternalLink } from '@tabler/icons-react';
import { SkeletonLoader } from '../../../core/SkeletonLoader';
import ComparisonChannelPicker from '../../components/PickTwoChannels';
import ChannelComparisonChart from '../../components/ChannelComparisonChart';
import { getChannelChartData, getChannelSummary } from '../../../../utils/api';

// Navigation items for the comparison view
const navItems = [
  { title: 'Channels', href: '/channels/channels/channel' },
  { title: 'Channels', href: '/channels/channels/channel' },
  { title: 'Channel', href: '/channels/channels/channel' },
  { title: 'Top Five', href: '/channels/channels/top-five' },
  { title: 'Compare Channels', href: '/channels/channels/compare' },
  { title: 'Timeline', href: '/channels/channels/timeline' },
  { title: 'Classification', href: '/channels/channels/classification' },
  { title: 'Notes', href: 'https://brick-river-8a5.notion.site/Compare-Channels-1336777fc955809b9a98fb4a8d4422d2', isExternal: true },
];

export function ChannelComparison() {
  const [channel1, setChannel1] = useState<string | null>(null);
  const [channel2, setChannel2] = useState<string | null>(null);
  const [channel1Name, setChannel1Name] = useState<string>('');
  const [channel2Name, setChannel2Name] = useState<string>('');
  const [channel1ViewsData, setChannel1ViewsData] = useState<any[]>([]);
  const [channel2ViewsData, setChannel2ViewsData] = useState<any[]>([]);
  const [channel1LibraryData, setChannel1LibraryData] = useState<any[]>([]);
  const [channel2LibraryData, setChannel2LibraryData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();

  // Fetch data for a single channel
  const fetchChannelData = async (channelId: string) => {
    const [viewsResponse, libraryResponse, summaryResponse] = await Promise.all([
      getChannelChartData(`/channels/${channelId}/daily-change/views`),
      getChannelChartData(`/channels/${channelId}/daily-change/library_size`),
      getChannelSummary(channelId)
    ]);

    return {
      viewsData: viewsResponse.data,
      libraryData: libraryResponse.data,
      name: summaryResponse.data.channelName
    };
  };

  // Effect to fetch data when channels are selected
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      
      try {
        if (channel1) {
          const data = await fetchChannelData(channel1);
          setChannel1ViewsData(data.viewsData);
          setChannel1LibraryData(data.libraryData);
          setChannel1Name(data.name);
        }

        if (channel2) {
          const data = await fetchChannelData(channel2);
          setChannel2ViewsData(data.viewsData);
          setChannel2LibraryData(data.libraryData);
          setChannel2Name(data.name);
        }
      } catch (err) {
        console.error('Error fetching channel data:', err);
        setError('Failed to fetch channel data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    if (channel1 || channel2) {
      fetchData();
    }
  }, [channel1, channel2]);

  const renderChart = () => {
    if (!channel1 && !channel2) {
      return (
        <Paper p="xl" radius="md" withBorder>
          <Text ta="center" size="lg" c="dimmed">
            Select channels to compare their daily views
          </Text>
        </Paper>
      );
    }

    if (loading) {
      return <SkeletonLoader count={1} height={600} radius="sm" />;
    }

    if (error) {
      return (
        <Paper p="xl" radius="md" withBorder>
          <Text color="red" ta="center">{error}</Text>
        </Paper>
      );
    }

    if (channel1ViewsData.length > 0 || channel2ViewsData.length > 0) {
      return (
        <Paper
          p="md"
          radius="md"
          withBorder
          style={{
            transition: 'box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out',
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.1)';
            e.currentTarget.style.transform = 'translateY(-5px)';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.boxShadow = '';
            e.currentTarget.style.transform = '';
          }}
        >
          <ChannelComparisonChart 
            channel1Data={{
              viewsData: channel1ViewsData,
              libraryData: channel1LibraryData,
              name: channel1Name
            }}
            channel2Data={{
              viewsData: channel2ViewsData,
              libraryData: channel2LibraryData,
              name: channel2Name
            }}
          />
        </Paper>
      );
    }

    return null;
  };

  return (
    <Stack gap="md">
      {/* Navigation */}
      <Group gap={5}>
        {navItems.map((item, index) => (
          <React.Fragment key={item.title}>
            {index > 0 && index < 3 && <IconChevronRight size={14} color='#868e96'/>}
            {index > 2 && <Text size="sm" color="dimmed">|</Text>}
            {item.isExternal ? (
              <Text
                component="a"
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
                size="sm"
                c="#0982eb"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {item.title}
                <IconExternalLink size={14} style={{ marginLeft: 5 }} />
              </Text>
            ) : (
              <Text
                component={Link}
                to={item.href}
                size="sm"
                fw={index >= 2 && location.pathname === item.href ? 500 : 'normal'}
                td={index >= 2 && location.pathname === item.href ? 'underline' : 'none'}
                c={index < 2 ? 'dimmed' : '#0982eb'}
              >
                {item.title}
              </Text>
            )}
          </React.Fragment>
        ))}
      </Group>

      {/* Channel Selection */}
      <ComparisonChannelPicker
        channel1={channel1}
        channel2={channel2}
        onChannel1Change={setChannel1}
        onChannel2Change={setChannel2}
        channel1Name={channel1Name}
        channel2Name={channel2Name}
      />

      {/* Chart Area */}
      {renderChart()}
    </Stack>
  );
}

export default ChannelComparison;