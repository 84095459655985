import React, { useState, useEffect, useCallback } from 'react';
import { Group, Box, Grid, Text, Stack, Alert, Paper, Select } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import { IconChevronRight, IconExternalLink, IconChartScatter } from '@tabler/icons-react';
import { SkeletonLoader } from '../../core/SkeletonLoader';
import { ScatterChart, XAxis, YAxis, Tooltip, Scatter, ResponsiveContainer, Cell, CartesianGrid } from 'recharts';
import { getTrendingDailyData } from '../../../utils/api';
import { formatDateUK } from '../../../utils/dateUtils';
import { CustomDatePicker } from '../components/CustomDatePicker';
import { MetricPicker } from '../components/TrendingMetricPicker';
import { formatNumber } from '../../../utils/formatter';

const navItems = [
  { title: 'Trending', href: '/trending/yesterday' },
  { title: 'Yesterday', href: '/trending/yesterday'},
  { title: 'Performance', href: '/trending/performance'},
  { title: 'Networks', href: '/trending/networks'},
  { title: 'Data', href: '/trending/data'},
  { title: 'Notes', href: 'https://brick-river-8a5.notion.site/Trending-Performance-11f6777fc95580eab953de8d8357b2e8', isExternal: true},
];

const regionOptions = [
  { value: 'US', label: '🇺🇸 United States' },
  { value: 'GB', label: '🇬🇧 United Kingdom' },
];

const getCustomColor = (index: number, total: number): string => {
  const darkBlue = [13, 63, 166];  // #08306b
  const lightBlue = [109, 174, 213];  // #6daed5
  
  const r = Math.round(darkBlue[0] + (lightBlue[0] - darkBlue[0]) * (index / (total - 1)));
  const g = Math.round(darkBlue[1] + (lightBlue[1] - darkBlue[1]) * (index / (total - 1)));
  const b = Math.round(darkBlue[2] + (lightBlue[2] - darkBlue[2]) * (index / (total - 1)));
  
  return `rgb(${r}, ${g}, ${b})`;
};

interface PerformanceData {
  videoId: string;
  rank: number;
  title: string;
  channelName: string;
  publishDate: string;
  views: number;
  comments: number;
  conversationRate: number;
}

type MetricKey = 'views' | 'comments' | 'conversationRate';

const getMetricLabel = (metric: MetricKey): string => {
  switch (metric) {
    case 'views':
      return 'Views';
    case 'comments':
      return 'Comments';
    case 'conversationRate':
      return 'Conversation Rate';
    default:
      return '';
  }
};

const formatMetricValue = (value: number, metric: MetricKey): string => {
  if (metric === 'conversationRate') {
    return value.toFixed(2);
  }
  return formatNumber(value);
};

export function TrendingPerformance() {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedRegion, setSelectedRegion] = useState<string>('US');
  const [selectedMetric, setSelectedMetric] = useState<MetricKey>('views');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [performanceData, setPerformanceData] = useState<PerformanceData[]>([]);
  const location = useLocation();

  const fetchData = useCallback(async () => {
    if (!selectedDate) return;
    setLoading(true);
    setError(null);
    try {
      const response = await getTrendingDailyData(selectedDate, selectedRegion);
      setPerformanceData(response.data);
    } catch (err) {
      console.error('Error fetching trending data:', err);
      setError('Failed to fetch trending data. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, [selectedDate, selectedRegion]);

  useEffect(() => {
    if (selectedDate) {
      fetchData();
    }
  }, [selectedDate, fetchData]);

  const handleDateChange = useCallback((date: Date) => {
    setSelectedDate(date);
  }, []);

  const CustomTooltip: React.FC<any> = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload as PerformanceData;
      const metricValue = data[selectedMetric];
      
      return (
        <div style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}>
          <Text size="sm" fw={500}>{data.title}</Text>
          <Text size="sm">{data.channelName}</Text>
          <Text size="sm" fs="italic" c="dimmed">Published: {formatDateUK(new Date(data.publishDate))}</Text>
          <Text size="sm">{getMetricLabel(selectedMetric)}: {formatMetricValue(metricValue, selectedMetric)}</Text>
          <Text size="sm">Trending Rank: {data.rank}</Text>
        </div>
      );
    }
    return null;
  };

  const renderScatterChart = () => (
    <Box>
      <ResponsiveContainer width="100%" height={600}>
        <ScatterChart margin={{ top: 20, right: 20, bottom: 40, left: 40 }}>
        <CartesianGrid strokeDasharray="3 3" />
          <XAxis 
            type="number" 
            dataKey="rank" 
            name="Rank" 
            domain={[1, 50]}
            ticks={[1, 10, 20, 30, 40, 50]}
            label={{ value: 'Rank', position: 'bottom' }}
          />
          <YAxis 
            type="number" 
            dataKey={selectedMetric} 
            name={getMetricLabel(selectedMetric)} 
            tickFormatter={(value) => formatMetricValue(value, selectedMetric)}
            label={{ value: getMetricLabel(selectedMetric), angle: -90, position: 'insideLeft', offset: -10 }}
          />
          <Tooltip content={<CustomTooltip />} />
          <Scatter name="Videos" data={performanceData}>
            {performanceData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={getCustomColor(entry.rank - 1, 49)} />
            ))}
          </Scatter>
        </ScatterChart>
      </ResponsiveContainer>
    </Box>
  );
  
  return (
    <Stack gap="xs">
      <Group gap={5}>
      {navItems.map((item, index) => (
          <React.Fragment key={item.title}>
            {index > 0 && index < 2 && <IconChevronRight size={14} color='#868e96'/>}
            {index > 1 && <Text size="sm" color="dimmed">|</Text>}
            {item.isExternal ? (
              <Text
                component="a"
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
                size="sm"
                c="#0982eb"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {item.title}
                <IconExternalLink size={14} style={{ marginLeft: 5 }} />
              </Text>
            ) : (
              <Text
                component={Link}
                to={item.href}
                size="sm"
                fw={index >= 1 && location.pathname === item.href ? 500 : 'normal'}
                td={index >= 1 && location.pathname === item.href ? 'underline' : 'none'}
                c={index < 1 ? 'dimmed' : '#0982eb'}
              >
                {item.title}
              </Text>
            )}
      </React.Fragment>
        ))}
      </Group>
      <Stack gap="xs">
        <Grid gutter="xs">
          <Grid.Col span="content">
            <CustomDatePicker onChange={handleDateChange} />
          </Grid.Col>
          <Grid.Col span="content">
            <Select
              data={regionOptions}
              value={selectedRegion}
              onChange={(value) => setSelectedRegion(value || 'US')}
            />
          </Grid.Col>
          <Grid.Col span="content">
            <MetricPicker
              value={selectedMetric}
              onChange={(value) => setSelectedMetric(value)}
            />
          </Grid.Col>
        </Grid>
        <Paper
          p="md" 
          radius="md" 
          withBorder
          style={{ 
              transition: 'box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out',
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.1)';
            e.currentTarget.style.transform = 'translateY(-5px)';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.boxShadow = '';
            e.currentTarget.style.transform = '';
          }}
        >
          <Group align="center" mb="sm">
            <IconChartScatter size={20} />
            <Text size="md" fw={400} fs="italic">
              Trending Videos in {selectedRegion === 'US' ? '🇺🇸' : '🇬🇧'} on {selectedDate ? formatDateUK(selectedDate) : 'Selected Date'} by rank and {selectedMetric === 'conversationRate' ? 'Conversation Rate' : selectedMetric}
            </Text>
          </Group>
          {error && (
            <Alert color="red" title="Error">
              {error}
            </Alert>
          )}
          {loading ? (
            <SkeletonLoader count={1} height={500} radius="sm" />
          ) : (
            renderScatterChart()
          )}
        </Paper>
      </Stack>
    </Stack>
  );
}
