import React from 'react';
import {
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { Text, Group, Box, Badge, Tooltip as MantineTooltip } from '@mantine/core';
import { IconChartBar } from '@tabler/icons-react';
import { formatNumber, formatDate } from '../../../utils/formatter';

interface DailyWeibullProps {
  data: {
    metadata: {
      title: string;
      publishDate: string;
      contentType: string;
    };
    weibullParams: {
      lambda: number;
      k: number;
    };
    analysisData: {
      daysLive: number;
      dailyChange: number;
      hazardRate: number;
      weibullBound: {
        predicted: number;
        lower: number;
        upper: number;
      };
      actualDate: string;
    }[];
    confidenceScore: number;
  };
}

const getContentTypeColor = (contentType: string): string => {
  switch (contentType) {
    case 'Short': return '#FFA500';
    case 'Video': return '#FF0000';
    case 'Podcast': return '#9C27B0';
    case 'Live': return '#007BFF';
    case 'Premiere': return '#607D8B';
    case 'Music Video': return '#FF0000';
    case 'Course': return '#FF0000';
    default: return '#0d3fa6';
  }
};

const DailyWeibullVisualization: React.FC<DailyWeibullProps> = ({ data }) => {
  const { metadata, weibullParams, analysisData, confidenceScore } = data;
  const contentTypeColor = getContentTypeColor(metadata.contentType);
  
  const CustomTooltip: React.FC<any> = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <Box className="custom-tooltip" p="md" bg="white" style={{ border: '1px solid #ccc' }}>
          <Text size="sm" fw={500}>Day {data.daysLive}</Text>
          <Text size="xs" c="dimmed">{formatDate(data.actualDate)}</Text>
          <Text size="sm">Daily Views: {formatNumber(data.dailyChange)}</Text>
          <Text size="sm">Predicted: {formatNumber(data.weibullBound.predicted)}</Text>
          <Text size="sm">Range: {formatNumber(data.weibullBound.lower)} - {formatNumber(data.weibullBound.upper)}</Text>
          <Text size="sm">Hazard Rate: {(data.hazardRate * 100).toFixed(4)}%</Text>
        </Box>
      );
    }
    return null;
  };

  return (
    <Box>
      <Group align="center" mb="md" gap="apart">
        <Group>
          <IconChartBar size={20} />
          <Text size="md" fw={500}>
            Daily Change Analysis for "{metadata.title.length > 50 ? metadata.title.substring(0, 47) + '...' : metadata.title}"
          </Text>
        </Group>
        <Group>
          <MantineTooltip
            label={weibullParams.k < 1 ?
              "Shows rapid initial engagement followed by declining daily views" :
              "Shows sustained or growing daily engagement patterns"
            }
            multiline
            w={200}
          >
            <Badge color={weibullParams.k < 1 ? "blue" : "orange"}>
              {weibullParams.k < 1 ? 'Rapid Decay' : 'Sustained Engagement'}
            </Badge>
          </MantineTooltip>
          <MantineTooltip
            label="λ (lambda) represents the scale of daily views"
            multiline
            w={200}
          >
            <Text size="sm">λ = {weibullParams.lambda.toFixed(2)}</Text>
          </MantineTooltip>
          <MantineTooltip
            label="k represents the shape of daily view patterns - values < 1 indicate rapid decay, values > 1 indicate sustained viewing"
            multiline
            w={200}
          >
            <Text size="sm">k = {weibullParams.k.toFixed(2)}</Text>
          </MantineTooltip>
          {/* <MantineTooltip
            label="Confidence score based on data quality and pattern fit"
            multiline
            w={200}
          >
            <Badge color={confidenceScore > 0.7 ? "green" : confidenceScore > 0.4 ? "yellow" : "red"}>
              {(confidenceScore * 100).toFixed(0)}% confidence
            </Badge>
          </MantineTooltip> */}
        </Group>
      </Group>

      <ResponsiveContainer width="100%" height={600}>
        <ComposedChart
          data={analysisData}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="daysLive"
            tickFormatter={(value) => `Day ${value}`}
          />
          <YAxis
            tickFormatter={(value) => formatNumber(value)}
          />
          <Tooltip content={<CustomTooltip />} />

          {/* Actual daily changes */}
          <Bar
            dataKey="dailyChange"
            fill={contentTypeColor}
            fillOpacity={0.6}
            isAnimationActive={true}
            animationDuration={2000}
          />

          {/* Predicted line */}
          <Line
            type="monotone"
            dataKey="weibullBound.predicted"
            stroke="#000000"
            strokeWidth={2}
            dot={false}
            isAnimationActive={true}
            animationDuration={2000}
          />

          {/* Bounds */}
          <Line
            type="monotone"
            dataKey="weibullBound.upper"
            stroke="#808080"
            strokeDasharray="5 5"
            strokeWidth={1}
            dot={false}
            isAnimationActive={true}
            animationDuration={2000}
          />
          <Line
            type="monotone"
            dataKey="weibullBound.lower"
            stroke="#808080"
            strokeDasharray="5 5"
            strokeWidth={1}
            dot={false}
            isAnimationActive={true}
            animationDuration={2000}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default DailyWeibullVisualization;