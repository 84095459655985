import React, { useState, useEffect } from 'react';
import { Box, Grid, Text, Group, Stack, Alert } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import { SkeletonLoader } from '../../core/SkeletonLoader';
import { TrendingVideoCarousel } from '../components/TrendingVideoCarousel';
import { IconChevronRight, IconExternalLink } from '@tabler/icons-react';
import { getTrendingCarouselData } from '../../../utils/api';
import { formatDateUK } from '../../../utils/dateUtils';
import { CustomDatePicker } from '../components/CustomDatePicker';

const navItems = [
  { title: 'Trending', href: '/trending/yesterday' },
  { title: 'Yesterday', href: '/trending/yesterday'},
  { title: 'Performance', href: '/trending/performance'},
  { title: 'Networks', href: '/trending/networks'},
  { title: 'Data', href: '/trending/data'},
  { title: 'Notes', href: 'https://brick-river-8a5.notion.site/Trending-Yesterday-11f6777fc95580b4a6d2ce2862f05a45', isExternal: true},
];


export function TrendingSummary() {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [usCarouselData, setUsCarouselData] = useState<any[]>([]);
  const [gbCarouselData, setGbCarouselData] = useState<any[]>([]);
  const location = useLocation();

  useEffect(() => {
    if (selectedDate) {
      fetchData(selectedDate);
    }
  }, [selectedDate]);

  const fetchData = async (date: Date) => {
    setLoading(true);
    setError(null);
    try {
      const [usCarouselResponse, gbCarouselResponse] = await Promise.all([
        getTrendingCarouselData(date, 'US'),
        getTrendingCarouselData(date, 'GB'),
      ]);

      setUsCarouselData(usCarouselResponse.data);
      setGbCarouselData(gbCarouselResponse.data);

      if (usCarouselResponse.data.length === 0 && gbCarouselResponse.data.length === 0) {
        setError(`No data available for ${formatDateUK(date)}. Please select a different date.`);
      }
    } catch (err) {
      setError('Failed to fetch trending data. Please try again later.');
      console.error('Error fetching trending data:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
  };

  return (
    <Stack gap="xs">
      <Group gap={5}>
        {navItems.map((item, index) => (
          <React.Fragment key={item.title}>
            {index > 0 && index < 2 && <IconChevronRight size={14} color='#868e96'/>}
            {index > 1 && <Text size="sm" color="dimmed">|</Text>}
            {item.isExternal ? (
              <Text
                component="a"
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
                size="sm"
                c="#0982eb"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {item.title}
                <IconExternalLink size={14} style={{ marginLeft: 5 }} />
              </Text>
            ) : (
              <Text
                component={Link}
                to={item.href}
                size="sm"
                fw={index >= 1 && location.pathname === item.href ? 500 : 'normal'}
                td={index >= 1 && location.pathname === item.href ? 'underline' : 'none'}
                c={index < 1 ? 'dimmed' : '#0982eb'}
              >
                {item.title}
              </Text>
            )}
      </React.Fragment>
        ))}
      </Group>
      <Box>
        <Stack gap="md">
          <Group justify="center" gap="sm">
            <CustomDatePicker onChange={handleDateChange} />
          </Group>
          {error && (
            <Alert color="red" title="Error">
              {error}
            </Alert>
          )}
          {loading ? (
            <SkeletonLoader count={2} height={400} radius="sm" />
          ) : (
            <>
              <Grid gutter="md">
                {(['US', 'GB'] as const).map((region) => (
                  <Grid.Col span={6} key={region}>
                    <TrendingVideoCarousel
                      videos={region === 'US' ? usCarouselData : gbCarouselData}
                      region={region}
                    />
                  </Grid.Col>
                ))}
              </Grid>
            </>
          )}
        </Stack>
      </Box>
    </Stack>
  );
}