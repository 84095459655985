import React from 'react';
import { ResponsiveContainer, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { Text } from '@mantine/core';
import { formatNumber, formatDate } from '../../../utils/formatter';

interface BaseChartProps {
  data: any[];
  xDataKey: string;
  yDataKey: string;
  areas: Array<{
    dataKey: string;
    stroke: string;
    fill: string;
  }>;
  startDate: string;
  firstTrackedDate: string | null;
  lastTrackedDate: string;
}

const BaseChart: React.FC<BaseChartProps> = ({
  data,
  xDataKey,
  yDataKey,
  areas,
  startDate,
  firstTrackedDate,
  lastTrackedDate
}) => {
  return (
    <ResponsiveContainer width="100%" height={600}>
      <AreaChart data={data} margin={{ top: 10, right: 30, left: 30, bottom: 60 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis 
          dataKey={xDataKey} 
          tick={<CustomizedAxisTick />}
          ticks={[startDate, ...(firstTrackedDate ? [firstTrackedDate] : []), lastTrackedDate]}
        />
        <YAxis
          dataKey={yDataKey}
          tickFormatter={(value) => formatNumber(value)}
        />
        <Tooltip content={<CustomTooltip yDataKey={yDataKey} />} />
        <defs>
          {areas.map((area, index) => (
            <linearGradient key={index} id={`color${area.dataKey}`} x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={area.fill} stopOpacity={0.8}/>
              <stop offset="95%" stopColor={area.fill} stopOpacity={0.2}/>
            </linearGradient>
          ))}
        </defs>
        {areas.map((area, index) => (
          <Area
            key={index}
            type="monotone"
            dataKey={area.dataKey}
            strokeWidth={1}
            stroke={area.stroke}
            fill={`url(#color${area.dataKey})`}
            fillOpacity={1}
            connectNulls
          />
        ))}
      </AreaChart>
    </ResponsiveContainer>
  );
};

const CustomizedAxisTick: React.FC<any> = ({ x, y, payload }) => (
  <g transform={`translate(${x},${y})`}>
    <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)" fontSize={12}>
      {formatDate(payload.value)}
    </text>
  </g>
);


const CustomTooltip: React.FC<any> = ({ active, payload, label, yDataKey }) => {
  if (active && payload && payload.length) {
    const yLabels: Record<string, string> = {
      totalSubscribers: 'Total Subscribers',
      totalViews: 'Total Views',
      totalVideos: 'Library Size',
    };

    const yLabel = yLabels[yDataKey as keyof typeof yLabels] || yDataKey;

    return (
      <div style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}>
        <Text size="sm" c="dimmed" fs="italic">{`Date: ${formatDate(label)}`}</Text>
        <Text size="sm">{`${yLabel}: ${formatNumber(payload[0].payload[yDataKey])}`}</Text>
        {payload.map((entry: any, index: number) => {
          if (entry.dataKey !== 'tracked' && entry.dataKey !== 'preTracking') {
            return (
              <Text key={index} size="sm" c={entry.color}>
                {`${entry.name}: ${entry.value !== null ? formatNumber(entry.value) : 'N/A'}`}
              </Text>
            );
          }
          return null;
        })}
      </div>
    );
  }
  return null;
};

export default BaseChart;
