import React from 'react';
import { Combobox, InputBase, Group, useCombobox } from '@mantine/core';
import { IconChartAreaFilled, IconChartBarPopular, IconChartCovariate } from '@tabler/icons-react';

type GraphType = 'relative' | 'total' | 'daily';

const graphTypes: { value: GraphType; label: string; icon: React.ReactNode }[] = [
  { value: 'relative', label: 'Expected', icon: <IconChartCovariate size={16} /> },
  { value: 'total', label: 'Total', icon: <IconChartAreaFilled size={16} /> },
  { value: 'daily', label: 'Daily Change', icon: <IconChartBarPopular size={16} /> },
];

interface GraphTypePickerProps {
  value: GraphType;
  onChange: (value: GraphType) => void;
}

export function GraphTypePicker({ value, onChange }: GraphTypePickerProps) {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
  });

  const selectedType = graphTypes.find(type => type.value === value);

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={(val) => {
        onChange(val as GraphType);
        combobox.closeDropdown();
      }}
    >
      <Combobox.Target>
        <InputBase
          component="button"
          pointer
          rightSection={<Combobox.Chevron />}
          onClick={() => combobox.toggleDropdown()}
          rightSectionPointerEvents="none"
          style={{ width: '100%', maxWidth: '200px' }}
        >
          {selectedType ? (
            <Group gap="sm">
              {selectedType.icon}
              <span>{selectedType.label}</span>
            </Group>
          ) : (
            <span>Select graph type</span>
          )}
        </InputBase>
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options>
          {graphTypes.map((type) => (
            <Combobox.Option value={type.value} key={type.value}>
              <Group gap="sm">
                {type.icon}
                <span>{type.label}</span>
              </Group>
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}