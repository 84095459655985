import axios from 'axios';
import { format } from 'date-fns';

const api = axios.create({
  baseURL: '/api',
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Logout API call
export const logout = () => api.post('/auth/logout');

// Trending API calls
export const getTrendingSummary = (selectedDate: Date) => {
  const formattedDate = format(selectedDate, 'yyyy-MM-dd');
  return api.get('/trending/summary', { params: { date: formattedDate } });
};

export const getTrendingVideos = (selectedDate: Date, region: string, page: number = 1, pageSize: number = 50) => {
  const formattedDate = format(selectedDate, 'yyyy-MM-dd');
  return api.get('/trending/videos', { params: { date: formattedDate, region, page, pageSize } });
};



// Trending Networks API calls
export const getTrendingNetwork = (selectedDate: Date, region: string) => {
  const formattedDate = format(selectedDate, 'yyyy-MM-dd');
  return api.get('/trending-networks/trending-network', { params: { date: formattedDate, region } });
};

// Trending Available Dates API calls
export const getTrendingAvailableDates = () => api.get('/trending/available-dates');

// Channel API calls
export const getFullyTrackedChannels = () => 
  api.get('/channels/fully-tracked');

// Channel Calendar API calls
export const getChannelCalendarData = (channelId: string, contentType: string = 'All') =>
  api.get(`/channels/${channelId}/calendar`, { params: { contentType } });

// Channel Treemap API calls
interface TreemapResponse {
  data: any;
  channelName: string;
  channelViews: number;
}

export const getChannelTopicsTreemapData = async (channelId: string): Promise<TreemapResponse> => {
  const response = await api.get(`/channels/${channelId}/topics-treemap`);
  return {
    data: response.data,
    channelName: await getChannelName(channelId),
    channelViews: await getChannelViews(channelId)
  };
};

export const getChannelWikipediaTopicsTreemapData = async (channelId: string): Promise<TreemapResponse> => {
  const response = await api.get(`/channels/${channelId}/wikipedia-topics-treemap`);
  return {
    data: response.data,
    channelName: await getChannelName(channelId),
    channelViews: await getChannelViews(channelId)
  };
};

export const getChannelTypesTreemapData = async (channelId: string): Promise<TreemapResponse> => {
  const response = await api.get(`/channels/${channelId}/types-treemap`);
  return {
    data: response.data,
    channelName: await getChannelName(channelId),
    channelViews: await getChannelViews(channelId)
  };
};

async function getChannelName(channelId: string): Promise<string> {
  const response = await api.get(`/channels/${channelId}/summary`);
  return response.data.channelName;
}

async function getChannelViews(channelId: string): Promise<number> {
  const response = await api.get(`/channels/${channelId}/summary`);
  return Number(response.data.totalViews);
}

// Channel Model Topics Box Plot API call
export const getChannelModelTopicsBoxPlotData = (channelId: string) =>
  api.get(`/channels/${channelId}/model-topics-boxplot`);

// Channel Model Topics Grid API call
export const getChannelModelTopicsGridData = (channelId: string) =>
  api.get(`/channels/${channelId}/model-topics-grid`);

// Channel Model Topics Video Grid API call
export const getChannelModelTopicsVideoGridData = (channelId: string, page: number = 1, pageSize: number = 50) =>
  api.get(`/channels/${channelId}/model-topics-video-grid`, { params: { page, pageSize } });

// Channel Performance 2D Scatter API call
export const getChannelPerformanceData = (channelId: string) => 
  api.get(`/channels/${channelId}/performance`);

// Channel Performance 3D Scatter API call
export const getChannelPerformance3DData = (channelId: string) => 
  api.get(`/channels/${channelId}/performance-3d`);

// Channel Performance Top 5 API call
export const getChannelTop5Data = (channelId: string, contentType: string) => 
  api.get(`/channels/${channelId}/top-5`, { params: { contentType } });

// Trending V2s
// GET /api/trending/trending-daily-data  
export const getTrendingDailyData = (date: Date, region: string) => 
  api.get('/trending/trending-daily-data', {
    params: {
      date: date.toISOString().split('T')[0],
      region
    }
  });
// GET /api/trending/trending-carousel-data
export const getTrendingCarouselData = (date: Date, region: string) => 
  api.get('/trending/trending-carousel-data', {
    params: {
      date: date.toISOString().split('T')[0],
      region
    }
  });

// GET /api/trending/trending-carousel-data
export const getTrendingOverlap = (date: Date) => 
  api.get('/trending/trending-overlap', {
    params: {
      date: date.toISOString().split('T')[0]
    }
  });

// Channel Networks API call
export const getChannelNetwork = (channelId: string, edgeTypes: string[] = []) => {
  const params = new URLSearchParams();
  if (edgeTypes.length > 0) {
    params.append('edgeType', edgeTypes.join(','));
  }
  return api.get(`/channel-networks/${channelId}${params.toString() ? `?${params.toString()}` : ''}`);
};

// Video Subgraph API calls
// Video Subgraph API call
export const getVideoSubgraph = (channelId: string, videoId: string) => {
  return api.get(`/channel-networks/${channelId}/video-subgraph/${videoId}`);
};

// GET UMAP
export const getChannelUMAPData = (channelId: string) => {
  return api.get(`/channels/${channelId}/umap-data`);
};

export const getTopicSummary = (channelId: string, topicId: string) => {
  return api.get(`/channels/${channelId}/topic-summary/${topicId}`);
};

// GET Channel Videos Data
export const getChannelVideosData = (channelId: string, page: number, pageSize: number) =>
  api.get(`/channels/${channelId}/videos-data`, { params: { page, pageSize } });

// GET Channel Summary
export const getChannelSummary = (channelId: string) =>
  api.get(`/channels/${channelId}/summary`);

// GET Channel Chart Options
export const getChannelChartOptions = (channelId: string) =>
  api.get(`/channels/${channelId}/chart-options`);

// GET Channel Subgraph
export const getSubgraph = (channelId: string, nodeId: string) =>
  api.get(`/channel-networks/${channelId}/subgraph/${nodeId}`);

// GET Node Types
export const getNodeTypes = (channelId: string) =>
  api.get(`/channel-networks/${channelId}/node-types`);  

// GET Nodes of Type
export const getNodesOfType = (channelId: string, nodeType: string) =>
  api.get(`/channel-networks/${channelId}/nodes/${nodeType}`);

// GET Content Types
export const getContentTypes = (channelId: string) =>
  api.get<{ contentTypes: string[], hasShorts: boolean }>(`/channels/${channelId}/content-types`);

// Video Page API Calls
// Get Channel Videos for VideoPicker
export const getChannelVideos = async (channelId: string, contentType: string) => {
  try {
    const response = await api.get(`/channels/${channelId}/videos`, {
      params: { contentType }
    });
    
    return response.data.map((video: any) => ({
      id: video.id,
      title: video.title,
      type: video.type,
      views: video.views,
      publishDate: video.publishDate
    }));
  } catch (error) {
    console.error('Error fetching channel videos:', error);
    throw error;
  }
};

// INTERFACES FOR VIDEOS PAGE
interface VideoMetadata {
  id: string;
  title: string;
  publishDate: string;
  type: string;
  modelTopic: string;
  wikipediaTopic: string;
  videoLength: string;
  firstTrackedDate: string;
}

interface VideoPerformance {
  totalViews: number;
  totalLikes: number;
  totalComments: number;
  viewsChange: number | null;
  likesChange: number | null;
  commentsChange: number | null;
  conversationRate: number;
  likeRate: number;
  rankAll: number;
  rankType: number;
  lastUpdated: string;
}

interface PerformanceHistoryItem {
  date: string;
  views: number;
  likes: number;
  comments: number;
  daysLive: number;
}

// GET Videos Page calls
export const getChannelVideosPage = (channelId: string, videoId: string) =>
  api.get<VideoMetadata>(`/channels/${channelId}/videos-page/${videoId}/metadata`);

export const getChannelVideosPagePerformance = (channelId: string, videoId: string) =>
  api.get<VideoPerformance>(`/channels/${channelId}/videos-page/${videoId}/performance`);

export const getChannelVideosPagePerformanceHistory = (channelId: string, videoId: string) =>
  api.get<PerformanceHistoryItem[]>(`/channels/${channelId}/videos-page/${videoId}/performance-history`);

// Video Page Bar Chart Daily call
export const getVideoPerformanceDailyData = (channelId: string, videoId: string) =>
  api.get(`/channels/${channelId}/videos/${videoId}/performance/daily`);

// Video Page Total Chart call
export const getVideoPerformanceTotalData = (channelId: string, videoId: string) =>
  api.get(`/channels/${channelId}/videos/${videoId}/performance/total`);

// GET Video Page Available Charts
export const getVideoAvailableCharts = (channelId: string, videoId: string) =>
  api.get(`/channels/${channelId}/videos/${videoId}/available-charts`);

// GET Video Page Relative Chart
export const getVideoPerformanceRelativeData = (channelId: string, videoId: string) =>
  api.get(`/channels/${channelId}/videos/${videoId}/performance/relative`);


// GET Swarm Chart calls
export const getChannelModelTopicsSwarmData = (channelId: string, metric: string, excludeShorts: boolean) =>
  api.get(`/channels/${channelId}/model-topics-swarm`, { params: { metric, excludeShorts } });

export const getChannelYouTubeTopicsSwarmData = (channelId: string, metric: string, excludeShorts: boolean) =>
  api.get(`/channels/${channelId}/youtube-topics-swarm`, { params: { metric, excludeShorts } });

export const getChannelTypesSwarmData = (channelId: string, metric: string) =>
  api.get(`/channels/${channelId}/types-swarm`, { params: { metric } });

export const getChannelAgeSwarmData = (channelId: string, metric: string, excludeShorts: boolean) =>
  api.get(`/channels/${channelId}/age-swarm`, { params: { metric, excludeShorts } });

// GET Timeline V2
export const getChannelTimelineData = (channelId: string, timelineType: string) =>
  api.get(`/channels/${channelId}/timeline?type=${encodeURIComponent(timelineType)}`);

export const getChannelTimelineSummary = (channelId: string, timelineType: string) =>
  api.get(`/channels/${channelId}/timeline-summary?type=${encodeURIComponent(timelineType)}`)

// Top 10 Videos API call
export const getTop10Videos = (channelId: string, type: 'daily' | 'total', excludeShorts: boolean) =>
  api.get(`/channels/${channelId}/top-10-videos`, {
    params: { type, excludeShorts }
  });

// Channel Chart API calls
export const getChannelChartData = (endpoint: string) =>
  api.get(endpoint);

// Stacked Views API calls for Channels
export const getStackedViews = (channelId: string) =>
  api.get(`/channels/${channelId}/stacked-views`);

export const getStackedViewsTracked = (channelId: string) =>
  api.get(`/channels/${channelId}/stacked-views-tracked`);

export const getStackedLibrary = (channelId: string) =>
  api.get(`/channels/${channelId}/stacked-library`);

export const getStackedLibraryTracked = (channelId: string) =>
  api.get(`/channels/${channelId}/stacked-library-tracked`);

// Enhanced UMAP Data API call
export const getEnhancedChannelUMAPData = (channelId: string) => {
  return api.get(`/channels/${channelId}/enhanced-umap-data`);
};

// Mega Grid API call
export const getChannelVideosGridData = (
  channelId: string, 
  startRow: number, 
  pageSize: number, 
  sortModel: any[]
) => {
  return api.get(`/channels/${channelId}/videos-grid-data`, {
    params: { startRow, pageSize, sortModel: JSON.stringify(sortModel) }
  });
};

// Channel Network Types API call
export const getChannelTypes = (channelId: string) => 
  api.get(`/channel-networks/${channelId}/types`);

// Source Subsidiary API calls
// Types

export interface SourceSubValueComparisonData {
  sourceId: string;
  title: string;
  publishDate: string;
  sourceViews: number;
  totalViews: number;
  subsidiaryCount: number;
}

export const getSourceSubNetwork = (channelId: string) => 
  api.get(`/experiments/sourcesub/${channelId}/network`);

export const getSourceSubValueComparison = (channelId: string) => 
  api.get<SourceSubValueComparisonData[]>(`/experiments/sourcesub/${channelId}/value-comparison`);

// Video Comparison API call
export interface ComparisonDataResponse {
  metadata: {
    id: string;
    title: string;
    publishDate: string;
    type: string;
    wikipediaTopic: string;
    modelTopic: string;
    videoLength: string;
  };
  performance: {
    totalViews: number;
    totalComments: number;
    totalLikes: number;
    lastUpdated: string;
  };
  comparisonData: Array<{
    date: string;
    daysLive: number;
    views: number;
  }>;
  firstTrackedDate: string;
}

export const getVideoComparisonData = (channelId: string, videoId: string) =>
  api.get<ComparisonDataResponse>(`/channels/${channelId}/videos/${videoId}/comparison-data`);

// Experiment Analysis API calls

interface ExperimentsVideoMetadata {
  title: string;
  publishDate: string;
  contentType: string;
}

interface BaseAnalysisResponse {
  metadata: ExperimentsVideoMetadata;
  baseline: {
    sampleSize: number;
    confidence: number;
  };
}

export interface WeibullAnalysisResponse extends BaseAnalysisResponse {
  weibullParams: {
    lambda: number;
    k: number;
  };
  analysisData: {
    daysLive: number;
    views: number;
    hazardRate: number;
    weibullBound: {
      lower: number;
      predicted: number;
      upper: number;
    };
    actualDate: string;
  }[];
}

export interface AlphaAnalysisResponse extends BaseAnalysisResponse {
  alpha: number;
  analysisData: {
    daysLive: number;
    actualViews: number;
    expectedViews: number;
    excessReturn: number;
    actualDate: string;
  }[];
}

export interface BetaAnalysisResponse extends BaseAnalysisResponse {
  beta: number;
  analysisData: {
    daysLive: number;
    actualVolatility: number;
    baselineVolatility: number;
    actualDate: string;
  }[];
}

// API utility functions
export const getVideoWeibullAnalysis = (channelId: string, videoId: string) =>
  api.get<WeibullAnalysisResponse>(`/experiments/analysis/${channelId}/videos/${videoId}/weibull`);

export const getVideoAlphaAnalysis = (channelId: string, videoId: string) =>
  api.get<AlphaAnalysisResponse>(`/experiments/analysis/${channelId}/videos/${videoId}/alpha`);

export const getVideoBetaAnalysis = (channelId: string, videoId: string) =>
  api.get<BetaAnalysisResponse>(`/experiments/analysis/${channelId}/videos/${videoId}/beta`);

// Finance Experiments Video & Channels
interface FinanceChannel {
  channelId: string;
  channelName: string;
  channelIconUrl: string;
  contentTypes: string[];
}

interface FinanceVideo {
  id: string;
  title: string;
  type: string;
  views: number;
  publishDate: string;
  confidenceScore: number;
  sequentialDays: number;
}

export const getExperimentChannels = () =>
  api.get<{ channels: FinanceChannel[] }>('/experiments/channels');

export const getExperimentVideos = (channelId: string) =>
  api.get<FinanceVideo[]>(`/experiments/channels/${channelId}/videos`);

// Sankey Diagram API call
interface SankeyData {
  date: string;
  Video?: string;
  Short?: string;
  Podcast?: string;
  Live?: string;
  Premiere?: string;
  Course?: string;
  'Music Video'?: string;
  untracked?: string;
  total?: string;
}

export const getContentDistribution = async (channelId: string) => {
  // Fetch both datasets in parallel
  const [viewsResponse, libraryResponse] = await Promise.all([
    api.get(`/channels/${channelId}/stacked-views`),
    api.get(`/channels/${channelId}/stacked-library`)
  ]);

  const viewsData: SankeyData[] = viewsResponse.data;
  const libraryData: SankeyData[] = libraryResponse.data;

  // Get the most recent date's data
  const latestViews = viewsData[viewsData.length - 1];
  const latestLibrary = libraryData[libraryData.length - 1];

  // Transform into the format needed for Sankey
  const contentTypes = Object.keys(latestViews).filter(key => 
    key !== 'date' && key !== 'total' && key !== 'untracked'
  );

  return contentTypes.map(type => ({
    videoType: type,
    videoCount: parseInt(latestLibrary[type as keyof SankeyData] || '0'),
    views: parseInt(latestViews[type as keyof SankeyData] || '0')
  }));
};

// DAILY WEIBULL ANALYSIS
export const getDailyViews = (channelId: string) =>
  api.get(`/channels/${channelId}/daily-change/views`);

export interface DailyWeibullAnalysisResponse {
  metadata: {
    title: string;
    publishDate: string;
    contentType: string;
  };
  weibullParams: {
    lambda: number;
    k: number;
  };
  analysisData: {
    daysLive: number;
    dailyChange: number;
    hazardRate: number;
    weibullBound: {
      predicted: number;
      lower: number;
      upper: number;
    };
    actualDate: string;
  }[];
  confidenceScore: number;
}

export const getVideoDailyWeibullAnalysis = (channelId: string, videoId: string) =>
  api.get<DailyWeibullAnalysisResponse>(`/experiments/analysis/${channelId}/videos/${videoId}/daily-weibull`);

// classifications API calls and interfaces

export type VideoClassificationCategory = 
  'STAR' | 'UNICORN' | 'EVERGREEN_PLUS' | 'EVERGREEN' | 
  'ROCKET' | 'ZOMBIE' | 'FOSSIL';

export interface VideoClassificationMetrics {
  totalViews: number;
  firstWeekViewsRatio: number;
  dailyViewChange: number | null;
  lastUpdated: string;
}

export interface VideoClassification {
  videoId: string;
  channelId: string;
  metrics: VideoClassificationMetrics;
  classification: {
    category: VideoClassificationCategory;
    isTracked: boolean;
  };
  transitions?: {
    date: string;
    fromCategory: VideoClassificationCategory;
    toCategory: VideoClassificationCategory;
  }[];
}

export interface ChannelClassificationsResponse {
  channelId: string;
  totalVideos: number;
  classifications: VideoClassification[];
}

// API utilities
export const getChannelClassifications = (channelId: string) =>
  api.get<ChannelClassificationsResponse>(
    `/experiments/classifications/${channelId}`
  );

export const getVideoClassification = (channelId: string, videoId: string) =>
  api.get<VideoClassification>(
    `/experiments/classifications/${channelId}/videos/${videoId}`
  );

// Helper utilities for frontend display
export const classificationEmoji: Record<VideoClassificationCategory, string> = {
  'STAR': '⭐️',
  'UNICORN': '🦄',
  'EVERGREEN_PLUS': '🎄',
  'EVERGREEN': '🌲',
  'ROCKET': '🚀',
  'ZOMBIE': '🧟‍♂️',
  'FOSSIL': '🪨'
};

export const classificationColors: Record<VideoClassificationCategory, string> = {
  'STAR': '#FFD700',      // Gold
  'UNICORN': '#FF1493',   // Deep Pink
  'EVERGREEN_PLUS': '#228B22', // Forest Green
  'EVERGREEN': '#006400', // Dark Green
  'ROCKET': '#FF4500',    // Orange Red
  'ZOMBIE': '#9370DB',    // Medium Purple
  'FOSSIL': '#808080'     // Gray
};

export function getClassificationDescription(
  classification: VideoClassification
): string {
  const { category, isTracked } = classification.classification;
  const { dailyViewChange } = classification.metrics;

  const baseText = isTracked ? 'This video is' : 'When last tracked, this video was';
  const statusText = `${baseText} a ${category.toLowerCase().replace('_', ' ')}`;
  
  if (!isTracked) return statusText;

  const changeText = dailyViewChange && dailyViewChange > 0 
    ? ` and gained ${dailyViewChange.toLocaleString()} views yesterday`
    : '';

  return `${statusText}${changeText}`;
}

export interface ChannelMedians {
  totalViews: number;
  firstWeekRatio: number;
  dailyViewChange: number;
  dailyViewChangePercentiles: {
    p20: number;
    p50: number;
    p80: number;
  };
}

export const getChannelClassificationMedians = (channelId: string) =>
  api.get<ChannelMedians>(`/experiments/classifications/${channelId}/medians`);

export interface NormalizedViewsData {
  date: string;
  baseline: number;
  recent: number;
  total: number;
  videoIds: string[];
}

export const getNormalizedViews = async (channelId: string): Promise<{ data: NormalizedViewsData[] }> => {
  const response = await api.get(`/channels/${channelId}/normalized-views`);
  return response;
};

export interface VideoTitleData {
  title: string;
  type: string;
  publishDate: string;
}

export const getVideoTitles = async (
  channelId: string, 
  videoIds: string[]
): Promise<{ data: Record<string, VideoTitleData> }> => {
  const response = await api.get(`/channels/${channelId}/video-titles`, {
    params: { ids: videoIds.join(',') }
  });
  return response;
};

export default api;