import React from 'react';
import {
  ComposedChart,
  Line,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
} from 'recharts';
import { Text, Group, Box, Badge, Tooltip as MantineTooltip } from '@mantine/core';
import { IconBeta } from '@tabler/icons-react';
import { formatNumber, formatDate } from '../../../utils/formatter';
import { BetaAnalysisResponse } from '../../../utils/api';

interface BetaVisualizationProps {
  data: BetaAnalysisResponse;
}

const getContentTypeColor = (contentType: string): string => {
    switch (contentType) {
      case 'Short': return '#FFA500';
      case 'Video': return '#FF0000';
      case 'Podcast': return '#9C27B0';
      case 'Live': return '#007BFF';
      case 'Premiere': return '#607D8B';
      case 'Music Video': return '#FF0000';
      case 'Course': return '#FF0000';
      default: return '#0d3fa6';
    }
  };  


  const BetaVisualization: React.FC<BetaVisualizationProps> = ({ data }) => {
    const { metadata, beta, analysisData, baseline } = data;
    const contentTypeColor = getContentTypeColor(metadata.contentType);
  
    // Get average baseline volatility from analysis data
    const avgBaselineVolatility = analysisData.reduce((sum, point) => 
      sum + point.baselineVolatility, 0) / analysisData.length;
  
    const CustomTooltip: React.FC<any> = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        const data = payload[0].payload;
        return (
          <Box className="custom-tooltip" p="md" bg="white" style={{ border: '1px solid #ccc' }}>
            <Text size="sm" fw={500}>Day {data.daysLive}</Text>
            <Text size="xs" c="dimmed">{formatDate(data.actualDate)}</Text>
            <Text size="sm">Actual Volatility: {(data.actualVolatility * 100).toFixed(1)}%</Text>
            <Text size="sm">Baseline Volatility: {(data.baselineVolatility * 100).toFixed(1)}%</Text>
            <Text size="sm">Relative Risk: {(data.actualVolatility / data.baselineVolatility).toFixed(2)}x</Text>
          </Box>
        );
      }
      return null;
    };

  const getBetaColor = (value: number): string => {
    if (value < 0.8) return 'green';     // Low volatility
    if (value < 1.2) return 'blue';      // Normal volatility
    if (value < 1.5) return 'orange';    // High volatility
    return 'red';                        // Very high volatility
  };

  return (
    <Box>
      <Group align="center" mb="md" gap="apart">
        <Group>
          <IconBeta size={20} />
          <Text size="md" fw={500}>
            Volatility Analysis for "{metadata.title.length > 50 ? metadata.title.substring(0, 47) + '...' : metadata.title}"
          </Text>
        </Group>
        <Group>
          <MantineTooltip 
            label="Beta (β) measures volatility relative to baseline - values > 1 indicate higher volatility"
            multiline
            w={200}
          >
            <Badge color={getBetaColor(beta)}>
              β = {beta.toFixed(2)}
            </Badge>
          </MantineTooltip>
          <MantineTooltip 
            label="Number of videos used to establish the baseline volatility"
            multiline
            w={200}
          >
            <Badge color="blue">Sample Size: {baseline.sampleSize}</Badge>
          </MantineTooltip>
          <MantineTooltip 
            label="Confidence score based on sample size and consistency of volatility patterns"
            multiline
            w={200}
          >
            <Badge color={baseline.confidence >= 0.8 ? "green" : "yellow"}>
              {baseline.confidence >= 0.8 ? "High" : "Medium"} Confidence
            </Badge>
          </MantineTooltip>
        </Group>
      </Group>

      <Group mt="md" gap="xl">
        <MantineTooltip 
          label="Beta compared to baseline volatility - indicates how much more or less volatile this video is"
          multiline
          w={200}
        >
          <Text size="sm">
            <Text span fw={500}>Beta:</Text> {beta.toFixed(2)}x baseline volatility
          </Text>
        </MantineTooltip>
        <MantineTooltip 
          label="Overall risk profile based on volatility comparison"
          multiline
          w={200}
        >
          <Text size="sm">
            <Text span fw={500}>Risk Profile:</Text>{' '}
            {beta < 0.8 ? 'Lower' : beta < 1.2 ? 'Similar' : 'Higher'} 
            {' '}volatility than typical {metadata.contentType} content
          </Text>
        </MantineTooltip>
      </Group>


      <ResponsiveContainer width="100%" height={600}>
        <ComposedChart
          data={analysisData}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="daysLive"
            tickFormatter={(value) => `Day ${value}`}
          />
          <YAxis
            tickFormatter={(value) => `${(value * 100).toFixed(0)}%`}
          />
          <Tooltip content={<CustomTooltip />} />

          {/* Baseline volatility reference line */}
          <ReferenceLine
            y={avgBaselineVolatility}
            stroke="#666"
            strokeDasharray="3 3"
            label={{ 
              value: `Baseline Volatility (${(avgBaselineVolatility * 100).toFixed(1)}%)`, 
              position: 'right' 
            }}
          />

          {/* Baseline volatility area */}
          <Area
            type="monotone"
            dataKey="baselineVolatility"
            fill="#f0f0f0"
            stroke="#808080"
            fillOpacity={0.3}
            isAnimationActive={true}
            animationDuration={2000}
          />

          {/* Actual volatility line */}
          <Line
            type="monotone"
            dataKey="actualVolatility"
            stroke={contentTypeColor}
            strokeWidth={2}
            dot={false}
            activeDot={{ r: 8 }}
            isAnimationActive={true}
            animationDuration={2000}
          />
        </ComposedChart>
      </ResponsiveContainer>

    </Box>
  );
};

export default BetaVisualization;