import React, { useState, useEffect } from 'react';
import { Paper, Group, Text, Stack } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import { IconChevronRight, IconExternalLink, IconChartScatter } from '@tabler/icons-react';
import { ScatterChart, XAxis, YAxis, Tooltip, Scatter, ResponsiveContainer, CartesianGrid } from 'recharts';
import { SkeletonLoader } from '../../../core/SkeletonLoader';
import { ChannelPicker } from '../../components/ChannelPicker';
import { getChannelPerformanceData, getChannelSummary } from '../../../../utils/api';
import { ScatterMetricPicker, MetricKey } from '../../components/ScatterMetricPicker';
import { ClickableContentTypeBadge } from '../../components/ClickableContentTypeBadge';
import { formatNumber } from '../../../../utils/formatter';

const navItems = [
  { title: 'Channels', href: '/channels/channel' },
  { title: 'Performance', href: '/channels/performance/two-d' },
  { title: '2D', href: '/channels/performance/two-d' },
  { title: 'Compare 2D', href: '/channels/performance/compare-two-d' },
  { title: 'Treemap', href: '/channels/performance/treemap' },
  { title: 'Swarm', href: '/channels/performance/swarm' },
  { title: 'Notes', href: 'https://brick-river-8a5.notion.site/Channels-2D-1216777fc95580b4950bef02de3565d6', isExternal: true },
];

interface PerformanceDataItem {
  videoId: string;
  title: string;
  views: number;
  comments: number;
  likes: number;
  conversationRate: number;
  likeRate: number;
  contentType: string;
}

interface ChannelSummary {
  channelName: string;
  contentTypes: string[];
}

const contentTypeColors = {
  'Video': '#FF0000',
  'Short': '#FFA500',
  'Podcast': '#9C27B0',
  'Live': '#007BFF',
  'Premiere': '#607D8B',
  'Course': '#FF0000',
  'Music Video': '#FF0000'
};

const metricLabels: Record<MetricKey, string> = {
  views: 'Views',
  likes: 'Likes',
  comments: 'Comments',
  conversationRate: 'Conversation Rate',
  likeRate: 'Like Rate'
};

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length && payload[0].payload) {
    const data = payload[0].payload;
    return (
      <div style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}>
        <Text size="sm" fw={500}>{data.title || 'N/A'}</Text>
        <Text size="sm">Type: {data.contentType || 'N/A'}</Text>
        <Text size="sm">Views: {formatNumber(data.views)}</Text>
        <Text size="sm">Likes: {formatNumber(data.likes)}</Text>
        <Text size="sm">Comments: {formatNumber(data.comments)}</Text>
        <Text size="sm">Conversation Rate: {data.conversationRate.toFixed(2)}%</Text>
        <Text size="sm">Like Rate: {data.likeRate.toFixed(2)}%</Text>
      </div>
    );
  }
  return null;
};

export function ChannelPerformance2DScatter() {
  const [selectedChannel, setSelectedChannel] = useState<string | null>(null);
  const [selectedXAxis, setSelectedXAxis] = useState<MetricKey>('views');
  const [selectedYAxis, setSelectedYAxis] = useState<MetricKey>('comments');
  const [performanceData, setPerformanceData] = useState<PerformanceDataItem[]>([]);
  const [channelSummary, setChannelSummary] = useState<ChannelSummary | null>(null);
  const [visibleContentTypes, setVisibleContentTypes] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();

  useEffect(() => {
    if (selectedChannel) {
      setLoading(true);
      setError(null);
      
      Promise.all([
        getChannelPerformanceData(selectedChannel),
        getChannelSummary(selectedChannel)
      ])
        .then(([performanceResponse, summaryResponse]) => {
          setPerformanceData(performanceResponse.data);
          setChannelSummary(summaryResponse.data);
          setVisibleContentTypes(summaryResponse.data.contentTypes);
          setLoading(false);
        })
        .catch(err => {
          setError("Error fetching data. Please try again.");
          setLoading(false);
        });
    }
  }, [selectedChannel]);

  const toggleContentType = (contentType: string) => {
    setVisibleContentTypes(prev => 
      prev.includes(contentType)
        ? prev.filter(type => type !== contentType)
        : [...prev, contentType]
    );
  };

  const handleMetricChange = (axis: 'x' | 'y', value: MetricKey) => {
    if (axis === 'x') {
      setSelectedXAxis(value);
    } else {
      setSelectedYAxis(value);
    }
  };

  const renderPerformanceScatter = () => (
    <ResponsiveContainer width="100%" height={700}>
      <ScatterChart margin={{ top: 20, right: 20, bottom: 100, left: 60 }}>
      <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          type="number"
          dataKey={selectedXAxis}
          name={metricLabels[selectedXAxis as keyof typeof metricLabels]}
          tickFormatter={(value: number) => formatNumber(value)}
          label={{ value: metricLabels[selectedXAxis as keyof typeof metricLabels], position: 'bottom', offset: 20 }}
        />
        <YAxis
          type="number"
          dataKey={selectedYAxis}
          name={metricLabels[selectedYAxis as keyof typeof metricLabels]}
          tickFormatter={(value: number) => formatNumber(value)}
          label={{ value: metricLabels[selectedYAxis as keyof typeof metricLabels], angle: -90, position: 'insideLeft', offset: 0 }}
        />
        <Tooltip content={<CustomTooltip />} />
        {visibleContentTypes.map((contentType) => (
          <Scatter
            key={contentType}
            name={contentType}
            data={performanceData.filter(item => item.contentType === contentType)}
            fill={contentTypeColors[contentType as keyof typeof contentTypeColors] || '#808080'}
          />
        ))}
      </ScatterChart>
    </ResponsiveContainer>
  );

  return (
    <Stack gap="xs">
      <Group gap={5}>
        {navItems.map((item, index) => (
          <React.Fragment key={item.title}>
            {index > 0 && index < 3 && <IconChevronRight size={14} color='#868e96'/>}
            {index > 2 && <Text size="sm" color="dimmed">|</Text>}
            {item.isExternal ? (
              <Text
                component="a"
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
                size="sm"
                c="#0982eb"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {item.title}
                <IconExternalLink size={14} style={{ marginLeft: 5 }} />
              </Text>
            ) : (
              <Text
                component={Link}
                to={item.href}
                size="sm"
                fw={index >= 2 && location.pathname === item.href ? 500 : 'normal'}
                td={index >= 2 && location.pathname === item.href ? 'underline' : 'none'}
                c={index < 2 ? 'dimmed' : '#0982eb'}
              >
                {item.title}
              </Text>
            )}
          </React.Fragment>
        ))}
      </Group>
      <Stack gap="xs">
      <Group>
      <ChannelPicker value={selectedChannel} onChange={setSelectedChannel} />
      <ScatterMetricPicker 
        xAxisMetric={selectedXAxis} 
        yAxisMetric={selectedYAxis} 
        onChange={handleMetricChange} 
          />
        </Group>
        <Paper
          p="md" 
          radius="md" 
          withBorder
          style={{ 
            transition: 'box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out',
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.1)';
            e.currentTarget.style.transform = 'translateY(-5px)';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.boxShadow = '';
            e.currentTarget.style.transform = '';
          }}
        >
          {loading ? (
            <SkeletonLoader count={1} height={500} radius="sm" />
          ) : error ? (
            <Text color="red">{error}</Text>
          ) : performanceData.length > 0 && channelSummary ? (
            <>
              <Group align="center" mb="sm">
                <IconChartScatter size={20} />
                <Text size="md" fw={400} fs="italic">
                  {metricLabels[selectedXAxis as MetricKey]} and {metricLabels[selectedYAxis as MetricKey]} for {channelSummary.channelName}
                </Text>
              </Group>
              <Group mb="md">
                {channelSummary.contentTypes.map((contentType: string) => (
                  <ClickableContentTypeBadge
                    key={contentType}
                    type={contentType}
                    isSelected={visibleContentTypes.includes(contentType)}
                    onClick={() => toggleContentType(contentType)}
                  />
                ))}
              </Group>
              {renderPerformanceScatter()}
            </>
          ) : (
            <Text>Select a channel to get started.</Text>
          )}
        </Paper>
      </Stack>
    </Stack>
  );
}