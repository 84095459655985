import React from 'react';
import { Badge } from '@mantine/core';
import { IconBrandYoutube, IconDeviceMobile, IconBroadcast, IconSchool, IconVideo, IconDeviceTv, IconSum, IconDeviceMobileOff, IconPlayerTrackNext } from '@tabler/icons-react';

interface ContentTypeBadgeProps {
  type: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  style?: React.CSSProperties;
}

const typeConfig: Record<string, { icon: React.ReactNode; color: string }> = {
  Video: { icon: <IconBrandYoutube size={14} />, color: '#FF0000' },
  Short: { icon: <IconDeviceMobile size={14} />, color: '#FFA500' },
  Podcast: { icon: <IconBroadcast size={14} />, color: '#9C27B0' },
  Course: { icon: <IconSchool size={14} />, color: '#FF0000' },
  Live: { icon: <IconVideo size={14} />, color: '#007BFF' },
  Premiere: { icon: <IconDeviceTv size={14} />, color: '#607D8B' },
  'Music Video': { icon: <IconBrandYoutube size={14} />, color: '#FF0000' },
  All: { icon: <IconSum size={14} />, color: '#FF0000' },
  'All (excluding Shorts)': { icon: <IconDeviceMobileOff size={14} />, color: '#FF0000' },
  Untracked: { icon: <IconPlayerTrackNext size={14} />, color: '#808080' },
};

export const ContentTypeBadge: React.FC<ContentTypeBadgeProps> = ({ type, size = 'sm', style }) => {
  const config = typeConfig[type] || { icon: null, color: 'gray' };
  
  return (
    <Badge
      size={size}
      radius="sm"
      variant="filled"
      leftSection={config.icon}
      color={config.color}
      style={style}
    >
      {type}
    </Badge>
  );
};

export default ContentTypeBadge;