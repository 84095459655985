import React from 'react';
import { Group, Box, Text } from '@mantine/core';

export function Legend() {
  return (
    <Group gap="md">
      <Group gap="xs">
        <Box style={{ width: 10, height: 10, borderRadius: '50%', backgroundColor: '#FF0000' }} />
        <Text size="sm" c="white">Trending</Text>
      </Group>
      <Group gap="xs">
        <Box style={{ width: 10, height: 10, borderRadius: '50%', backgroundColor: '#FAFAFA' }} />
        <Text size="sm" c="white">Related To Trending</Text>
      </Group>
    </Group>
  );
}