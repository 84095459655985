import React, { useState, useEffect } from 'react';
import { Paper, Group, Text, Stack, Grid } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import { IconChevronRight, IconExternalLink } from '@tabler/icons-react';
import { ComparisonChart } from '../../components/ComparisonChart';
import { SkeletonLoader } from '../../../core/SkeletonLoader';
import { getVideoClassification, getVideoComparisonData, VideoClassification } from '../../../../utils/api';
import { ComparisonCard } from '../../components/ComparisonCard';
import { ComparisonVideoPicker } from '../../components/ComparisonVideoPicker';

interface VideoSelection {
  channelId: string | null;
  contentType: string;
  videoId: string | null;
}

interface VideoData {
  metadata: {
    id: string;
    title: string;
    publishDate: string;
    type: string;
    wikipediaTopic: string;
    modelTopic: string;
    videoLength: string;
  };
  performance: {
    totalViews: number;
    totalComments: number;
    totalLikes: number;
    lastUpdated: string;
  };
  comparisonData: Array<{
    daysLive: number;
    date: string;
    views: number;
  }>;
  firstTrackedDate: string;
}

const navItems = [
  { title: 'Channels', href: '/channels/channel' },
  { title: 'Videos', href: '/channels/video/video' },
  { title: 'Video', href: '/channels/video/video' },
  { title: 'Compare Videos', href: '/channels/video/video-comparison' },
  { title: 'Video Analysis', href: '/channels/video/video-analysis' },
  { title: 'Notes', href: 'https://brick-river-8a5.notion.site/Compare-Videos-12a6777fc955805d8d9ec9df3a28fb28', isExternal: true },
];

const initialVideoSelection: VideoSelection = {
  channelId: null,
  contentType: 'All',
  videoId: null,
};

export function VideoComparison() {
  const [video1Selection, setVideo1Selection] = useState<VideoSelection>(initialVideoSelection);
  const [video2Selection, setVideo2Selection] = useState<VideoSelection>(initialVideoSelection);
  const [video1Data, setVideo1Data] = useState<VideoData | null>(null);
  const [video2Data, setVideo2Data] = useState<VideoData | null>(null);
  const [video1Classification, setVideo1Classification] = useState<VideoClassification | null>(null);
  const [video2Classification, setVideo2Classification] = useState<VideoClassification | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      if (!video1Selection.videoId || !video2Selection.videoId) return;
      
      setLoading(true);
      setError(null);
      
      try {
        const [video1Response, video2Response, video1ClassResponse, video2ClassResponse] = await Promise.all([
          getVideoComparisonData(video1Selection.channelId!, video1Selection.videoId),
          getVideoComparisonData(video2Selection.channelId!, video2Selection.videoId),
          getVideoClassification(video1Selection.channelId!, video1Selection.videoId)
            .catch(err => {
              if (err.response?.status === 404) return { data: null };
              throw err;
            }),
          getVideoClassification(video2Selection.channelId!, video2Selection.videoId)
            .catch(err => {
              if (err.response?.status === 404) return { data: null };
              throw err;
            })
        ]);
        
        setVideo1Data(video1Response.data);
        setVideo2Data(video2Response.data);
        setVideo1Classification(video1ClassResponse.data);
        setVideo2Classification(video2ClassResponse.data);
      } catch (err) {
        setError('Error fetching video data. Please try again.');
        console.error('Error fetching comparison data:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [video1Selection.videoId, video2Selection.videoId]);

  return (
    <Stack gap="md">
      <Group gap={5}>
        {navItems.map((item, index) => (
          <React.Fragment key={item.title}>
            {index > 0 && index < 3 && <IconChevronRight size={14} color='#868e96'/>}
            {index > 2 && <Text size="sm" color="dimmed">|</Text>}
            {item.isExternal ? (
              <Text
                component="a"
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
                size="sm"
                c="#0982eb"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {item.title}
                <IconExternalLink size={14} style={{ marginLeft: 5 }} />
              </Text>
            ) : (
              <Text
                component={Link}
                to={item.href}
                size="sm"
                fw={index >= 2 && location.pathname === item.href ? 500 : 'normal'}
                td={index >= 2 && location.pathname === item.href ? 'underline' : 'none'}
                c={index < 2 ? 'dimmed' : '#0982eb'}
              >
                {item.title}
              </Text>
            )}
          </React.Fragment>
        ))}
      </Group>

      <Group align="flex-start" grow>
        <ComparisonVideoPicker 
          selection={video1Selection} 
          onChange={setVideo1Selection}
          label="Select Video 1"
        />
        <ComparisonVideoPicker 
          selection={video2Selection} 
          onChange={setVideo2Selection}
          label="Select Video 2"
        />
      </Group>

      {loading ? (
        <SkeletonLoader count={3} height={200} />
      ) : error ? (
        <Text color="red" mt="md">{error}</Text>
      ) : video1Data && video2Data ? (
        <>
          <Grid gutter="md">
            <Grid.Col span={{ base: 12, md: 6 }}>
              <ComparisonCard
                metadata={video1Data.metadata}
                performance={video1Data.performance}
                firstTrackedDate={video1Data.firstTrackedDate}
                classification={video1Classification}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6 }}>
              <ComparisonCard
                metadata={video2Data.metadata}
                performance={video2Data.performance}
                firstTrackedDate={video2Data.firstTrackedDate}
                classification={video2Classification}
              />
            </Grid.Col>
          </Grid>

          <Paper
            p="md"
            radius="md"
            withBorder
            style={{
              transition: 'box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out',
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.1)';
              e.currentTarget.style.transform = 'translateY(-5px)';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.boxShadow = '';
              e.currentTarget.style.transform = '';
            }}
          >
            <ComparisonChart
              video1Data={video1Data.comparisonData}
              video2Data={video2Data.comparisonData}
              video1Type={video1Data.metadata.type}
              video2Type={video2Data.metadata.type}
              video1Title={video1Data.metadata.title}
              video2Title={video2Data.metadata.title}
            />
          </Paper>
        </>
      ) : null}
    </Stack>
  );
}