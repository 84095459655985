import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../utils/api';

export function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    const performLogout = async () => {
      try {
        await logout();
        localStorage.removeItem('token');
        navigate('/login');
      } catch (error) {
        console.error('Logout failed', error);
        // Optionally, you could still redirect to login or show an error message
        navigate('/login');
      }
    };

    performLogout();
  }, [navigate]);

  return null; // This component doesn't render anything
}