import React from 'react';
import { Skeleton, Stack } from '@mantine/core';

interface SkeletonLoaderProps {
  count?: number;
  height?: number | string;
  width?: number | string;
  radius?: number | string;
  animate?: boolean;
}

export const SkeletonLoader: React.FC<SkeletonLoaderProps> = ({
  count = 1,
  height = 'auto',
  width = '100%',
  radius = 'sm',
  animate = true,
}) => {
  return (
    <Stack>
      {Array.from({ length: count }).map((_, index) => (
        <Skeleton
          key={index}
          height={height}
          width={width}
          radius={radius}
          animate={animate}
        />
      ))}
    </Stack>
  );
};