import React from 'react';
import { Stack, Group, Text } from '@mantine/core';
import { ChannelPicker } from './ChannelPicker';

interface ComparisonChannelPickerProps {
  channel1: string | null;
  channel2: string | null;
  onChannel1Change: (channelId: string | null) => void;
  onChannel2Change: (channelId: string | null) => void;
  channel1Name?: string;
  channel2Name?: string;
}

const ComparisonChannelPicker = ({
  channel1,
  channel2,
  onChannel1Change,
  onChannel2Change,
  channel1Name,
  channel2Name
}: ComparisonChannelPickerProps) => {
  return (
    <Stack gap="xs">
      <Group align="flex-start">
        <Stack gap="xs">
          <Text size="sm" c="#FF0000" fw={500}>Channel 1</Text>
          <ChannelPicker
            value={channel1}
            onChange={onChannel1Change}
          />
        </Stack>
        <Stack gap="xs">
          <Text size="sm" c="#0d3fa6" fw={500}>Channel 2</Text>
          <ChannelPicker
            value={channel2}
            onChange={onChannel2Change}
          />
        </Stack>
      </Group>
      {(channel1Name || channel2Name) && (
        <Text size="sm" fs="italic" mb="md" fw={400}>
          Comparing:{' '}
          <Text span c="#FF0000">
            {channel1Name || 'Select first channel'}
          </Text>
          {' & '}
          <Text span c="#0d3fa6">
            {channel2Name || 'Select second channel'}
          </Text>
        </Text>
      )}
    </Stack>
  );
};

export default ComparisonChannelPicker;