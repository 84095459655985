import React from 'react';
import { Container, Title, Text, Stack, Image } from '@mantine/core';

export function NotFound() {
  const gifUrl = 'https://c.tenor.com/R9Q68oYL3WwAAAAd/tenor.gif';

  return (
    <Container size="md" style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Stack align="center" gap="xl">
        <Image src={gifUrl} alt="404 Error" width={300} height={300} />
        <Title order={1}>404 - Page Not Found</Title>
        <Text size="xl">The page you're looking for doesn't exist.</Text>
      </Stack>
    </Container>
  );
}