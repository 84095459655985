import React from 'react';
import { Paper, Stack, Text } from '@mantine/core';
import { ChannelPicker } from './ChannelPicker';
import { ContentTypePicker } from './ContentTypePicker';
import { VideoPicker } from './VideoPicker';

interface ComparisonVideoPickerProps {
  selection: {
    channelId: string | null;
    contentType: string;
    videoId: string | null;
  };
  onChange: (selection: {
    channelId: string | null;
    contentType: string;
    videoId: string | null;
  }) => void;
  label: string;
}

export function ComparisonVideoPicker({ selection, onChange, label }: ComparisonVideoPickerProps) {
  return (
    <Paper p="md" radius="md" withBorder style={{ flex: 1 }}>
      <Stack gap="md">
        <Text size="sm" fw={500} mb="xs">{label}</Text>
        <ChannelPicker 
          value={selection.channelId} 
          onChange={(channelId) => onChange({ ...selection, channelId, videoId: null })} 
        />
        <ContentTypePicker
          channelId={selection.channelId}
          value={selection.contentType}
          onChange={(value) => onChange({ ...selection, contentType: value || 'All', videoId: null })}
        />
        <VideoPicker
          key={`${selection.channelId}-${selection.contentType}`}
          channelId={selection.channelId}
          contentType={selection.contentType}
          onChange={(videoId) => onChange({ ...selection, videoId })}
        />
      </Stack>
    </Paper>
  );
}