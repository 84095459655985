import React from 'react';
import { Badge, Checkbox, Group } from '@mantine/core';
import { IconBrandYoutube, IconDeviceMobile, IconBroadcast, IconSchool, IconVideo, IconDeviceTv, IconSum, IconDeviceMobileOff, IconPlayerTrackNext, IconMusic } from '@tabler/icons-react';

interface ClickableContentTypeBadgeProps {
  type: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  style?: React.CSSProperties;
  onClick?: () => void;
  isSelected: boolean;
}

const typeConfig: Record<string, { icon: React.ReactNode; color: string }> = {
  Video: { icon: <IconBrandYoutube size={14} />, color: '#FF0000' },
  Short: { icon: <IconDeviceMobile size={14} />, color: '#FFA500' },
  Podcast: { icon: <IconBroadcast size={14} />, color: '#9C27B0' },
  Course: { icon: <IconSchool size={14} />, color: '#FF0000' },
  Live: { icon: <IconVideo size={14} />, color: '#007BFF' },
  Premiere: { icon: <IconDeviceTv size={14} />, color: '#607D8B' },
  'Music Video': { icon: <IconMusic size={14} />, color: '#FF0000' },
  All: { icon: <IconSum size={14} />, color: '#FF0000' },
  'All (excluding Shorts)': { icon: <IconDeviceMobileOff size={14} />, color: '#FF0000' },
  Untracked: { icon: <IconPlayerTrackNext size={14} />, color: '#808080' },
};

export const ClickableContentTypeBadge: React.FC<ClickableContentTypeBadgeProps> = ({ 
  type, 
  size = 'sm', 
  style, 
  onClick, 
  isSelected 
}) => {
  const config = typeConfig[type] || { icon: null, color: 'gray' };
  
  return (
    <Group gap="xs" style={{ cursor: 'pointer' }} onClick={onClick}>
      <Checkbox checked={isSelected} onChange={() => {}} style={{ pointerEvents: 'none' }} />
      <Badge
        size={size}
        radius="sm"
        variant="filled"
        leftSection={config.icon}
        color={config.color}
        style={{
          ...style,
          opacity: isSelected ? 1 : 0.5,
        }}
      >
        {type}
      </Badge>
    </Group>
  );
};

export default ClickableContentTypeBadge;