import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { Video } from './ChannelsVideo';
import { VideoComparison } from './ChannelsVideoComparison';
import { ExperimentsPatternAnalysis } from './ExperimentsAnalysis';


export function ChannelVideosSet() {
  const { subpage } = useParams<{ subpage: string }>();

  switch (subpage) {
    case 'video':
      return <Video />;
    case 'video-comparison':
      return <VideoComparison />;
    case 'video-analysis':
      return <ExperimentsPatternAnalysis />;
    default:
      return <Navigate to="/channels/video/video" replace />;
  }
}