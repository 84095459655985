import React from 'react';
import { Card, Group, Text, Stack, Box, Anchor, HoverCard, Divider } from '@mantine/core';
import { IconExternalLink } from '@tabler/icons-react';
import { ContentTypeBadge } from './ContentTypeBadge';
import { formatNumber, formatDate, formatSeconds } from '../../../utils/formatter';
import { VideoClassification } from '../../../utils/api';
import { ClassificationBadge } from './VideoClassificationBadge';

interface ComparisonCardProps {
  metadata: {
    id: string;
    title: string;
    publishDate: string;
    type: string;
    wikipediaTopic: string;
    modelTopic: string;
    videoLength: string;
  };
  performance: {
    totalViews: number;
    totalComments: number;
    totalLikes: number;
    lastUpdated: string;
  };
  firstTrackedDate: string;
  classification?: VideoClassification | null;
}

interface CroppedThumbnailProps {
  videoId: string;
  title: string;
  type: string;
}

const CroppedThumbnail: React.FC<CroppedThumbnailProps> = ({ videoId, title, type }) => {
  const isShort = type === 'Short';
  const thumbnailUrl = isShort
    ? `https://i.ytimg.com/vi/${videoId}/oar2.jpg`
    : `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;

  return (
    <Box
      style={{
        width: '100%',
        height: 0,
        paddingBottom: isShort ? '177.78%' : '56.25%',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <img
        src={thumbnailUrl}
        alt={title}
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: isShort ? 'auto' : '100%',
          height: isShort ? '100%' : 'auto',
          maxWidth: '100%',
          maxHeight: '100%',
          objectFit: 'cover',
        }}
      />
    </Box>
  );
};

export function ComparisonCard({ metadata, performance, firstTrackedDate, classification }: ComparisonCardProps) {
  const videoUrl = metadata.type === 'Short'
    ? `https://youtube.com/shorts/${metadata.id}`
    : `https://youtube.com/watch?v=${metadata.id}`;

  const isShort = metadata.type === 'Short';

  return (
    <Card
      withBorder
      p="md"
      radius="md"
      style={{
        transition: 'box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out',
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.1)';
        e.currentTarget.style.transform = 'translateY(-5px)';
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.boxShadow = '';
        e.currentTarget.style.transform = '';
      }}
    >
      <Group align="flex-start" gap="md" wrap="nowrap">
        <Box style={{ width: isShort ? '30%' : '40%', maxWidth: isShort ? '200px' : '300px' }}>
          <HoverCard shadow="md" openDelay={500}>
            <HoverCard.Target>
              <div>
                <CroppedThumbnail videoId={metadata.id} title={metadata.title} type={metadata.type} />
              </div>
            </HoverCard.Target>
            <HoverCard.Dropdown>
              <Anchor
                href={videoUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Group>
                  <Text size="sm">Watch on YouTube</Text>
                  <IconExternalLink size={14} />
                </Group>
              </Anchor>
            </HoverCard.Dropdown>
          </HoverCard>
        </Box>

        <Stack gap="xs" style={{ flex: 1 }}>
          <Text fw={500}>{metadata.title}</Text>
          <ContentTypeBadge type={metadata.type} size="md" />
          <Text size="sm" c="dimmed">Published: {formatDate(metadata.publishDate)}</Text>
          <Text size="sm" c="dimmed">Length: {formatSeconds(parseInt(metadata.videoLength))}</Text>
          
          <Text 
            size="sm" 
            c={metadata.wikipediaTopic ? undefined : "dimmed"} 
            fs={!metadata.wikipediaTopic ? "italic" : undefined}
          >
            YouTube's Topics: {metadata.wikipediaTopic || "Not assigned by YouTube"}
          </Text>
          <Text 
            size="sm" 
            c={metadata.modelTopic ? undefined : "dimmed"} 
            fs={!metadata.modelTopic ? "italic" : undefined}
          >
            Model Topic: {metadata.modelTopic || "Not Modelled"}
          </Text>
          
          <Divider my="xs" />
          
          {classification && (
            <ClassificationBadge classification={classification} />
          )}
          <Text size="sm">Views: {formatNumber(performance.totalViews)}</Text>
          <Text size="sm">Comments: {formatNumber(performance.totalComments)}</Text>
          <Text size="sm">Likes: {formatNumber(performance.totalLikes)}</Text>
          
          <Divider my="xs" />
          
          <Text size="xs" c="dimmed" fs="italic">First Ingested: {formatDate(firstTrackedDate)}</Text>
          <Text size="xs" c="dimmed" fs="italic">Last Updated: {formatDate(performance.lastUpdated)}</Text>
        </Stack>
      </Group>
    </Card>
  );
}