import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { ChannelSummary } from './ChannelsChannels';
import { ChannelsTopFive } from './ChannelsTopFive';
import { ChannelComparison } from './ChannelsComparison';
import { ChannelTimeline } from './ChannelsTimeline';
import { ChannelClassifications } from './ChannelsClassification';

export function ChannelsChannelSet() {
  const { subpage } = useParams<{ subpage: string }>();

  switch (subpage) {
    case 'channel':
      return <ChannelSummary />;
    case 'top-five':
      return <ChannelsTopFive />;
    case 'compare':
      return <ChannelComparison />;
    case 'timeline':
      return <ChannelTimeline />;
    case 'classification':
      return <ChannelClassifications />;
    default:
      return <Navigate to="/channels/channels/channel" replace />;
  }
}