import { SimulationNodeDatum, SimulationLinkDatum } from 'd3-force';
import { differenceInDays } from 'date-fns';

export interface ChannelNetworkNode extends SimulationNodeDatum {
  id: string;
  title: string;
  channel_id: string;
  channel_name: string;
  publish_date: { value: string };
  node_type: string;
  x?: number;
  y?: number;
  vx?: number;
  vy?: number;
  fx?: number | undefined;
  fy?: number | undefined;
}

export interface ChannelNetworkLink extends SimulationLinkDatum<ChannelNetworkNode> {
  source: string | ChannelNetworkNode;
  target: string | ChannelNetworkNode;
  edge_type: string;
}

export interface NodeNeighborMap {
  [nodeId: string]: {
    neighbors: string[];
    links: ChannelNetworkLink[];
  };
}

export function processChannelNetworkData(
  data: any
): { nodes: ChannelNetworkNode[], links: ChannelNetworkLink[] } {
  console.log("Processing channel network data:", data);

  if (!data || !Array.isArray(data.nodes) || !Array.isArray(data.links)) {
    console.error("Invalid data structure:", data);
    return { nodes: [], links: [] };
  }

  const nodes: ChannelNetworkNode[] = data.nodes.map((node: any) => ({
    id: node.id,
    title: node.title,
    channel_id: node.channelId || '',
    channel_name: node.channelTitlePretty || '',
    publish_date: { value: node.publishDate || '' },
    node_type: node.type,
    x: Math.random() * 1000,
    y: Math.random() * 1000,
    vx: 0,
    vy: 0,
    fx: undefined,
    fy: undefined
  }));

  const nodeMap = new Map(nodes.map(node => [node.id, node]));

  const links: ChannelNetworkLink[] = data.links.map((link: any) => ({
    source: nodeMap.get(link.source) || link.source,
    target: nodeMap.get(link.target) || link.target,
    edge_type: link.edgeType
  }));

  console.log("Processed result. Nodes:", nodes.length, "Links:", links.length);

  return { nodes, links };
}

export function createNodeNeighborMap(nodes: ChannelNetworkNode[], links: ChannelNetworkLink[]): NodeNeighborMap {
  const neighborMap: NodeNeighborMap = {};

  nodes.forEach(node => {
    neighborMap[node.id] = { neighbors: [], links: [] };
  });

  links.forEach(link => {
    const sourceId = typeof link.source === 'string' ? link.source : link.source.id;
    const targetId = typeof link.target === 'string' ? link.target : link.target.id;

    if (!neighborMap[sourceId].neighbors.includes(targetId)) {
      neighborMap[sourceId].neighbors.push(targetId);
      neighborMap[sourceId].links.push(link);
    }

    if (!neighborMap[targetId].neighbors.includes(sourceId)) {
      neighborMap[targetId].neighbors.push(sourceId);
      neighborMap[targetId].links.push(link);
    }
  });

  return neighborMap;
}

export function filterGraphData(
  nodes: ChannelNetworkNode[],
  links: ChannelNetworkLink[],
  selectedEdgeTypes: string[]
): { nodes: ChannelNetworkNode[], links: ChannelNetworkLink[] } {
  if (selectedEdgeTypes.length === 0) {
    return { nodes, links };
  }

  const filteredLinks = links.filter(link => selectedEdgeTypes.includes(link.edge_type));
  
  const connectedNodeIds = new Set<string>();
  filteredLinks.forEach(link => {
    connectedNodeIds.add(typeof link.source === 'string' ? link.source : link.source.id);
    connectedNodeIds.add(typeof link.target === 'string' ? link.target : link.target.id);
  });

  const filteredNodes = nodes.filter(node => connectedNodeIds.has(node.id));

  return { nodes: filteredNodes, links: filteredLinks };
}

export const AGE_CATEGORIES = [
  'In the last week',
  'Between 1 week and 1 month',
  'Between 1 month and 3 months',
  'Between 3 months and 6 months',
  'Older than 6 months'
] as const;

export type AgeCategory = typeof AGE_CATEGORIES[number];

export type FilterMode = 'edge' | 'age' | null;

export interface NodeAge {
  nodeId: string;
  publishDate: string;
  ageCategory: AgeCategory;
}

export const getNodeAgeCategory = (publishDate: string): AgeCategory => {
  const daysSincePublish = differenceInDays(new Date(), new Date(publishDate));
  if (daysSincePublish < 7) return 'In the last week';
  if (daysSincePublish < 32) return 'Between 1 week and 1 month';
  if (daysSincePublish < 91) return 'Between 1 month and 3 months';
  if (daysSincePublish < 181) return 'Between 3 months and 6 months';
  return 'Older than 6 months';
};

export const createNodeAgeMap = (nodes: ChannelNetworkNode[]): NodeAge[] => {
  return nodes
    .filter(node => node.publish_date?.value)
    .map(node => ({
      nodeId: node.id,
      publishDate: node.publish_date.value,
      ageCategory: getNodeAgeCategory(node.publish_date.value)
    }));
};

export const filterNetworkByAge = (
  nodes: ChannelNetworkNode[],
  links: ChannelNetworkLink[],
  selectedAges: AgeCategory[]
): { nodes: ChannelNetworkNode[], links: ChannelNetworkLink[] } => {
  if (selectedAges.length === 0) return { nodes, links };

  const nodeAges = createNodeAgeMap(nodes);
  const allowedSourceIds = new Set(
    nodeAges
      .filter(node => selectedAges.includes(node.ageCategory))
      .map(node => node.nodeId)
  );

  const filteredLinks = links.filter(link => {
    const sourceId = typeof link.source === 'string' ? link.source : link.source.id;
    return allowedSourceIds.has(sourceId);
  });

  const connectedNodeIds = new Set<string>();
  filteredLinks.forEach(link => {
    const sourceId = typeof link.source === 'string' ? link.source : link.source.id;
    const targetId = typeof link.target === 'string' ? link.target : link.target.id;
    connectedNodeIds.add(sourceId);
    connectedNodeIds.add(targetId);
  });

  return {
    nodes: nodes.filter(node => connectedNodeIds.has(node.id)),
    links: filteredLinks
  };
};