import React from 'react';
import { Text, Group, Stack, Container, Title, Image } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import { IconChevronRight, IconExternalLink } from '@tabler/icons-react';

const navItems = [
  { title: 'Experiments', href: '/experiments/model-explorer' },
  { title: 'Model Explorer', href: '/experiments/model-explorer' },
  { title: 'Source / Subsidiary', href: '/experiments/source-subsidiary' },
  { title: 'Thumbnail Scores', href: '/experiments/thumbnail-scores' },
  { title: 'Notes', href: 'https://brick-river-8a5.notion.site/Experiments-Thumbnail-Scores-1216777fc95580958ca3d72ab9237e78', isExternal: true },
];

export function ThumbnailScoresScores() {
  const gifUrl = 'https://c.tenor.com/R9Q68oYL3WwAAAAd/tenor.gif';
  const location = useLocation();

  return (
    <Stack gap="xs">
      <Group gap={5}>
        {navItems.map((item, index) => (
          <React.Fragment key={item.title}>
            {index > 0 && index < 2 && <IconChevronRight size={14} color='#868e96'/>}
            {index > 1 && <Text size="sm" color="dimmed">|</Text>}
            {item.isExternal ? (
              <Text
                component="a"
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
                size="sm"
                c="#0982eb"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {item.title}
                <IconExternalLink size={14} style={{ marginLeft: 5 }} />
              </Text>
            ) : (
              <Text
                component={Link}
                to={item.href}
                size="sm"
                fw={index >= 1 && location.pathname === item.href ? 500 : 'normal'}
                td={index >= 1 && location.pathname === item.href ? 'underline' : 'none'}
                c={index < 1 ? 'dimmed' : '#0982eb'}
              >
                {item.title}
              </Text>
            )}
      </React.Fragment>
        ))}
      </Group>

      <Container size="md" style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Stack align="center" gap="xl">
        <Image src={gifUrl} alt="404 Error" width={300} height={300} />
        <Title order={1}>Under Construction</Title>
        <Text size="xl">Soon...</Text>
      </Stack>
    </Container>
    </Stack>
  );
}
