import React from 'react';
import { Combobox, InputBase, useCombobox, Group } from '@mantine/core';
import { IconEye, IconMessage, IconMessages } from '@tabler/icons-react';

type MetricKey = 'views' | 'comments' | 'conversationRate';

const metricOptions: { value: MetricKey; label: string; icon: React.ReactNode }[] = [
  { value: 'views', label: 'Views', icon: <IconEye size={16} /> },
  { value: 'comments', label: 'Comments', icon: <IconMessage size={16} /> },
  { value: 'conversationRate', label: 'Conversation Rate', icon: <IconMessages size={16} /> },
];

interface MetricPickerProps {
  value: MetricKey;
  onChange: (value: MetricKey) => void;
}

export function MetricPicker({ value, onChange }: MetricPickerProps) {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
  });

  const selectedMetric = metricOptions.find(metric => metric.value === value);

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={(val) => {
        onChange(val as MetricKey);
        combobox.closeDropdown();
      }}
    >
      <Combobox.Target>
        <InputBase
          component="button"
          pointer
          rightSection={<Combobox.Chevron />}
          onClick={() => combobox.toggleDropdown()}
          rightSectionPointerEvents="none"
          style={{ width: '100%', maxWidth: '300px' }}
        >
          {selectedMetric ? (
            <Group gap="sm">
              {selectedMetric.icon}
              <span>{selectedMetric.label}</span>
            </Group>
          ) : (
            <span>Select metric</span>
          )}
        </InputBase>
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options>
          {metricOptions.map((metric) => (
            <Combobox.Option value={metric.value} key={metric.value}>
              <Group gap="sm">
                {metric.icon}
                <span>{metric.label}</span>
              </Group>
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}