import React from 'react';
import { Carousel } from '@mantine/carousel';
import { Card, Image, Text, Group, Stack, Box, Badge, Avatar, HoverCard, Anchor, Tooltip, Divider } from '@mantine/core';
import { formatDateUK } from '../../../utils/dateUtils';
import { IconExternalLink } from '@tabler/icons-react';
import { formatNumber} from '../../../utils/formatter';

interface TrendingVideo {
  videoId: string;
  title: string;
  rank: number;
  views: number;
  comments: number;
  conversationRate: number;
  publishDate: string;
  channelName: string;
  channelIconUrl: string;
  otherRegionRank?: number;
}

interface TrendingVideoCarouselProps {
  videos: TrendingVideo[];
  region: 'US' | 'GB';
}

const getRankColor = (rank: number): string => {
  const darkBlue = [13, 63, 166];  // ##0d3fa6
  const lightBlue = [109, 174, 213];  // #6daed5
  const index = rank - 1;
  const total = 50;
  
  const r = Math.round(darkBlue[0] + (lightBlue[0] - darkBlue[0]) * (index / (total - 1)));
  const g = Math.round(darkBlue[1] + (lightBlue[1] - darkBlue[1]) * (index / (total - 1)));
  const b = Math.round(darkBlue[2] + (lightBlue[2] - darkBlue[2]) * (index / (total - 1)));
  
  return `rgb(${r}, ${g}, ${b})`;
};

export function TrendingVideoCarousel({ videos, region }: TrendingVideoCarouselProps) {
  return (
    <Card 
      withBorder 
      radius="md" 
      p="md"
      style={{
        backgroundColor: 'white',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)',
        transition: 'box-shadow 0.3s ease-in-out',
      }}
    >
      <Text size="xl" tt="uppercase" fw={700} ta="center" mb="xs">
        {region === 'US' ? '🇺🇸' : '🇬🇧'}
      </Text>
      <Carousel
        slideSize="100%"
        slideGap="sm"
        align="center"
        slidesToScroll={1}
        controlSize={20}
        controlsOffset="md"
        styles={{
          control: {
            '&[data-inactive]': {
              opacity: 0,
              cursor: 'default',
            },
          },
        }}
      >
        {videos.map((video) => (
          <Carousel.Slide key={video.videoId}>
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <Card 
                withBorder 
                radius="md" 
                p="xl" 
                style={{ 
                  width: '100%', 
                  maxWidth: 480,
                  backgroundColor: 'white',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.1)',
                  transition: 'box-shadow 0.3s ease-in-out',
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06)';
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.boxShadow = '0 2px 4px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.1)';
                }}
              >
                <Card.Section>
                  <HoverCard shadow="md" openDelay={500}>
                    <HoverCard.Target>
                      <div>
                        <Box
                          style={{
                            width: '100%',
                            paddingTop: '56.25%', // 16:9 aspect ratio
                            position: 'relative',
                            overflow: 'hidden',
                            borderRadius: '8px 8px 0 0',
                          }}
                        >
                          <Image
                            src={`https://img.youtube.com/vi/${video.videoId}/hqdefault.jpg`}
                            alt={video.title}
                            style={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              width: '100%',
                              height: 'auto',
                              transform: 'translate(-50%, -50%)',
                              objectFit: 'cover',
                            }}
                          />
                        </Box>
                      </div>
                    </HoverCard.Target>
                    <HoverCard.Dropdown>
                      <Anchor href={`https://www.youtube.com/watch?v=${video.videoId}`} target="_blank" rel="noopener noreferrer">
                        <Group>
                          <Text size="sm">Watch on YouTube</Text>
                          <IconExternalLink size={14} />
                        </Group>
                      </Anchor>
                    </HoverCard.Dropdown>
                  </HoverCard>
                </Card.Section>
                <Stack gap="xs" mt="md">
                  <Group wrap="nowrap" align="flex-start" mt="md">
                    <Badge 
                      size="lg"
                      radius="sm"
                      style={{
                        backgroundColor: getRankColor(video.rank),
                        color: 'white',
                        minWidth: '40px',
                        fontWeight: 'bold'
                      }}
                    >
                      #{video.rank}
                    </Badge>
                    <Tooltip label={video.title} multiline maw={300}>
                      <Text fw={500} lineClamp={2} style={{ flex: 1 }}>{video.title}</Text>
                    </Tooltip>
                  </Group>
                  <Group gap="xs">
                    <Avatar src={video.channelIconUrl} size="sm" radius="xl" />
                    <Text fz="sm">
                      {video.channelName}
                    </Text>
                  </Group>
                  <Text size="sm" c="dimmed" fs="italic">
                    Published: {formatDateUK(new Date(video.publishDate))}
                  </Text>
                  <Divider my="xs" />
                  <Text size="sm">Views: {formatNumber(video.views)}</Text>
                  <Text size="sm">Comments: {formatNumber(video.comments)} | Conversation Rate: {(video.conversationRate).toFixed(2)}</Text>
                  {video.otherRegionRank && (
                    <>
                      <Divider my="xs" />
                      <Text size="sm" fs="italic">
                        Also Trending in {region === 'US' ? 'GB' : 'US'} at rank #{video.otherRegionRank}
                      </Text>
                    </>
                  )}
                </Stack>
              </Card>
            </Box>
          </Carousel.Slide>
        ))}
      </Carousel>
    </Card>
  );
}
