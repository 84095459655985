import React, { useState, useEffect, useCallback } from 'react';
import { Group, ActionIcon, Box } from '@mantine/core';
import { DatePickerSingle } from '../../core/DatePickerSingle';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import { getTrendingAvailableDates } from '../../../utils/api';

interface CustomDatePickerProps {
  onChange: (date: Date) => void;
}

export function CustomDatePicker({ onChange }: CustomDatePickerProps) {
  const [availableDates, setAvailableDates] = useState<Date[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const fetchAvailableDates = useCallback(async () => {
    try {
      console.log('Fetching available dates');
      const response = await getTrendingAvailableDates();
      const dates = response.data.dates.map((dateString: string) => new Date(dateString));
      console.log('Available dates fetched:', dates);
      setAvailableDates(dates);
      
      if (dates.length > 0 && !selectedDate) {
        const mostRecentDate = dates[0];
        console.log('Setting initial date to:', mostRecentDate.toISOString());
        setSelectedDate(mostRecentDate);
        onChange(mostRecentDate);
      }
    } catch (error) {
      console.error('Failed to fetch available dates:', error);
    }
  }, [onChange, selectedDate]);

  useEffect(() => {
    fetchAvailableDates();
  }, [fetchAvailableDates]);

  const onPrevDate = useCallback(() => {
    if (availableDates.length === 0 || !selectedDate) return;
    const currentIndex = availableDates.findIndex(date => date.getTime() === selectedDate.getTime());
    if (currentIndex < availableDates.length - 1) {
      const newDate = availableDates[currentIndex + 1];
      console.log('Moving to previous date:', newDate.toISOString());
      setSelectedDate(newDate);
      onChange(newDate);
    }
  }, [availableDates, selectedDate, onChange]);

  const onNextDate = useCallback(() => {
    if (availableDates.length === 0 || !selectedDate) return;
    const currentIndex = availableDates.findIndex(date => date.getTime() === selectedDate.getTime());
    if (currentIndex > 0) {
      const newDate = availableDates[currentIndex - 1];
      console.log('Moving to next date:', newDate.toISOString());
      setSelectedDate(newDate);
      onChange(newDate);
    }
  }, [availableDates, selectedDate, onChange]);

  const handleDateChange = useCallback((date: Date | null) => {
    if (date) {
      console.log('Date selected in picker:', date.toISOString());
      setSelectedDate(date);
      onChange(date);
    }
  }, [onChange]);

  const minDate = availableDates[availableDates.length - 1];
  const maxDate = availableDates[0];

  const excludeDate = useCallback((date: Date) => 
    !availableDates.some(d => d.toDateString() === date.toDateString()),
    [availableDates]
  );

  return (
    <Group gap="xs" align="center">
      <ActionIcon onClick={onPrevDate} variant="transparent" size="sm">
        <IconChevronLeft size={16} color='#868e96' />
      </ActionIcon>
      <Box mx={-8}>
        <DatePickerSingle 
          value={selectedDate} 
          onChange={handleDateChange}
          minDate={minDate}
          maxDate={maxDate}
          excludeDate={excludeDate}
        />
      </Box>
      <ActionIcon onClick={onNextDate} variant="transparent" size="sm">
        <IconChevronRight size={16} color='#868e96' />
      </ActionIcon>
    </Group>
  );
}