import React from 'react';
import { Group, Select, MultiSelect } from '@mantine/core';
import { FilterMode, AgeCategory, AGE_CATEGORIES } from '../../../utils/channelNetworkUtils';

const EDGE_TYPE_LABELS: Record<string, string> = {
  'shorts_related': 'Shorts Related',
  'description': 'Description',
  'endscreen': 'Endscreen'
};

interface NetworkFilterProps {
  filterMode: FilterMode;
  onFilterModeChange: (mode: FilterMode) => void;
  edgeTypes: string[];
  selectedEdgeTypes: string[];
  onEdgeTypesChange: (types: string[]) => void;
  selectedAgeCategories: AgeCategory[];
  onAgeCategoriesChange: (categories: AgeCategory[]) => void;
}

export function NetworkFilter({
  filterMode,
  onFilterModeChange,
  edgeTypes,
  selectedEdgeTypes,
  onEdgeTypesChange,
  selectedAgeCategories,
  onAgeCategoriesChange
}: NetworkFilterProps) {
  return (
    <Group align="flex-end" gap="md">
      <Select
        placeholder="Select filter type"
        value={filterMode}
        onChange={(value) => {
          const newMode = value as FilterMode;
          onFilterModeChange(newMode);
          if (newMode === 'edge') onAgeCategoriesChange([]);
          if (newMode === 'age') onEdgeTypesChange([]);
        }}
        data={[
          { value: 'edge', label: 'Edge Type' },
          { value: 'age', label: 'Content Age' }
        ]}
        style={{ width: 200 }}
        clearable
      />

      {filterMode === 'edge' && (
        <MultiSelect
          placeholder="Select edge types"
          data={edgeTypes.map(type => ({
            value: type,
            label: EDGE_TYPE_LABELS[type] || type // Fallback to raw value if no label defined
          }))}
          value={selectedEdgeTypes}
          onChange={onEdgeTypesChange}
          style={{ width: 300 }}
          searchable
          clearable
        />
      )}

      {filterMode === 'age' && (
        <MultiSelect
          placeholder="Select age ranges"
          data={AGE_CATEGORIES.map(category => ({
            value: category,
            label: category
          }))}
          value={selectedAgeCategories}
          onChange={(value) => onAgeCategoriesChange(value as AgeCategory[])}
          style={{ width: 300 }}
          clearable
        />
      )}
    </Group>
  );
}