import React, { useState, useEffect } from 'react';
import { Combobox, InputBase, Image, Group, Text, Box, useCombobox } from '@mantine/core';
import { getExperimentChannels } from '../../../utils/api';

interface Channel {
  channelId: string;
  channelName: string;
  channelIconUrl: string;
  contentTypes: string[];
}

interface ExperimentsChannelPickerProps {
  value: string | null;
  onChange: (channelId: string | null) => void;
}

export function ExperimentsChannelPicker({ value, onChange }: ExperimentsChannelPickerProps) {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
  });

  const [channels, setChannels] = useState<Channel[]>([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');

  useEffect(() => {
    const fetchChannels = async () => {
      try {
        const response = await getExperimentChannels();
        setChannels(response.data.channels);
        setLoading(false);
      } catch (err) {
        console.error('Failed to fetch channels:', err);
        setLoading(false);
      }
    };

    fetchChannels();
  }, []);

  const filteredChannels = channels.filter((channel) =>
    channel.channelName.toLowerCase().includes(search.trim().toLowerCase())
  );

  const options = filteredChannels.map((channel) => (
    <Combobox.Option value={channel.channelId} key={channel.channelId}>
      <Group gap="sm">
        <Image src={channel.channelIconUrl} width={24} height={24} radius="xl" />
        <span>{channel.channelName}</span>
      </Group>
    </Combobox.Option>
  ));

  const selectedChannel = channels.find((channel) => channel.channelId === value);

  return (
    <Box style={{ width: '300px' }}>
      <Combobox
        store={combobox}
        onOptionSubmit={(val) => {
          onChange(val);
          combobox.closeDropdown();
        }}
      >
        <Combobox.Target>
          <InputBase
            component="button"
            pointer
            rightSection={<Combobox.Chevron />}
            onClick={() => combobox.toggleDropdown()}
            rightSectionPointerEvents="none"
            style={{ width: '100%' }}
          >
            {selectedChannel ? (
              <Group gap="sm">
                <Image src={selectedChannel.channelIconUrl} width={24} height={24} radius="xl" />
                <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {selectedChannel.channelName}
                </span>
              </Group>
            ) : (
              <span>Select a channel</span>
            )}
          </InputBase>
        </Combobox.Target>

        <Combobox.Dropdown>
          <Combobox.Search
            value={search}
            onChange={(event) => setSearch(event.currentTarget.value)}
            placeholder="Search channels"
          />
          <Combobox.Options>
            {loading ? (
              <Combobox.Empty>Loading channels...</Combobox.Empty>
            ) : options.length === 0 ? (
              <Combobox.Empty>No channels found</Combobox.Empty>
            ) : (
              options
            )}
          </Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
    </Box>
  );
}
