// Styles Imports
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/carousel/styles.css';

// React Imports
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { MantineProvider } from '@mantine/core';

// Auth Imports
import Login from './components/login/Login';
import ProtectedRoute from './components/login/ProtectedRoute';
import { Logout } from './components/login/Logout';

// Page Imports
// Trending
import { TrendingSummary } from './components/trending/pages/TrendingSummary';
import { TrendingPerformance } from './components/trending/pages/TrendingPerformance';
import { TrendingNetworks } from './components/trending/pages/TrendingNetworks';
import { TrendingData } from './components/trending/pages/TrendingData';
// Channels
import { ChannelsChannelSet } from './components/channels/pages/Channels';
import { ChannelVideosSet } from './components/channels/pages/Videos';
import { ChannelNetworksSet } from './components/channels/pages/Networks';
// import { ChannelClassifications } from './components/channels/pages/Channels/ChannelsClassification';
import { ChannelPerformance } from './components/channels/pages/Performance';
import { ChannelsData } from './components/channels/pages/ChannelsData';
// Experiments
import { UMAPNetwork } from './components/experiments/pages/ExperimentsUmap';
import { SourceSubNetwork } from './components/experiments/pages/ExperimentsSourceSubsidiary';
import { ThumbnailScoresScores } from './components/experiments/pages/ExperimentsThumbnailScores';
// import ChannelSankeyFlow from './components/experiments/pages/ChannelsFlow';
// Docs
import { DocsNew } from './components/docs/pages/DocsNew';
import { DocsHelp } from './components/docs/pages/DocsHelp';
import { DocsReadMe } from './components/docs/pages/DocsReadMe';
// var
import { NotFound } from './components/core/NotFound';

// Main App Function
function App() {
  return (
    <MantineProvider>
      <Router>
        <Routes>  
          {/* Login Route */}
          <Route path="/login" element={<Login />} />
          {/* Protected Routes */}
          <Route element={<ProtectedRoute />}>
            {/* Trending Routes */}
            <Route path="/trending/yesterday/" element={<TrendingSummary />} />
            <Route path="/trending/performance/" element={<TrendingPerformance />} />
            <Route path="/trending/networks/" element={<TrendingNetworks />} />
            <Route path="/trending/data/" element={<TrendingData />} />
            {/* Channel routes */}
            <Route path="/channels/channels/:subpage?" element={<ChannelsChannelSet />} />
            <Route path="/channels/video/:subpage?" element={<ChannelVideosSet />} />
            <Route path="/channels/networks/:subpage?" element={<ChannelNetworksSet />} />
            {/* <Route path="/channels/staging" element={<ChannelClassifications />} /> */}
            <Route path="/channels/performance/:subpage?" element={<ChannelPerformance />} />
            <Route path="/channels/data/" element={<ChannelsData />} />
            {/* Experiments Routes */}
            <Route path="/experiments/model-explorer/" element={<UMAPNetwork />} />
            <Route path="/experiments/source-subsidiary/" element={<SourceSubNetwork />} />
            <Route path="/experiments/thumbnail-scores/" element={<ThumbnailScoresScores />} />
            {/* Docs routes */}
            <Route path="/docs/new-one/" element={<DocsNew />} />
            <Route path="/docs/help/" element={<DocsHelp />} />
            <Route path="/docs/readme/" element={<DocsReadMe />} />
            {/* 404 Route */}
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route path="/" element={<Navigate to="/channels/video/" replace />} />
          {/* Logout Route */}
          <Route path="/logout" element={<Logout />} />
        </Routes>
      </Router>
    </MantineProvider>
  );
}

export default App;