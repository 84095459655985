import React, { useState, useCallback } from 'react';
import { Paper, Stack, Text, Group } from '@mantine/core';
import { ChannelPicker } from '../components/ChannelPicker';
import { ChannelDataGrid } from '../components/ChannelDataGrid';
import { IconChevronRight, IconExternalLink } from '@tabler/icons-react';
import { Link } from 'react-router-dom';

const navItems = [
  { title: 'Channels', href: '/channels/data' },
  { title: 'Channel', href: '/channels/channels/channel' },
  { title: 'Video', href: '/channels/video/video' },
  { title: 'Networks', href: '/channels/networks/network' },
  { title: 'Performance', href: '/channels/performance/two-d' },
  { title: 'Data', href: '/channels/data' },
  { title: 'Notes', href: 'https://brick-river-8a5.notion.site/Channels-Data-11f6777fc9558047898fe606d1b6c413', isExternal: true },
];

export function ChannelsData() {
  const [selectedChannel, setSelectedChannel] = useState<string | null>(null);

  const onChannelChange = useCallback((channel: string | null) => {
    setSelectedChannel(channel);
  }, []);

  return (
    <Stack gap="md">
      <Group gap={5}>
        {navItems.map((item, index) => (
          <React.Fragment key={item.title}>
            {index > 0 && index < 2 && <IconChevronRight size={14} color='#868e96'/>}
            {index > 1 && <Text size="sm" color="dimmed">|</Text>}
            {item.isExternal ? (
              <Text
                component="a"
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
                size="sm"
                c="#0982eb"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {item.title}
                <IconExternalLink size={14} style={{ marginLeft: 5 }} />
              </Text>
            ) : (
              <Text
                component={Link}
                to={item.href}
                size="sm"
                fw={index >= 1 && location.pathname === item.href ? 500 : 'normal'}
                td={index >= 1 && location.pathname === item.href ? 'underline' : 'none'}
                c={index < 1 ? 'dimmed' : '#0982eb'}
              >
                {item.title}
              </Text>
            )}
      </React.Fragment>
        ))}
      </Group>
        <Stack gap="md">
          <ChannelPicker value={selectedChannel} onChange={onChannelChange} />
          <Paper
                p="md" 
                radius="md" 
                withBorder
                style={{ 
                      transition: 'box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out',
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.1)';
                      e.currentTarget.style.transform = 'translateY(-5px)';
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.boxShadow = '';
                      e.currentTarget.style.transform = '';
                    }}
                  >
          {selectedChannel ? (
            <ChannelDataGrid key={selectedChannel} channelId={selectedChannel} />
          ) : (
            <Text>Select a channel to get started... This may take a second or two.</Text>
          )}
          </Paper>
        </Stack>
    </Stack>
  );
}