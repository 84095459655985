import React from 'react';
import { Combobox, InputBase, Group, useCombobox } from '@mantine/core';
import { IconTopologyStar, IconChartArrowsVertical } from '@tabler/icons-react';

export type ViewType = 'network' | 'network2' | 'value';

const viewTypes: { value: ViewType; label: string; icon: React.ReactNode }[] = [
  { value: 'network', label: 'Network View', icon: <IconTopologyStar size={16} /> },
  { value: 'value', label: 'Value Comparison', icon: <IconChartArrowsVertical size={16} /> },
];

interface ViewTypePickerProps {
  value: ViewType;
  onChange: (value: ViewType) => void;
}

export function ViewTypePicker({ value, onChange }: ViewTypePickerProps) {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
  });

  const selectedType = viewTypes.find(type => type.value === value);

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={(val) => {
        onChange(val as ViewType);
        combobox.closeDropdown();
      }}
    >
      <Combobox.Target>
        <InputBase
          component="button"
          pointer
          rightSection={<Combobox.Chevron />}
          onClick={() => combobox.toggleDropdown()}
          rightSectionPointerEvents="none"
          style={{ width: '100%', maxWidth: '200px' }}
        >
          {selectedType ? (
            <Group gap="sm">
              {selectedType.icon}
              <span>{selectedType.label}</span>
            </Group>
          ) : (
            <span>Select view type</span>
          )}
        </InputBase>
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options>
          {viewTypes.map((type) => (
            <Combobox.Option value={type.value} key={type.value}>
              <Group gap="sm">
                {type.icon}
                <span>{type.label}</span>
              </Group>
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}