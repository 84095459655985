import React from 'react';
import { Text, Group, Stack, Container, Title, Paper } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import { IconChevronRight, IconExternalLink } from '@tabler/icons-react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ValueFormatterParams } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';

const navItems = [
  { title: 'Docs', href: '/docs/new-one' },
  { title: 'New!', href: '/docs/new-one' },
  { title: 'Help Directory', href: '/docs/help' },
  { title: 'ReadMe', href: '/docs/readme', isExternal: false },
];

const helpData = [
  { page: '2D', link: 'https://brick-river-8a5.notion.site/2D-1216777fc95580b4950bef02de3565d6' },
  { page: 'Channel', link: 'https://brick-river-8a5.notion.site/Channel-11f6777fc95580f78c07d2219639b06c' },
  { page: 'Compare Channels', link: 'https://brick-river-8a5.notion.site/Compare-Channels-1336777fc955809b9a98fb4a8d4422d2' },
  { page: 'Compare Videos', link: 'https://brick-river-8a5.notion.site/Compare-Videos-1246777fc955806d9423fdcf6064ac18' },
  { page: 'Content Classification', link: 'https://brick-river-8a5.notion.site/Content-Classification-1356777fc955802a8930f5722329b738' },
  { page: 'Content Types', link: 'https://brick-river-8a5.notion.site/Content-Types-1246777fc9558008abe5ee04ab29e2e6' },
  { page: '(Channels) Data', link: 'https://brick-river-8a5.notion.site/Data-11f6777fc9558047898fe606d1b6c413?' },
  { page: '(Trending) Data', link: 'https://brick-river-8a5.notion.site/Data-11f6777fc95580cda742ec3662c30569' },
  { page: 'FAQs', link: 'https://brick-river-8a5.notion.site/FAQs-1086777fc95580508665fedcb2d098bc' },
  { page: 'Feedback & Access', link: 'https://brick-river-8a5.notion.site/Feedback-Access-1126777fc95580abbd66f73c92284e2c' },
  { page: 'Metrics', link: 'https://brick-river-8a5.notion.site/Metrics-1246777fc955806b811be92863b878a2' },
  { page: 'Model Explorer', link: 'https://brick-river-8a5.notion.site/Model-Explorer-1216777fc955800d80dce6b3eed2b2bf' },
  { page: 'Model Topics', link: 'https://brick-river-8a5.notion.site/Model-Topics-1246777fc95580d1ac24d0e6daf05d2f' },
  { page: '(Channels) Network', link: 'https://brick-river-8a5.notion.site/Network-11f6777fc95580009b04e2ad5f23c0ce' },
  { page: '(Trending) Networks', link: 'https://brick-river-8a5.notion.site/Networks-11f6777fc95580c6bd7dc1cf7b9ba41a' },
  { page: 'Performance', link: 'https://brick-river-8a5.notion.site/Performance-11f6777fc95580eab953de8d8357b2e8' },
  { page: 'Source / Subsidiary', link: 'https://brick-river-8a5.notion.site/Source-Subsidiary-12a6777fc95580b68943f63d60e76f06' },
  { page: 'Subgraph', link: 'https://brick-river-8a5.notion.site/Subgraph-11f6777fc9558044b8e7f9daafdbdf55' },
  { page: 'Swarm', link: 'https://brick-river-8a5.notion.site/Swarm-1216777fc9558017b783c9fe0ee697a2' },
  { page: 'Timeline', link: 'https://brick-river-8a5.notion.site/Timeline-1216777fc95580ae9b00fe8a4e921fa7' },
  { page: 'Top 5', link: 'https://brick-river-8a5.notion.site/Top-5-11f6777fc95580c68bb1daa4c393a70d' },
  { page: 'Tracked Channels', link: 'https://brick-river-8a5.notion.site/Tracked-Channels-1126777fc95580f39bc6cc5d7b7dbcc9' },
  { page: 'Tracked Videos', link: 'https://brick-river-8a5.notion.site/Tracked-Videos-1246777fc955806d9423fdcf6064ac18' },
  { page: 'Treemap', link: 'https://brick-river-8a5.notion.site/Treemap-1216777fc955806a82bec55c07fb640a' },
  { page: 'Video', link: 'https://brick-river-8a5.notion.site/Video-11f6777fc955803c8f47fe7a3a977b46' },
  { page: 'Video Analyses', link: 'https://brick-river-8a5.notion.site/Video-Analyses-12c6777fc95580ed9a51c5a6948c208b' },
  { page: 'Yesterday', link: 'https://brick-river-8a5.notion.site/Yesterday-11f6777fc95580b4a6d2ce2862f05a45' },
  { page: "YouTube's Topics", link: 'https://brick-river-8a5.notion.site/YouTube-s-Topics-1246777fc95580e2a51de38ad9a54adc' },
  { page: 'All Pages', link: 'https://brick-river-8a5.notion.site/1086777fc95580abbfd1c8f85f01c198?v=1086777fc95580a1857d000cea89d780' },
];

interface HelpData {
  page: string;
  link: string;
}

const columnDefs: ColDef<HelpData>[] = [
  { 
    headerName: 'Page', 
    field: 'page', 
    sortable: true, 
    filter: true 
  },
  { 
    headerName: 'Link', 
    field: 'link', 
    sortable: true, 
    filter: true,
    cellRenderer: (params: ValueFormatterParams<HelpData, string>) => {
      if (params.value) {
        return (
          <a href={params.value} target="_blank" rel="noopener noreferrer">
            {params.value}
          </a>
        );
      }
      return null;
    }
  },
];

export function DocsHelp() {
  const location = useLocation();

  return (
    <Stack gap="xs">
      <Group gap={5}>
        {navItems.map((item, index) => (
          <React.Fragment key={item.title}>
            {index > 0 && index < 2 && <IconChevronRight size={14} color='#868e96'/>}
            {index > 1 && <Text size="sm" color="dimmed">|</Text>}
            {item.isExternal ? (
              <Text
                component="a"
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
                size="sm"
                c="#0982eb"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {item.title}
                <IconExternalLink size={14} style={{ marginLeft: 5 }} />
              </Text>
            ) : (
              <Text
                component={Link}
                to={item.href}
                size="sm"
                fw={index >= 1 && location.pathname === item.href ? 500 : 'normal'}
                td={index >= 1 && location.pathname === item.href ? 'underline' : 'none'}
                c={index < 1 ? 'dimmed' : '#0982eb'}
              >
                {item.title}
              </Text>
            )}
          </React.Fragment>
        ))}
      </Group>

      <Container size="lg">
        <Paper p="md" withBorder>
          <Title order={1} mb="xl">Help Directory</Title>
          <div className="ag-theme-quartz" style={{ height: 600, width: '100%' }}>
            <AgGridReact
              columnDefs={columnDefs}
              rowData={helpData}
              pagination={true}
              paginationPageSize={50}
            />
          </div>
        </Paper>
      </Container>
    </Stack>
  );
}
