import React, { useMemo } from 'react';
import { Stack, Text, HoverCard, Box, Group } from '@mantine/core';
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from 'recharts';
import { formatNumber, formatDate } from '../../../utils/formatter';

interface DailyData {
  date: string;
  [key: string]: number | string;
}

interface ChannelData {
  viewsData: DailyData[];
  libraryData: DailyData[];
  name: string;
}

interface ChannelComparisonChartProps {
  channel1Data: ChannelData;
  channel2Data: ChannelData;
}

const ChannelComparisonChart = ({ channel1Data, channel2Data }: ChannelComparisonChartProps) => {
  console.log('Chart received data:', {
    channel1: channel1Data,
    channel2: channel2Data
  });

  const processedData = useMemo(() => {
    const allDates = new Set([
      ...channel1Data.viewsData.map(d => d.date),
      ...channel2Data.viewsData.map(d => d.date)
    ]);

    const data = Array.from(allDates).sort().map(date => {
      const channel1Views = channel1Data.viewsData.find(d => d.date === date);
      const channel2Views = channel2Data.viewsData.find(d => d.date === date);
      const channel1Lib = channel1Data.libraryData.find(d => d.date === date);
      const channel2Lib = channel2Data.libraryData.find(d => d.date === date);

      // Log the library data for this date
      console.log(`Processing date ${date}:`, {
        channel1Library: channel1Lib,
        channel2Library: channel2Lib
      });

      // Calculate total views
      const getTotalViews = (dayData: DailyData | undefined) => {
        if (!dayData) return null;
        return Object.entries(dayData).reduce((sum, [key, value]) => 
          key !== 'date' && typeof value === 'number' ? sum + value : sum, 0
        );
      };

      // Calculate publications
      const getPublications = (libDay: DailyData | undefined) => {
        if (!libDay) return { total: 0, details: {} };
        
        const details = Object.entries(libDay).reduce((acc, [key, value]) => {
          if (key !== 'date' && key !== 'untracked' && typeof value === 'number' && value > 0) {
            acc[key] = value;
          }
          return acc;
        }, {} as Record<string, number>);

        const total = Object.values(details).reduce((sum, count) => sum + count, 0);
        
        return { total, details };
      };

      const ch1Pubs = getPublications(channel1Lib);
      const ch2Pubs = getPublications(channel2Lib);

      return {
        date,
        channel1Views: getTotalViews(channel1Views),
        channel2Views: getTotalViews(channel2Views),
        channel1Publications: ch1Pubs.total,
        channel2Publications: ch2Pubs.total,
        channel1PubDetails: ch1Pubs.details,
        channel2PubDetails: ch2Pubs.details
      };
    });

    console.log('Processed data:', data);
    return data;
  }, [channel1Data, channel2Data]);


  const PublicationBadges = ({ x, y, payload }: any) => {
    const dayData = processedData.find(d => d.date === payload.value);
    if (!dayData) return null;

    return (
      <>
        {dayData.channel1Publications > 0 && (
          <foreignObject 
            x={x - 15} 
            y={y - 90} 
            width={20} 
            height={20}
          >
            <HoverCard width={200} shadow="md">
              <HoverCard.Target>
                <Box
                  style={{
                    backgroundColor: '#FF0000',
                    color: 'white',
                    padding: '2px 4px',
                    borderRadius: '3px',
                    fontSize: '10px',
                    textAlign: 'center',
                    cursor: 'pointer'
                  }}
                >
                  {dayData.channel1Publications}
                </Box>
              </HoverCard.Target>
              <HoverCard.Dropdown>
                <Stack gap="xs">
                  <Text size="sm" fw={500}>Published on {formatDate(payload.value)}</Text>
                  {Object.entries(dayData.channel1PubDetails).map(([type, count]) => (
                    <Text key={type} size="xs">
                      {type}: {count}
                    </Text>
                  ))}
                </Stack>
              </HoverCard.Dropdown>
            </HoverCard>
          </foreignObject>
        )}
        {dayData.channel2Publications > 0 && (
          <foreignObject 
            x={x - 15} 
            y={y - 110} 
            width={20} 
            height={20}
          >
            <HoverCard width={200} shadow="md">
              <HoverCard.Target>
                <Box
                  style={{
                    backgroundColor: '#0d3fa6',
                    color: 'white',
                    padding: '2px 4px',
                    borderRadius: '3px',
                    fontSize: '10px',
                    textAlign: 'center',
                    cursor: 'pointer'
                  }}
                >
                  {dayData.channel2Publications}
                </Box>
              </HoverCard.Target>
              <HoverCard.Dropdown>
                <Stack gap="xs">
                  <Text size="sm" fw={500}>Published on {formatDate(payload.value)}</Text>
                  {Object.entries(dayData.channel2PubDetails).map(([type, count]) => (
                    <Text key={type} size="xs">
                      {type}: {count}
                    </Text>
                  ))}
                </Stack>
              </HoverCard.Dropdown>
            </HoverCard>
          </foreignObject>
        )}
      </>
    );
  };

  const CustomizedAxisTick = ({ x, y, payload }: any) => (
    <g transform={`translate(${x},${y})`}>
      <text 
        x={0} 
        y={0} 
        dy={16} 
        textAnchor="end" 
        fill="#666" 
        transform="rotate(-35)"
        style={{ fontSize: '12px' }}
      >
        {formatDate(payload.value)}
      </text>
    </g>
  );

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const data = processedData.find(d => d.date === label);
      if (!data) return null;

      return (
        <Box style={{ 
          backgroundColor: 'white', 
          padding: '10px', 
          border: '1px solid #ccc',
          borderRadius: '4px'
        }}>
          <Text size="sm" fw={500}>{formatDate(label)}</Text>
          
          {data.channel1Views !== null && (
            <Group gap="xs">
              <Box w={12} h={12} style={{ backgroundColor: '#FF0000', borderRadius: '50%' }} />
              <Text size="sm">{channel1Data.name}: {formatNumber(data.channel1Views)}</Text>
              {data.channel1Publications > 0 && (
                <Text size="xs" c="dimmed">({data.channel1Publications} published)</Text>
              )}
            </Group>
          )}
          
          {data.channel2Views !== null && (
            <Group gap="xs">
              <Box w={12} h={12} style={{ backgroundColor: '#0d3fa6', borderRadius: '50%' }} />
              <Text size="sm">{channel2Data.name}: {formatNumber(data.channel2Views)}</Text>
              {data.channel2Publications > 0 && (
                <Text size="xs" c="dimmed">({data.channel2Publications} published)</Text>
              )}
            </Group>
          )}
        </Box>
      );
    }
    return null;
  };

  return (
    <ResponsiveContainer width="100%" height={600}>
      <ComposedChart 
        data={processedData}
        margin={{ top: 20, right: 30, left: 60, bottom: 60 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis 
          dataKey="date"
          axisLine={false}
          tickLine={false}
          interval={0}
          tick={<PublicationBadges />}
          height={40}
          xAxisId="publications"
          padding={{ left: 20, right: 20 }}
        />
        <XAxis 
          dataKey="date"
          tick={<CustomizedAxisTick />}
          height={60}
          padding={{ left: 20, right: 20 }}
        />
        <YAxis
          tickFormatter={formatNumber}
          width={60}
        />
        <Tooltip content={<CustomTooltip />} />
        <Line
          type="linear"
          dataKey="channel1Views"
          stroke="#FF0000"
          strokeWidth={3}
          dot={true}
          activeDot={{ r: 8 }}
          isAnimationActive={true}
          animationDuration={2000}
        />
        <Line
          type="linear"
          dataKey="channel2Views"
          stroke="#0d3fa6"
          strokeWidth={3}
          dot={true}
          activeDot={{ r: 8 }}
          isAnimationActive={true}
          animationDuration={2000}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default ChannelComparisonChart;