import React from 'react';
import { Text, Group, Stack, Container, Title, Paper, List } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import { IconChevronRight, IconExternalLink } from '@tabler/icons-react';

const navItems = [
  { title: 'Docs', href: '/docs/new-one' },
  { title: 'New!', href: '/docs/new-one' },
  { title: 'Help Directory', href: '/docs/help' },
  { title: 'ReadMe', href: '/docs/readme', isExternal: false },
];

export function DocsReadMe() {
  const location = useLocation();

  return (
    <Stack gap="xs">
      <Group gap={5}>
        {navItems.map((item, index) => (
          <React.Fragment key={item.title}>
            {index > 0 && index < 2 && <IconChevronRight size={14} color='#868e96'/>}
            {index > 1 && <Text size="sm" color="dimmed">|</Text>}
            {item.isExternal ? (
              <Text
                component="a"
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
                size="sm"
                c="#0982eb"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {item.title}
                <IconExternalLink size={14} style={{ marginLeft: 5} } />
              </Text>
            ) : (
              <Text
                component={Link}
                to={item.href}
                size="sm"
                fw={index >= 1 && location.pathname === item.href ? 500 : 'normal'}
                td={index >= 1 && location.pathname === item.href ? 'underline' : 'none'}
                c={index < 1 ? 'dimmed' : '#0982eb'}
              >
                {item.title}
              </Text>
            )}
          </React.Fragment>
        ))}
      </Group>

      <Container size="lg">
        <Paper p="md" withBorder>
          <Title order={1}>ReadMe</Title>
          
          <Title order={2} mt="xl">Back End</Title>
          <List>
            <List.Item>YouTube Data API v3 for ingest
              <List withPadding>
                <List.Item>Daily ingests for videos published in the last month (31 days) or with &gt;100k views total</List.Item>
                <List.Item>Daily ingests for new videos</List.Item>
                <List.Item>Daily updates to channel performance</List.Item>
                <List.Item>Daily updates for Trending videos (50 in the US, 50 in GB)</List.Item>
              </List>
            </List.Item>
            <List.Item>BERTopic for topic modelling
              <List withPadding>
                <List.Item>KeyBERT for keyword vocabularies and topic naming</List.Item>
                <List.Item>spacy Part of Speech</List.Item>
                <List.Item>NLTK</List.Item>
                <List.Item>gensim</List.Item>
                <List.Item>sklearn</List.Item>
              </List>
            </List.Item>
            <List.Item>Back end mostly runs on:
              <List withPadding>
                <List.Item>Google Colabs</List.Item>
                <List.Item>Cloud Run Functions</List.Item>
                <List.Item>BigQuery</List.Item>
                <List.Item>Redis</List.Item>
                <List.Item>mongoDB</List.Item>
                <List.Item>Express.js API</List.Item>
              </List>
            </List.Item>
          </List>

          <Title order={2} mt="xl">Front End</Title>
          <List>
            <List.Item>Built with React / TypeScript</List.Item>
            <List.Item>Mantine Components used throughout, plus:
              <List withPadding>
                <List.Item>Mantine-Dates</List.Item>
                <List.Item>Mantine-Carousel</List.Item>
              </List>
            </List.Item>
            <List.Item>Tabler Icons for iconography</List.Item>
            <List.Item>AGGrid for tables</List.Item>
            <List.Item>recharts for simpler visualizations (things like bar charts, scatter graphs)</List.Item>
            <List.Item>react-Plotly.js for medium-level / slightly more complex visualizations</List.Item>
            <List.Item>react-force-graph (2D) for all network graphs</List.Item>
            <List.Item>Notion for docs</List.Item>
          </List>

          <Title order={2} mt="xl">Others</Title>
          <List>
            <List.Item>Coding mostly by Claude 3.5 Sonnet
              <List withPadding>
                <List.Item>and also Cursor</List.Item>
              </List>
            </List.Item>
          </List>
        </Paper>
      </Container>
    </Stack>
  );
}