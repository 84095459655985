import React, { useState, useEffect } from 'react';
import { Combobox, InputBase, useCombobox, Text, Box, ScrollArea } from '@mantine/core';
import { getChannelVideos } from '../../../utils/api';
import { formatNumber, formatDate } from '../../../utils/formatter';

interface Video {
  id: string;
  title: string;
  type: string;
  views: number;
  publishDate: string;
}

interface VideoPickerProps {
  channelId: string | null;
  contentType: string;
  onChange: (videoId: string | null) => void;
}

export function VideoPicker({ channelId, contentType, onChange }: VideoPickerProps) {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
  });

  const [videos, setVideos] = useState<Video[]>([]);
  const [selectedVideo, setSelectedVideo] = useState<Video | null>(null);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (channelId && contentType) {
      fetchVideos();
    } else {
      setVideos([]);
      setSelectedVideo(null);
    }
  }, [channelId, contentType]);

  const fetchVideos = async () => {
    if (!channelId || !contentType) return;
  
    setLoading(true);
    try {
      const fetchedVideos = await getChannelVideos(channelId, contentType);
      setVideos(fetchedVideos.sort((a: Video, b: Video) => new Date(b.publishDate).getTime() - new Date(a.publishDate).getTime()));
    } catch (err) {
      console.error('Failed to fetch videos:', err);
      setVideos([]);
    } finally {
      setLoading(false);
    }
  };
  
  const filteredVideos = videos.filter((video) => 
    video.title.toLowerCase().includes(search.trim().toLowerCase())
  );

  const truncateTitle = (title: string, maxLength: number = 60) => {
    return title.length > maxLength ? `${title.substring(0, maxLength)}...` : title;
  };

  const options = filteredVideos.map((video) => (
    <Combobox.Option value={video.id} key={video.id}>
      <Box>
        <Text size="sm">{truncateTitle(video.title)}</Text>
        <Text size="xs" c="dimmed" mt={4}>
          Views: {formatNumber(video.views)} | Published: {formatDate(video.publishDate)}
        </Text>
      </Box>
    </Combobox.Option>
  ));

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={(val) => {
        const video = videos.find((v) => v.id === val);
        setSelectedVideo(video || null);
        onChange(val);
        combobox.closeDropdown();
      }}
    >
      <Combobox.Target>
        <InputBase
          component="button"
          pointer
          rightSection={<Combobox.Chevron />}
          onClick={() => combobox.toggleDropdown()}
          rightSectionPointerEvents="none"
          style={{ width: '100%', maxWidth: '300px' }}
        >
          {selectedVideo ? (
            <Box>
              <Text size="xs" truncate>{selectedVideo.title}</Text>
              <Text size="xs" c="dimmed">
                Views: {formatNumber(selectedVideo.views)} | Published: {formatDate(selectedVideo.publishDate)}
              </Text>
            </Box>
          ) : (
            <span>Select a video</span>
          )}
        </InputBase>
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Search
          value={search}
          onChange={(event) => setSearch(event.currentTarget.value)}
          placeholder="Search videos"
        />
        <ScrollArea.Autosize mah={200}>
          <Combobox.Options>
            {loading ? (
              <Combobox.Empty>Loading videos...</Combobox.Empty>
            ) : filteredVideos.length === 0 ? (
              <Combobox.Empty>No videos found</Combobox.Empty>
            ) : (
              options
            )}
          </Combobox.Options>
        </ScrollArea.Autosize>
      </Combobox.Dropdown>
    </Combobox>
  );
}

export default VideoPicker;