import React from 'react';
import { Combobox, InputBase, Group, useCombobox } from '@mantine/core';
import { IconChartAreaLine, IconChartBar, IconAlpha, IconBeta } from '@tabler/icons-react';

type ChartType = 'weibull' | 'daily-weibull' | 'alpha' | 'beta';

const chartTypes: { value: ChartType; label: string; icon: React.ReactNode }[] = [
  { value: 'alpha', label: 'Alpha Analysis', icon: <IconAlpha size={16} /> },
  { value: 'beta', label: 'Beta Analysis', icon: <IconBeta size={16} /> },
  { value: 'daily-weibull', label: 'Weibull v2', icon: <IconChartBar size={16} /> },
  { value: 'weibull', label: 'Weibull v1', icon: <IconChartAreaLine size={16} /> }
];

interface ChartTypePickerProps {
  value: ChartType;
  onChange: (value: ChartType) => void;
}

export function ExperimentsChartPicker({ value, onChange }: ChartTypePickerProps) {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
  });

  const selectedType = chartTypes.find(type => type.value === value);

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={(val) => {
        onChange(val as ChartType);
        combobox.closeDropdown();
      }}
    >
      <Combobox.Target>
        <InputBase
          component="button"
          pointer
          rightSection={<Combobox.Chevron />}
          onClick={() => combobox.toggleDropdown()}
          rightSectionPointerEvents="none"
          style={{ width: '100%', maxWidth: '200px' }}
        >
          {selectedType ? (
            <Group gap="sm">
              {selectedType.icon}
              <span>{selectedType.label}</span>
            </Group>
          ) : (
            <span>Select analysis type</span>
          )}
        </InputBase>
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options>
          {chartTypes.map((type) => (
            <Combobox.Option value={type.value} key={type.value}>
              <Group gap="sm">
                {type.icon}
                <span>{type.label}</span>
              </Group>
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}