import React, { useMemo } from 'react';
import { Stack, Text, HoverCard, Box } from '@mantine/core';
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from 'recharts';
import { formatNumber, formatDate } from '../../../utils/formatter';

interface DailyData {
  date: string;
  [key: string]: number | string;
}

interface DailyViewsChartProps {
  viewsData: DailyData[];
  libraryData: DailyData[];
}

const DailyViewsChart = ({ viewsData, libraryData }: DailyViewsChartProps) => {
  const processedData = useMemo(() => {
    return viewsData.map(day => {
      // Find matching library data for this date
      const libDay = libraryData.find(d => d.date === day.date);
      
      // Calculate total daily views from viewsData (including untracked)
      const totalViews = Object.entries(day).reduce((sum, [key, value]) => {
        return key !== 'date' && typeof value === 'number' ? sum + value : sum;
      }, 0);

      // Calculate publications from libraryData (excluding untracked and negative values)
      const publicationDetails = libDay ? Object.entries(libDay).reduce((acc, [key, value]) => {
        if (key !== 'date' && key !== 'untracked' && typeof value === 'number' && value > 0) {
          acc[key] = value;
        }
        return acc;
      }, {} as Record<string, number>) : {};

      const publications = Object.values(publicationDetails).reduce((sum, count) => sum + count, 0);

      return {
        date: day.date,
        totalViews,
        publications,
        publicationDetails
      };
    });
  }, [viewsData, libraryData]);

  const CustomizedAxisTick = ({ x, y, payload }: any) => (
    <g transform={`translate(${x},${y})`}>
      <text 
        x={0} 
        y={0} 
        dy={16} 
        textAnchor="end" 
        fill="#666" 
        transform="rotate(-35)"
        style={{ fontSize: '12px' }}
      >
        {formatDate(payload.value)}
      </text>
    </g>
  );

  const PublicationBadge = ({ x, y, payload }: any) => {
    const dayData = processedData.find(d => d.date === payload.value);
    
    if (!dayData?.publications) {
      return (
        <g>
          <text x={x} y={y} fill="transparent">.</text>
        </g>
      );
    }

    return (
      <foreignObject 
        x={x - 15} 
        y={y - 70} 
        width={20} 
        height={20}
      >
        <HoverCard width={200} shadow="md">
          <HoverCard.Target>
            <Box
              style={{
                backgroundColor: '#808080',
                color: 'white',
                padding: '2px 6px',
                borderRadius: '4px',
                fontSize: '10px',
                textAlign: 'center',
                cursor: 'pointer'
              }}
            >
              {dayData.publications}
            </Box>
          </HoverCard.Target>
          <HoverCard.Dropdown>
            <Stack gap="xs">
              <Text size="sm" fw={500}>Published on {formatDate(payload.value)}</Text>
              {Object.entries(dayData.publicationDetails).map(([type, count]) => (
                <Text key={type} size="xs">
                  {type}: {count}
                </Text>
              ))}
            </Stack>
          </HoverCard.Dropdown>
        </HoverCard>
      </foreignObject>
    );
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const data = processedData.find(d => d.date === label);
      
      return (
        <Box style={{ 
          backgroundColor: 'white', 
          padding: '10px', 
          border: '1px solid #ccc',
          borderRadius: '4px'
        }}>
          <Text size="sm" fw={500}>{formatDate(label)}</Text>
          <Text size="sm">Total Views: {formatNumber(payload[0].value)}</Text>
          {data?.publications ? (
            <>
              <Text size="sm" mt="xs">Published: {data.publications}</Text>
              {Object.entries(data.publicationDetails).map(([type, count]) => (
                <Text key={type} size="xs" c="dimmed">
                  {type}: {count}
                </Text>
              ))}
            </>
          ) : null}
        </Box>
      );
    }
    return null;
  };

  return (
    <ResponsiveContainer width="100%" height={600}>
      <AreaChart 
        data={processedData}
        margin={{ top: 20, right: 30, left: 60, bottom: 60 }} // Increased bottom margin
      >
        <defs>
          <linearGradient id="totalViewsGradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#FF0000" stopOpacity={0.5}/>
            <stop offset="95%" stopColor="#FF0000" stopOpacity={0.05}/>
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis 
          dataKey="date"
          tick={<CustomizedAxisTick />}
          interval={0}
          ticks={processedData.map(d => d.date).filter((_, i) => i % 7 === 0)}
          padding={{ left: 20, right: 20 }}
        />
        <XAxis 
          dataKey="date"
          axisLine={false}
          tickLine={false}
          interval={0}
          tick={<PublicationBadge />}
          height={40}
          xAxisId="publications"
          padding={{ left: 20, right: 20 }}
        />
        <YAxis
          dataKey="totalViews"
          tickFormatter={formatNumber}
          width={60}
        />
        <Tooltip content={<CustomTooltip />} />
        <Area
          type="linear"
          dataKey="totalViews"
          stroke="#FF0000"
          strokeWidth={4}
          fill="url(#totalViewsGradient)"
          dot={true}
          activeDot={{ r: 8 }}
          isAnimationActive={true}
          animationDuration={1500}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default DailyViewsChart;