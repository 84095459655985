import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { Text, Group } from '@mantine/core';
import { IconChartLine } from '@tabler/icons-react';
import { formatNumber } from '../../../utils/formatter';

interface ComparisonDataPoint {
  daysLive: number;
  date: string;
  views: number;
}

interface ComparisonChartProps {
  video1Data: ComparisonDataPoint[];
  video2Data: ComparisonDataPoint[];
  video1Type: string;
  video2Type: string;
  video1Title: string;
  video2Title: string;
}

const getContentTypeColor = (contentType: string): string => {
  switch (contentType) {
    case 'Short': return '#FFA500';
    case 'Video': return '#FF0000';
    case 'Podcast': return '#9C27B0';
    case 'Live': return '#007BFF';
    case 'Premiere': return '#607D8B';
    case 'Music Video': return '#FF0000';
    case 'Course': return '#FF0000';
    default: return '#0d3fa6';
  }
};

const CustomTooltip: React.FC<any> = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}>
        <Text size="sm" fw={500}>Day {label}</Text>
        {payload.map((entry: any, index: number) => (
          <Text 
            key={index} 
            size="sm" 
            style={{ color: entry.color }}
          >
            {entry.name}: {formatNumber(entry.value)} views
          </Text>
        ))}
      </div>
    );
  }
  return null;
};

export const ComparisonChart: React.FC<ComparisonChartProps> = ({
    video1Data,
    video2Data,
    video1Type,
    video2Type,
    video1Title,
    video2Title,
  }) => {
    const truncateTitle = (title: string) => 
      title.length > 60 ? `${title.substring(0, 57)}...` : title;
    const maxDaysLive = Math.max(
      video1Data[video1Data.length - 1]?.daysLive || 0,
      video2Data[video2Data.length - 1]?.daysLive || 0
    );
  
    // Prepare data for chart ensuring all days are represented
    const chartData = Array.from({ length: maxDaysLive + 1 }, (_, i) => {
      const day1Data = video1Data.find(d => d.daysLive === i);
      const day2Data = video2Data.find(d => d.daysLive === i);
      
      return {
        daysLive: i,
        [video1Title]: day1Data?.views ?? null,
        [video2Title]: day2Data?.views ?? null,
      };
    });

  return (
    <>
      <Group align="center" mb="xs">
        <IconChartLine size={20} />
        <Text size="md" fw={400}>Views Comparison Over Time</Text>
      </Group>
      
      <Text size="sm" fs="italic" mb="md" fw={400}>
        Comparing:{' '}
        <Text span c={getContentTypeColor(video1Type)}>
          {truncateTitle(video1Title)}
        </Text>
        {' & '}
        <Text span c="#0d3fa6">
          {truncateTitle(video2Title)}
        </Text>
      </Text>
      
      <ResponsiveContainer width="100%" height={600}>
        <LineChart
          data={chartData}
          margin={{ top: 10, right: 30, left: 60, bottom: 20 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="daysLive"
            tickFormatter={(value) => `Day ${value}`}
          />
          <YAxis
            tickFormatter={(value) => formatNumber(value)}
          />
          <Tooltip content={<CustomTooltip />} />
          <Line
            type="monotone"
            dataKey={video1Title}
            stroke={getContentTypeColor(video1Type)}
            strokeWidth={3}
            dot={{ r: 4 }}
            activeDot={{ r: 8 }}
            animationDuration={1500}
            name={video1Title}
            connectNulls
          />
          <Line
            type="monotone"
            dataKey={video2Title}
            stroke="#0d3fa6"
            strokeWidth={3}
            dot={{ r: 4 }}
            activeDot={{ r: 8 }}
            animationDuration={1500}
            name={video2Title}
            connectNulls
          />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};