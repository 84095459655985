import React, { useState, useEffect } from 'react';
import { Combobox, InputBase, useCombobox, Box } from '@mantine/core';
import { IconBrandYoutube, IconDeviceMobile, IconBroadcast, IconSchool, IconVideo, IconDeviceTv, IconSum, IconDeviceMobileOff, IconMusic } from '@tabler/icons-react';
import { getContentTypes } from '../../../utils/api';

interface ContentTypePickerProps {
  channelId: string | null;
  value: string;
  onChange: (value: string) => void;
}

const typeConfig: Record<string, { icon: React.ReactNode; color: string }> = {
  Video: { icon: <IconBrandYoutube size={18} />, color: '#FF0000' },
  Short: { icon: <IconDeviceMobile size={18} />, color: '#FFA500' },
  Podcast: { icon: <IconBroadcast size={18} />, color: '#9C27B0' },
  Course: { icon: <IconSchool size={18} />, color: '#FF0000' },
  Live: { icon: <IconVideo size={18} />, color: '#007BFF' },
  Premiere: { icon: <IconDeviceTv size={18} />, color: '#607D8B' },
  'Music Video': { icon: <IconMusic size={18} />, color: '#FF0000' },
  All: { icon: <IconSum size={18} />, color: '#FF0000' },
  'All (excluding Shorts)': { icon: <IconDeviceMobileOff size={18} />, color: '#FF0000' },
};

const IconWithBackground: React.FC<{ icon: React.ReactNode; color: string }> = ({ icon, color }) => (
  <Box
    style={{
      backgroundColor: color,
      borderRadius: '50%',
      width: 24,
      height: 24,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    {React.cloneElement(icon as React.ReactElement, { size: 14, color: 'white' })}
  </Box>
);

export function ContentTypePicker({ channelId, value, onChange }: ContentTypePickerProps) {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
  });

  const [contentTypes, setContentTypes] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (channelId) {
      setLoading(true);
      getContentTypes(channelId)
        .then(response => {
          const { contentTypes, hasShorts } = response.data;
          setContentTypes([
            'All',
            ...(hasShorts ? ['All (excluding Shorts)'] : []),
            ...contentTypes
          ]);
          setLoading(false);
        })
        .catch(error => {
          console.error('Failed to fetch content types:', error);
          setLoading(false);
        });
    } else {
      setContentTypes([]);
    }
  }, [channelId]);

  const options = contentTypes.map((type) => (
    <Combobox.Option value={type} key={type}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <IconWithBackground icon={typeConfig[type]?.icon} color={typeConfig[type]?.color} />
        <span style={{ marginLeft: '10px' }}>{type === 'All' ? 'All Content' : type}</span>
      </div>
    </Combobox.Option>
  ));

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={(val) => {
        onChange(val);
        combobox.closeDropdown();
      }}
    >
      <Combobox.Target>
        <InputBase
          component="button"
          pointer
          rightSection={<Combobox.Chevron />}
          onClick={() => combobox.toggleDropdown()}
          rightSectionPointerEvents="none"
          style={{ width: '100%', maxWidth: '300px' }}
        >
          {value ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IconWithBackground icon={typeConfig[value]?.icon} color={typeConfig[value]?.color} />
              <span style={{ marginLeft: '10px' }}>{value === 'All' ? 'All Content' : value}</span>
            </div>
          ) : (
            <span>Select content type</span>
          )}
        </InputBase>
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options>
          {loading ? (
            <Combobox.Empty>Loading content types...</Combobox.Empty>
          ) : options.length === 0 ? (
            <Combobox.Empty>No content types found</Combobox.Empty>
          ) : (
            options
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}

export default ContentTypePicker;