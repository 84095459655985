import React, { useState, useEffect } from 'react';
import Plot from 'react-plotly.js';
import { interpolateRdBu } from 'd3-scale-chromatic';
import { formatNumber } from '../../../utils/formatter';

interface TreemapData {
  name: string;
  children?: TreemapData[];
  value?: number;
}

interface TypesTreemapProps {
  data: TreemapData;
  channelName: string;
  channelViews: number;
}

const safeMax = (numbers: (number | undefined)[]): number => {
  const validNumbers = numbers.filter((n): n is number => n !== undefined);
  return validNumbers.length > 0 ? Math.max(...validNumbers) : 0;
};

export const TypesTreemap: React.FC<TypesTreemapProps> = ({ data, channelName, channelViews }) => {
  const [plotData, setPlotData] = useState<any>(null);

  useEffect(() => {
    if (!data) return;

    const labels: string[] = [];
    const parents: string[] = [];
    const values: number[] = [];
    const ids: string[] = [];
    const colors: string[] = [];
    const hoverTexts: string[] = [];

    const processNode = (node: TreemapData, parent: string = '', prefix: string = '', depth: number = 0) => {
      const currentId = prefix ? `${prefix}_${node.name}` : node.name;
      ids.push(currentId);
      labels.push(node.name);
      parents.push(parent);
      values.push(node.value || 0);

      // Color logic
      if (depth === 0) {
        colors.push('rgba(255, 255, 255, 0.1)');
        hoverTexts.push(`Channel: ${channelName}<br>Total Views: ${formatNumber(channelViews || 0)}<br>Total views and the sum of the below will not match: the categories below show all *tracked* views`);
      } else if (depth === 1) {
        colors.push('rgba(200, 200, 200, 0.3)');
        const typeViews = node.children?.reduce((sum, child) => sum + (child.value || 0), 0) || 0;
        const percentage = ((typeViews / (data.value || 1)) * 100).toFixed(2);
        hoverTexts.push(`Type: ${node.name}<br>Tracked Views for Content Type: ${formatNumber(typeViews)}`);
      } else {
        const maxValue = safeMax(data.children?.flatMap(type => 
          type.children?.map(video => video.value)
        ).flat() ?? []);
        
        const normalizedValue = maxValue > 0 ? (node.value || 0) / maxValue : 0;
        colors.push(interpolateRdBu(normalizedValue));
        const parentType = data.children?.find(type => type.name === parent);
        const percentage = ((node.value || 0) / (parentType?.value || 1) * 100).toFixed(2);
        hoverTexts.push(`${node.name}<br>Views: ${formatNumber(node.value || 0)}`);
      }

      if (node.children) {
        node.children.forEach((child, index) => 
          processNode(child, currentId, `${currentId}_${index}`, depth + 1)
        );
      }
    };

    processNode({ ...data, name: channelName });

    setPlotData([{
      type: 'treemap',
      ids: ids,
      labels: labels,
      parents: parents,
      values: values,
      marker: { colors: colors },
      text: hoverTexts,
      hoverinfo: 'text',
      textinfo: "label",
    }]);
  }, [data, channelName]);

  const layout = {
    autosize: true,
    height: 600,
    margin: { l: 0, r: 0, b: 0, t: 0 },
  };

  return (
    <Plot
      data={plotData}
      layout={layout}
      style={{ width: '100%', height: '600px' }}
      config={{ responsive: true }}
    />
  );
};