import React from 'react';
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
} from 'recharts';
import { Text, Group, Box, Badge, Tooltip as MantineTooltip } from '@mantine/core';
import { IconAlpha } from '@tabler/icons-react';
import { formatNumber, formatDate } from '../../../utils/formatter';
import { AlphaAnalysisResponse } from '../../../utils/api';
interface AlphaVisualizationProps {
  data: AlphaAnalysisResponse;
}

const getContentTypeColor = (contentType: string): string => {
    switch (contentType) {
      case 'Short': return '#FFA500';
      case 'Video': return '#FF0000';
      case 'Podcast': return '#9C27B0';
      case 'Live': return '#007BFF';
      case 'Premiere': return '#607D8B';
      case 'Music Video': return '#FF0000';
      case 'Course': return '#FF0000';
      default: return '#0d3fa6';
    }
  };  

const AlphaVisualization: React.FC<AlphaVisualizationProps> = ({ data }) => {
  const { metadata, alpha, analysisData, baseline } = data;
  const contentTypeColor = getContentTypeColor(metadata.contentType);

  // Calculate cumulative excess return
  const chartData = analysisData.map((point, index) => ({
    ...point,
    cumulativeExcessReturn: analysisData
      .slice(0, index + 1)
      .reduce((sum, p) => sum + p.excessReturn, 0)
  }));

  const CustomTooltip: React.FC<any> = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <Box className="custom-tooltip" p="md" bg="white" style={{ border: '1px solid #ccc' }}>
          <Text size="sm" fw={500}>Day {data.daysLive}</Text>
          <Text size="xs" c="dimmed">{formatDate(data.actualDate)}</Text>
          <Text size="sm">Actual Views: {formatNumber(data.actualViews)}</Text>
          <Text size="sm">Expected Views: {formatNumber(data.expectedViews)}</Text>
          <Text size="sm">Daily Excess Return: {(data.excessReturn * 100).toFixed(1)}%</Text>
          <Text size="sm">Cumulative α: {(data.cumulativeExcessReturn * 100).toFixed(1)}%</Text>
        </Box>
      );
    }
    return null;
  };

  const getAlphaColor = (value: number): string => {
    if (value > 0.1) return 'green';  // Strongly outperforming
    if (value > 0) return 'teal';     // Outperforming
    if (value > -0.1) return 'orange'; // Slightly underperforming
    return 'red';                      // Strongly underperforming
  };

  return (
    <Box>
      <Group align="center" mb="md" gap="apart">
        <Group>
          <IconAlpha size={20} />
          <Text size="md" fw={500}>
            Alpha Analysis for "{metadata.title.length > 50 ? metadata.title.substring(0, 47) + '...' : metadata.title}"
          </Text>
        </Group>
        <Group>
          <MantineTooltip 
            label="Alpha (α) measures excess return vs. baseline - positive values indicate outperformance"
            multiline
            w={200}
          >
            <Badge color={getAlphaColor(alpha)}>
              α = {(alpha * 100).toFixed(1)}%
            </Badge>
          </MantineTooltip>
          <MantineTooltip 
            label="Number of videos used to establish the baseline performance"
            multiline
            w={200}
          >
            <Badge color="blue">Sample Size: {baseline.sampleSize}</Badge>
          </MantineTooltip>
          <MantineTooltip 
            label="Confidence based on sample size and data consistency"
            multiline
            w={200}
          >
            <Badge color={baseline.confidence >= 0.8 ? "green" : "yellow"}>
              {baseline.confidence >= 0.8 ? "High" : "Medium"} Confidence
            </Badge>
          </MantineTooltip>
        </Group>
      </Group>

      <ResponsiveContainer width="100%" height={600}>
        <ComposedChart
          data={chartData}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="daysLive"
            tickFormatter={(value) => `Day ${value}`}
          />
          <YAxis
            yAxisId="return"
            tickFormatter={(value) => `${(value * 100).toFixed(0)}%`}
          />
          <YAxis
            yAxisId="views"
            orientation="right"
            tickFormatter={(value) => formatNumber(value)}
          />
          <Tooltip content={<CustomTooltip />} />
          
          {/* Reference line for zero excess return */}
          <ReferenceLine 
            yAxisId="return"
            y={0} 
            stroke="#666"
            strokeDasharray="3 3"
          />

          {/* Daily excess return bars */}
          <Bar
            dataKey="excessReturn"
            yAxisId="return"
            fill={contentTypeColor}
            fillOpacity={0.5}
            isAnimationActive={true}
            animationDuration={2000}
          />

          {/* Cumulative alpha line */}
          <Line
            type="monotone"
            dataKey="cumulativeExcessReturn"
            yAxisId="return"
            stroke="#808080"
            strokeWidth={2}
            dot={false}
            isAnimationActive={true}
            animationDuration={2000}
          />

          {/* Actual vs Expected views */}
          <Line
            type="monotone"
            dataKey="actualViews"
            yAxisId="views"
            stroke={contentTypeColor}
            strokeWidth={3}
            dot={true}
            isAnimationActive={true}
            animationDuration={2000}
          />
          <Line
            type="monotone"
            dataKey="expectedViews"
            yAxisId="views"
            stroke="#808080"
            strokeDasharray="5 5"
            strokeWidth={2}
            dot={false}
            isAnimationActive={true}
            animationDuration={2000}
          />
        </ComposedChart>
      </ResponsiveContainer>

    </Box>
  );
};

export default AlphaVisualization;