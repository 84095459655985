import React from 'react';
import {
  ComposedChart,
  Line,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { Text, Group, Box, Badge, Tooltip as MantineTooltip } from '@mantine/core';
import { IconChartLine } from '@tabler/icons-react';
import { formatNumber, formatDate } from '../../../utils/formatter';
import { WeibullAnalysisResponse } from '../../../utils/api';

interface WeibullVisualizationProps {
  data: WeibullAnalysisResponse;
}

const getContentTypeColor = (contentType: string): string => {
    switch (contentType) {
      case 'Short': return '#FFA500';
      case 'Video': return '#FF0000';
      case 'Podcast': return '#9C27B0';
      case 'Live': return '#007BFF';
      case 'Premiere': return '#607D8B';
      case 'Music Video': return '#FF0000';
      case 'Course': return '#FF0000';
      default: return '#0d3fa6';
    }
  };  

const WeibullVisualization: React.FC<WeibullVisualizationProps> = ({ data }) => {
  const { metadata, weibullParams, analysisData } = data;
  const contentTypeColor = getContentTypeColor(metadata.contentType);

  const CustomTooltip: React.FC<any> = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <Box className="custom-tooltip" p="md" bg="white" style={{ border: '1px solid #ccc' }}>
          <Text size="sm" fw={500}>Day {data.daysLive}</Text>
          <Text size="xs" c="dimmed">{formatDate(data.actualDate)}</Text>
          <Text size="sm">Actual Views: {formatNumber(data.views)}</Text>
          <Text size="sm">Predicted: {formatNumber(data.weibullBound.predicted)}</Text>
          <Text size="sm">Bounds: {formatNumber(data.weibullBound.lower)} - {formatNumber(data.weibullBound.upper)}</Text>
          <Text size="sm">Hazard Rate: {(data.hazardRate * 100).toFixed(4)}%</Text>
        </Box>
      );
    }
    return null;
  };

  return (
    <Box>
      <Group align="center" mb="md" gap="apart">
        <Group>
          <IconChartLine size={20} />
          <Text size="md" fw={500}>
            Weibull Analysis for "{metadata.title.length > 50 ? metadata.title.substring(0, 47) + '...' : metadata.title}"
          </Text>
        </Group>
        <Group>
          <MantineTooltip 
            label={weibullParams.k < 1 ? 
              "Shows early screening behavior - popularity (defined as more views) declines after release" :
              "Shows sustained engagement pattern - more viewers over time"
            }
            multiline
            w={200}
          >
            <Badge color={weibullParams.k < 1 ? "blue" : "orange"}>
              {weibullParams.k < 1 ? 'Negative Aging' : 'Positive Aging'}
            </Badge>
          </MantineTooltip>
          <MantineTooltip 
            label="λ (lambda) represents the scale of views - higher values indicate more views overall"
            multiline
            w={200}
          >
            <Text size="sm">λ = {weibullParams.lambda.toFixed(2)}</Text>
          </MantineTooltip>
          <MantineTooltip 
            label="k represents the shape of engagement - values < 1 indicate early screening, values > 1 indicate sustained viewing"
            multiline
            w={200}
          >
            <Text size="sm">k = {weibullParams.k.toFixed(2)}</Text>
          </MantineTooltip>
        </Group>
      </Group>

      <ResponsiveContainer width="100%" height={600}>
        <ComposedChart
          data={analysisData}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="daysLive"
            tickFormatter={(value) => `Day ${value}`}
          />
          <YAxis
            tickFormatter={(value) => formatNumber(value)}
          />
          <Tooltip content={<CustomTooltip />} />

          <defs>
            <linearGradient id="boundsGradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#808080" stopOpacity={0.8}/>
              <stop offset="95%" stopColor="#808080" stopOpacity={0.2}/>
            </linearGradient>
          </defs>

          {/* Bounds area */}
          <Area
            type="monotone"
            dataKey={(d) => [d.weibullBound.lower, d.weibullBound.upper]}
            fill="url(#boundsGradient)"
            stroke="#808080"
            strokeWidth={0.1}
            fillOpacity={0.8}
            isAnimationActive={true}
            animationDuration={2000}
          />

          {/* Predicted line */}
          <Line
            type="monotone"
            dataKey="weibullBound.predicted"
            stroke="#808080"
            strokeDasharray="5 5"
            strokeWidth={2}
            dot={false}
            isAnimationActive={true}
            animationDuration={2000}
          />

          {/* Actual views line */}
          <Line
            type="monotone"
            dataKey="views"
            stroke={contentTypeColor}
            strokeWidth={3}
            dot={false}
            activeDot={{ r: 8 }}
            isAnimationActive={true}
            animationDuration={2000}
          />
        </ComposedChart>
      </ResponsiveContainer>

    </Box>
  );
};

export default WeibullVisualization;