import React, { useMemo } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceArea } from 'recharts';
import { Text, Group } from '@mantine/core';
import { formatNumber, formatDate } from '../../../utils/formatter';
import { IconChartBarPopular } from '@tabler/icons-react';

interface DailyGraphProps {
  data: PerformanceDataItem[];
  metric: 'views' | 'likes' | 'comments';
  publishDate: string;
  firstTrackedDate: string;
  lastUpdatedDate: string;
  gaps: Gap[];
  isDay2Scenario: boolean;
  contentType: string;
  videoTitle: string;
}

interface PerformanceDataItem {
  date: string;
  daysLive: number;
  views: number | null;
  likes: number | null;
  comments: number | null;
  totalViews: number;
  totalLikes: number;
  totalComments: number;
}

interface Gap {
  start: string;
  end: string;
  type: 'pre-tracking' | 'ingest-error' | 'post-tracking';
}

const CustomizedAxisTick: React.FC<any> = ({ x, y, payload }) => (
  <g transform={`translate(${x},${y})`}>
    <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)" fontSize={12}>
      {formatDate(payload.value)}
    </text>
  </g>
);

const getContentTypeColor = (contentType: string): string => {
  switch (contentType) {
    case 'Short': return '#FFA500';
    case 'Video': return '#FF0000';
    case 'Podcast': return '#9C27B0';
    case 'Live': return '#007BFF';
    case 'Premiere': return '#607D8B';
    case 'Music Video': return '#FF0000';
    case 'Course': return '#FF0000';
    default: return '#0d3fa6';
  }
};

const DailyGraph: React.FC<DailyGraphProps> = ({
  data,
  metric,
  publishDate,
  firstTrackedDate,
  lastUpdatedDate,
  gaps,
  isDay2Scenario,
  contentType,
  videoTitle,
}) => {
  const maxBarValue = useMemo(() => {
    return Math.max(...data.map(item => item[metric] || 0));
  }, [data, metric]);

  const referenceAreaHeight = maxBarValue / 3;
  const contentTypeColor = getContentTypeColor(contentType);

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}>
          <Text size="sm" fw={500}>Day {data.daysLive}</Text>
          <Text size="sm" fs="italic" c="dimmed">{formatDate(data.date)}</Text>
          <Text size="sm" fw={500}>Daily change: {data[metric] !== null ? formatNumber(data[metric]) : 'N/A'}</Text>
          <Text size="sm">Total: {formatNumber(data[`total${metric.charAt(0).toUpperCase() + metric.slice(1)}`])}</Text>
        </div>
      );
    }
    return null;
  };

  const renderReferenceAreas = () => {
    return gaps.map((gap, index) => (
      <ReferenceArea
        key={`gap-${index}`}
        x1={gap.start}
        x2={gap.end}
        y1={0}
        y2={referenceAreaHeight}
        fill={gap.type === 'pre-tracking' ? 'gray' : gap.type === 'ingest-error' ? 'orange' : 'red'}
        fillOpacity={0.3}
      />
    ));
  };

  return (
    <>
      <Group align="center" mb="sm">
        <IconChartBarPopular size={20} />
        <Text size="md" fw={400} fs="italic">
          Daily Change in {metric.charAt(0).toUpperCase() + metric.slice(1)} for "{videoTitle.length > 50 ? videoTitle.substring(0, 47) + '[...]' : videoTitle}"
        </Text>
      </Group>
      <ResponsiveContainer width="100%" height={600}>
        <BarChart data={data}
          margin={{ top: 10, right: 30, left: 0, bottom: 60 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            tickFormatter={(value) => formatDate(value)}
            ticks={[publishDate, firstTrackedDate, lastUpdatedDate]}
            tick={<CustomizedAxisTick />}
          />
          <YAxis tickFormatter={(value) => formatNumber(value)} />
          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey={metric} fill={contentTypeColor} animationDuration={1000} />
          {renderReferenceAreas()}
        </BarChart>
      </ResponsiveContainer>
    </>
  );
  };
  
  export default DailyGraph;