import React from 'react';
import { Card, Group, Text, Stack, Badge, Divider } from '@mantine/core';
import { formatNumber, formatRate, formatDate } from '../../../utils/formatter';
import { differenceInDays, parseISO } from 'date-fns';
import { VideoClassification } from '../../../utils/api';
import { ClassificationBadge } from './VideoClassificationBadge';

interface PerformanceCardProps {
  performance: {
    rankAll: number;
    rankType: number;
    totalViews: number;
    totalComments: number;
    totalLikes: number;
    viewsChange: number | null;
    commentsChange: number | null;
    likesChange: number | null;
    conversationRate: number;
    likeRate: number;
  };
  contentType: string;
  lastUpdated: string;
  isOldWithLowViews: boolean;
  classification?: VideoClassification | null; 
}

const getContentTypeColor = (type: string): string => {
  switch (type) {
    case 'Video': return '#FF0000';
    case 'Short': return '#FFA500';
    case 'Podcast': return '#9C27B0';
    case 'Course': return '#FF0000';
    case 'Live': return '#007BFF';
    case 'Premiere': return '#607D8B';
    case 'Music Video': return '#FF0000';
    default: return '#FF0000';
  }
};

const getRankColor = (rank: number, type: string): string => {
  const baseColor = getContentTypeColor(type);
  const lightColor = '#FFFFFF';
  const index = Math.min(rank - 1, 499); // Cap at 50
  const total = 500;
  
  const baseRGB = hexToRgb(baseColor);
  const lightRGB = hexToRgb(lightColor);
  
  const r = Math.round(baseRGB.r + (lightRGB.r - baseRGB.r) * (index / (total - 1)));
  const g = Math.round(baseRGB.g + (lightRGB.g - baseRGB.g) * (index / (total - 1)));
  const b = Math.round(baseRGB.b + (lightRGB.b - baseRGB.b) * (index / (total - 1)));
  
  return `rgb(${r}, ${g}, ${b})`;
};

const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : { r: 0, g: 0, b: 0 };
};

const ChangeText: React.FC<{ change: number | null }> = ({ change }) => {
  if (change === null) return null;
  return (
    <Text size="xs" color={change >= 0 ? 'green' : 'red'}>
      {change > 0 ? '+' : ''}{formatNumber(change)}
    </Text>
  );
};


export function PerformanceCard({ performance, contentType, lastUpdated, isOldWithLowViews, classification }: PerformanceCardProps) {
  const isPerformanceUpdated = performance.viewsChange !== null && 
                               performance.commentsChange !== null && 
                               performance.likesChange !== null;

  const daysSinceLastUpdate = differenceInDays(new Date(), parseISO(lastUpdated));
  const shouldShowUpdateMessage = (isOldWithLowViews || daysSinceLastUpdate > 2) && !isPerformanceUpdated;

  return (
    <Card 
      withBorder
      p="md"
      radius="md"
      style={{ 
        transition: 'box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out',
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.1)';
        e.currentTarget.style.transform = 'translateY(-5px)';
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.boxShadow = '';
        e.currentTarget.style.transform = '';
      }}
    >
      <Stack gap="xs">
        <Group gap="xs">
          {contentType !== 'All' && contentType !== 'All (excluding Shorts)' && ( 
            <>
              <Text size="sm">Rank: </Text>
              <Badge 
                size="md"
                radius="sm"
                style={{
                  backgroundColor: getRankColor(performance.rankType, contentType),
                  color: 'white',
                }}
              >
                #{performance.rankType}
              </Badge>
            </>
          )}
        </Group>
        <Text size="xs" fs="italic" c="dimmed">All Tracked Videos Rank: #{performance.rankAll}</Text>
        <Divider my="xs" />
        {classification && (
            <ClassificationBadge classification={classification} />
          )}
        <Group gap="xs">
          <Text size="sm">Views: {formatNumber(performance.totalViews)}</Text>
          <ChangeText change={performance.viewsChange} />
        </Group>
        <Group gap="xs">
          <Text size="sm">Comments: {formatNumber(performance.totalComments)}</Text>
          <ChangeText change={performance.commentsChange} />
        </Group>
        <Group gap="xs">
          <Text size="sm">Likes: {formatNumber(performance.totalLikes)}</Text>
          <ChangeText change={performance.likesChange} />
        </Group>
        <Divider my="xs" />
        <Text size="sm">Conversation Rate: {formatRate(performance.conversationRate)}</Text>
        <Text size="sm">Like Rate: {formatRate(performance.likeRate)}</Text>
        <Divider my="xs" />
        <Text size="xs" c="dimmed" fs="italic">Last Updated: {formatDate(lastUpdated)}</Text>
        {shouldShowUpdateMessage && (
          <>
            <Divider my="xs" />
            <Text size="xs" fs="italic" c="dimmed">
              {isOldWithLowViews 
                ? "Performance no longer updated as this video no longer meets the criteria for daily tracking."
                : "Performance not updated recently. The video may have been set to private or deleted."}
            </Text>
          </>
        )}
      </Stack>
    </Card>
  );
}
