import React from 'react';
import BaseChart from './ChannelBaseChart';

interface ChartDataPoint {
  date: string;
  preTracking?: number | null;
  tracked?: number | null;
  totalViews?: number;
}

interface ViewsAllChartProps {
  data: ChartDataPoint[];
  startDate: string;
  firstTrackedDate: string | null;
  lastTrackedDate: string;
}

const ViewsAllChart: React.FC<ViewsAllChartProps> = ({
  data,
  startDate,
  firstTrackedDate,
  lastTrackedDate
}) => {
  const areas = [
    { dataKey: 'preTracking', stroke: '#808080', fill: '#808080' },
    { dataKey: 'tracked', stroke: '#FF0000', fill: '#FF0000' }
  ];

  return (
    <BaseChart
      data={data}
      xDataKey="date"
      yDataKey="totalViews"
      areas={areas}
      startDate={startDate}
      firstTrackedDate={firstTrackedDate}
      lastTrackedDate={lastTrackedDate}
    />
  );
};

export default ViewsAllChart;