import React, { useState, useEffect, useMemo } from 'react';
import { Paper, Group, Text, Stack, Select } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import { IconChevronRight, IconExternalLink } from '@tabler/icons-react';
import { SkeletonLoader } from '../../../core/SkeletonLoader';
import { ChannelPicker } from '../../components/ChannelPicker';
import { getChannelTimelineData } from '../../../../utils/api';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, TooltipProps } from 'recharts';
import { formatNumber, formatDate } from '../../../../utils/formatter';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import * as d3 from 'd3';

interface TimelineDataPoint {
  date: string;
  [key: string]: string | number;
}

interface CategorySummary {
  category: string;
  totalVolume: number;
  selected: boolean;
  color: string;
}

const navItems = [
  { title: 'Channels', href: '/channels/channels/channel' },
  { title: 'Channels', href: '/channels/channels/channel' },
  { title: 'Channel', href: '/channels/channels/channel' },
  { title: 'Top Five', href: '/channels/channels/top-five' },
  { title: 'Compare Channels', href: '/channels/channels/compare' },
  { title: 'Timeline', href: '/channels/channels/timeline' },
  { title: 'Classification', href: '/channels/channels/classification' },
  { title: 'Notes', href: 'https://brick-river-8a5.notion.site/Channels-Timeline-1216777fc95580ae9b00fe8a4e921fa7', isExternal: true },
];

const timelineTypes = [
  { value: 'contentTypes', label: 'Content Types' },
  { value: 'youtubeTopics', label: 'YouTube Topics' },
  { value: 'modelTopics', label: 'Model Topics' },
];

const contentTypeColors: { [key: string]: string } = {
  'Short': '#FFA500',
  'Video': '#FF0000',
  'Podcast': '#9C27B0',
  'Live': '#007BFF',
  'Premiere': '#607D8B',
  'Music Video': '#FF0000',
  'Course': '#FF0000',
  'Other': '#808080'
};

// Generate 50 colors using d3.interpolateTurbo
const generateColorPalette = (count: number): string[] => {
  return Array.from({ length: count }, (_, i) => 
    d3.rgb(d3.interpolateTurbo(i / (count - 1))).formatHex()
  );
};

const colorPalette = generateColorPalette(50);


export function ChannelTimeline() {
  const [selectedChannel, setSelectedChannel] = useState<string | null>(null);
  const [selectedTimelineType, setSelectedTimelineType] = useState<string>('contentTypes');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [timelineData, setTimelineData] = useState<TimelineDataPoint[]>([]);
  const [categorySummary, setCategorySummary] = useState<CategorySummary[]>([]);
  const location = useLocation();

  useEffect(() => {
    if (selectedChannel && selectedTimelineType) {
      fetchData();
    }
  }, [selectedChannel, selectedTimelineType]);

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await getChannelTimelineData(selectedChannel!, selectedTimelineType);
      setTimelineData(response.data);
      
      // Calculate category summaries
      const summaries: { [key: string]: number } = {};
      response.data.forEach((item: TimelineDataPoint) => {
        Object.entries(item).forEach(([key, value]) => {
          if (key !== 'date') {
            summaries[key] = (summaries[key] || 0) + (Number(value) || 0);
          }
        });
      });
      
      const summaryArray = Object.entries(summaries)
        .map(([category, totalVolume]) => ({ 
          category, 
          totalVolume, 
          selected: false,
          color: getColor(category)
        }))
        .sort((a, b) => b.totalVolume - a.totalVolume);
      
      // Initially select top 5 categories (or all if less than 5)
      const initialSelectedCount = Math.min(5, summaryArray.length);
      summaryArray.forEach((item, index) => {
        item.selected = index < initialSelectedCount;
      });
      
      setCategorySummary(summaryArray);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Failed to fetch data');
    } finally {
      setLoading(false);
    }
  };

  const getColor = useMemo(() => {
    const categoryColorMap = new Map<string, string>();

    return (category: string): string => {
      switch (selectedTimelineType) {
        case 'contentTypes':
          return contentTypeColors[category] || contentTypeColors['Other'];
        case 'modelTopics':
          if (category === 'Not Modelled') return '#808080';
          if (!categoryColorMap.has(category)) {
            categoryColorMap.set(category, colorPalette[categoryColorMap.size % colorPalette.length]);
          }
          return categoryColorMap.get(category)!;
        case 'youtubeTopics':
          if (category === 'No Topics Assigned By YouTube') return '#808080';
          if (!categoryColorMap.has(category)) {
            categoryColorMap.set(category, colorPalette[categoryColorMap.size % colorPalette.length]);
          }
          return categoryColorMap.get(category)!;
        default:
          return '#808080';
      }
    };
  }, [selectedTimelineType]);
  
  const CustomTooltip = ({ active, payload, label }: TooltipProps<number, string>) => {
    if (active && payload && payload.length) {
      return (
        <div style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}>
          <Text size="sm" fw={500} fs="italic">{formatDate(label)}</Text>
          {payload.map((entry, index) => (
            entry.value && entry.value > 0 ? (
              <Text key={`item-${index}`} size="sm" style={{ color: entry.color }}>
                {`${entry.name}: ${formatNumber(entry.value as number)}`}
              </Text>
            ) : null
          ))}
        </div>
      );
    }
    return null;
  };

  const renderChart = () => {
    const selectedCategories = categorySummary.filter(cat => cat.selected).map(cat => cat.category);
    return (
      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={timelineData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" tickFormatter={formatDate} />
          <YAxis label={{ value: 'Videos Published', angle: -90, position: 'insideLeft' }} />
          <Tooltip content={<CustomTooltip />} />
          {selectedCategories.map((category) => {
            const categoryData = categorySummary.find(cat => cat.category === category);
            return (
              <Bar 
                key={category} 
                dataKey={category} 
                stackId="a" 
                fill={categoryData?.color || '#808080'} 
              />
            );
          })}
        </BarChart>
      </ResponsiveContainer>
    );
  };

  const columnDefs: ColDef[] = [
    {
      headerName: 'Select',
      field: 'selected',
      width: 100,
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <input
            type="checkbox"
            checked={params.value}
            onChange={() => {
              const newData = [...categorySummary];
              const index = newData.findIndex(item => item.category === params.data.category);
              newData[index].selected = !newData[index].selected;
              setCategorySummary(newData);
            }}
          />
        );
      }
    },
    { 
      headerName: 'Key',
      field: 'color',
      width: 200,
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <div
            style={{
              width: '100%',
              height: '100%',
              backgroundColor: params.value,
            }}
          />
        );
      }
    },
    { 
      headerName: selectedTimelineType === 'contentTypes' ? 'Type' : 'Topic', 
      field: 'category', 
      flex: 1 
    },
    { 
      headerName: 'Volume', 
      field: 'totalVolume', 
      flex: 1,
      valueFormatter: (params) => formatNumber(params.value),
      type: 'numericColumn'  
    }
  ];


  return (
    <Stack gap="xs">
      <Group gap={5}>
        {navItems.map((item, index) => (
          <React.Fragment key={item.title}>
            {index > 0 && index < 3 && <IconChevronRight size={14} color='#868e96'/>}
            {index > 2 && <Text size="sm" color="dimmed">|</Text>}
            {item.isExternal ? (
              <Text
                component="a"
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
                size="sm"
                c="#0982eb"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {item.title}
                <IconExternalLink size={14} style={{ marginLeft: 5 }} />
              </Text>
            ) : (
              <Text
                component={Link}
                to={item.href}
                size="sm"
                fw={index >= 2 && location.pathname === item.href ? 500 : 'normal'}
                td={index >= 2 && location.pathname === item.href ? 'underline' : 'none'}
                c={index < 2 ? 'dimmed' : '#0982eb'}
              >
                {item.title}
              </Text>
            )}
      </React.Fragment>
        ))}
      </Group>
        <Stack gap="md">
          <Group>
            <ChannelPicker value={selectedChannel} onChange={setSelectedChannel} />
            <Select
              data={timelineTypes}
              value={selectedTimelineType}
              onChange={(value) => setSelectedTimelineType(value || 'contentTypes')}
            />
          </Group>
          <Paper
                p="md" 
                radius="md" 
                withBorder
                style={{ 
                      transition: 'box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out',
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.1)';
                      e.currentTarget.style.transform = 'translateY(-5px)';
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.boxShadow = '';
                      e.currentTarget.style.transform = '';
                    }}
                  >
          {loading ? (
            <SkeletonLoader count={1} height={400} radius="sm" />
          ) : error ? (
            <Text color="red">{error}</Text>
          ) : timelineData.length > 0 ? (
            <>
              {renderChart()}
              <div className="ag-theme-quartz" style={{ height: '100%', width: '100%' }}>
                <AgGridReact
                  rowData={categorySummary}
                  columnDefs={columnDefs}
                  domLayout='autoHeight'
                />
              </div>
            </>
          ) : (
            <Text>Select a channel and timeline type to get started.</Text>
          )}
          </Paper>
        </Stack>
    </Stack>
  );
}