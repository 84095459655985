import React from 'react';
import { Combobox, InputBase, Group, useCombobox, Text } from '@mantine/core';
import { IconEye, IconThumbUp, IconMessage, IconMessages, IconFileLike } from '@tabler/icons-react';

export type MetricKey = 'views' | 'likes' | 'comments' | 'conversationRate' | 'likeRate';

const metricOptions: { value: MetricKey; label: string; icon: React.ReactNode }[] = [
  { value: 'views', label: 'Views', icon: <IconEye size={16} /> },
  { value: 'likes', label: 'Likes', icon: <IconThumbUp size={16} /> },
  { value: 'comments', label: 'Comments', icon: <IconMessage size={16} /> },
  { value: 'conversationRate', label: 'Conversation Rate', icon: <IconMessages size={16} /> },
  { value: 'likeRate', label: 'Like Rate', icon: <IconFileLike size={16} /> },
];

interface ScatterMetricPickerProps {
  xAxisMetric: MetricKey;
  yAxisMetric: MetricKey;
  onChange: (axis: 'x' | 'y', value: MetricKey) => void;
}

export function ScatterMetricPicker({ xAxisMetric, yAxisMetric, onChange }: ScatterMetricPickerProps) {
  const xCombobox = useCombobox({
    onDropdownClose: () => xCombobox.resetSelectedOption(),
    onDropdownOpen: () => xCombobox.updateSelectedOptionIndex('active'),
  });

  const yCombobox = useCombobox({
    onDropdownClose: () => yCombobox.resetSelectedOption(),
    onDropdownOpen: () => yCombobox.updateSelectedOptionIndex('active'),
  });

  const getSelectData = (axis: 'x' | 'y') => {
    const excludedMetric = axis === 'x' ? yAxisMetric : xAxisMetric;
    return metricOptions.filter(option => option.value !== excludedMetric);
  };

  const renderMetricPicker = (axis: 'x' | 'y', combobox: ReturnType<typeof useCombobox>) => {
    const selectedMetric = metricOptions.find(metric => 
      metric.value === (axis === 'x' ? xAxisMetric : yAxisMetric)
    );

    return (
      <Combobox
        store={combobox}
        onOptionSubmit={(val) => {
          onChange(axis, val as MetricKey);
          combobox.closeDropdown();
        }}
      >
        <Combobox.Target>
          <InputBase
            component="button"
            pointer
            rightSection={<Combobox.Chevron />}
            onClick={() => combobox.toggleDropdown()}
            rightSectionPointerEvents="none"
            style={{ width: '300px' }}
          >
            {selectedMetric ? (
              <Group gap="sm">
                {selectedMetric.icon}
                <span>{selectedMetric.label}</span>
              </Group>
            ) : (
              <span>Select {axis}-axis metric</span>
            )}
          </InputBase>
        </Combobox.Target>

        <Combobox.Dropdown>
          <Combobox.Options>
            {getSelectData(axis).map((metric) => (
              <Combobox.Option value={metric.value} key={metric.value}>
                <Group gap="sm">
                  {metric.icon}
                  <span>{metric.label}</span>
                </Group>
              </Combobox.Option>
            ))}
          </Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
    );
  };

  return (
    <Group gap="xs" wrap="nowrap">
        <Text size="sm">X: </Text>
      {renderMetricPicker('x', xCombobox)}
      <Text size="sm">Y: </Text>
      {renderMetricPicker('y', yCombobox)}
    </Group>
  );
}