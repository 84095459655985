import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { formatNumber, formatDate } from '../../../utils/formatter';
import { Text } from '@mantine/core';

interface StackedAreaChartProps {
  data: any[];
  areas: { key: string; color: string }[];
  xAxisDataKey: string;
}

const contentTypeColors: Record<string, string> = {
  'Short': '#FFA500',
  'Video': '#FF0000',
  'Podcast': '#9C27B0',
  'Live': '#007BFF',
  'Premiere': '#607D8B',
  'Music Video': '#FF0000',
  'Course': '#FF0000',
  'Untracked': '#C8C8C8',
};

const StackedAreaChartComponent: React.FC<StackedAreaChartProps> = ({ data, areas, xAxisDataKey }) => {
  // Sort areas to ensure "untracked" is first (bottom of the stack)
  const sortedAreas = [...areas].sort((a, b) => 
    a.key.toLowerCase() === 'untracked' ? -1 : b.key.toLowerCase() === 'untracked' ? 1 : 0
  );

  const getXAxisTicks = () => {
    if (!data || data.length === 0) return [];
    const firstDate = data[0]?.[xAxisDataKey];
    const middleDate = data[Math.floor(data.length / 2)]?.[xAxisDataKey];
    const lastDate = data[data.length - 1]?.[xAxisDataKey];
    return [firstDate, middleDate, lastDate].filter(Boolean);
  };

  return (
    <ResponsiveContainer width="100%" height={600} >
      <AreaChart data={data} margin={{ top: 10, right: 30, left: 30, bottom: 30 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis 
          dataKey={xAxisDataKey}
          tickFormatter={(tick) => formatDate(tick)}
          ticks={getXAxisTicks()}
        />
        <YAxis tickFormatter={(tick) => formatNumber(tick)} />
        <Tooltip content={<CustomTooltip />} />
        <defs>
          {sortedAreas.map((area) => (
            <linearGradient key={area.key} id={`color${area.key}`} x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={contentTypeColors[area.key] || area.color} stopOpacity={1}/>
              <stop offset="95%" stopColor={contentTypeColors[area.key] || area.color} stopOpacity={0.2}/>
            </linearGradient>
          ))}
        </defs>
        {sortedAreas.map((area) => (
          <Area 
            key={area.key}
            dataKey={area.key} 
            stackId="1"
            stroke={contentTypeColors[area.key] || area.color}
            fill={`url(#color${area.key})`}
            fillOpacity={area.key.toLowerCase() === 'untracked' ? 1 : 1}
            strokeWidth={area.key.toLowerCase() === 'untracked' ? 1 : 1}
            name={area.key}
            connectNulls
            isAnimationActive={true}
            animationDuration={2000}
          />
        ))}
      </AreaChart>
    </ResponsiveContainer>
  );
};

const CustomTooltip: React.FC<any> = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}>
        <Text size="sm" c="dimmed" fs="italic">{`Date: ${formatDate(label)}`}</Text>
        {payload.map((entry: any, index: number) => (
          <Text key={index} size="sm" style={{ color: entry.color }}>
            {`${entry.name === 'untracked' ? 'Untracked' : entry.name}: ${formatNumber(entry.value)}`}
          </Text>
        ))}
      </div>
    );
  }
  return null;
};

export default StackedAreaChartComponent;