import { format, parse, isValid } from 'date-fns';

export const formatDateUK = (date: Date | string): string => {
  try {
    let d: Date;
    if (typeof date === 'string') {
      // First, try to parse the date assuming it's in 'yyyy-MM-dd' format
      d = parse(date, 'yyyy-MM-dd', new Date());
      
      // If parsing fails (resulting in an invalid date), fall back to the default Date constructor
      if (!isValid(d)) {
        d = new Date(date);
      }
    } else {
      d = date;
    }

    // Check if the resulting date is valid
    if (!isValid(d)) {
      console.warn(`Invalid date encountered: ${date}`);
      return 'Invalid Date';
    }

    return format(d, 'dd/MM/yyyy');
  } catch (error) {
    console.error(`Error formatting date: ${date}`, error);
    return 'Invalid Date';
  }
};