import { SimulationNodeDatum, SimulationLinkDatum } from 'd3-force';

export interface SourceSubNode extends SimulationNodeDatum {
  id: string;
  title: string;
  type: string;
  sourcesubtype: string;
  publish_date: { value: string };
  x?: number;
  y?: number;
  vx?: number;
  vy?: number;
  fx?: number | undefined;
  fy?: number | undefined;
}

export interface SourceSubLink extends SimulationLinkDatum<SourceSubNode> {
  source: string | SourceSubNode;
  target: string | SourceSubNode;
  edge_type: string;
}

export interface NodeNeighborMap {
  [nodeId: string]: {
    neighbors: string[];
    links: SourceSubLink[];
  };
}

export function processNetworkData(
  data: any
): { nodes: SourceSubNode[], links: SourceSubLink[] } {
  console.log("Processing data:", data);

  if (!data || !data.nodes || !data.links) {
    console.error("Invalid data structure:", data);
    return { nodes: [], links: [] };
  }

  const nodes: SourceSubNode[] = data.nodes.map((node: any) => ({
    ...node,
    x: Math.random() * 1000,
    y: Math.random() * 1000,
    vx: 0,
    vy: 0,
    fx: undefined,
    fy: undefined
  }));

  const nodeMap = new Map(nodes.map(node => [node.id, node]));

  const links: SourceSubLink[] = data.links.map((link: any) => ({
    source: nodeMap.get(link.source) || link.source,
    target: nodeMap.get(link.target) || link.target,
    edge_type: link.edge_type
  }));

  console.log("Processed result. Nodes:", nodes.length, "Links:", links.length);

  return { nodes, links };
}

export function createNodeNeighborMap(
  nodes: SourceSubNode[], 
  links: SourceSubLink[]
): NodeNeighborMap {
  const neighborMap: NodeNeighborMap = {};

  nodes.forEach(node => {
    neighborMap[node.id] = { neighbors: [], links: [] };
  });

  links.forEach(link => {
    const sourceId = typeof link.source === 'string' ? link.source : link.source.id;
    const targetId = typeof link.target === 'string' ? link.target : link.target.id;

    if (!neighborMap[sourceId].neighbors.includes(targetId)) {
      neighborMap[sourceId].neighbors.push(targetId);
      neighborMap[sourceId].links.push(link);
    }

    if (!neighborMap[targetId].neighbors.includes(sourceId)) {
      neighborMap[targetId].neighbors.push(sourceId);
      neighborMap[targetId].links.push(link);
    }
  });

  return neighborMap;
}