import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { ChannelNetworksFilters } from './ChannelNetworksFilters';
import { VideoSubgraph } from './Subgraph';

export function ChannelNetworksSet() {
  const { subpage } = useParams<{ subpage: string }>();

  switch (subpage) {
    case 'network':
      return <ChannelNetworksFilters />;
    case 'subgraph':
      return <VideoSubgraph />;
    default:
      return <Navigate to="/channels/networks/network" replace />;
  }
}