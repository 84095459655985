import React, { useState, useEffect } from 'react';
import { Paper, Group, Text, Stack } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import { IconChevronRight, IconExternalLink, IconChartScatter } from '@tabler/icons-react';
import { ScatterChart, XAxis, YAxis, Tooltip, Scatter, ResponsiveContainer, CartesianGrid } from 'recharts';
import { SkeletonLoader } from '../../../core/SkeletonLoader';
import { ChannelPicker } from '../../components/ChannelPicker';
import { getChannelPerformanceData, getChannelSummary } from '../../../../utils/api';
import { ScatterMetricPicker, MetricKey } from '../../components/ScatterMetricPicker';
import { ClickableContentTypeBadge } from '../../components/ClickableContentTypeBadge';
import { formatNumber } from '../../../../utils/formatter';

const navItems = [
  { title: 'Channels', href: '/channels/channel' },
  { title: 'Performance', href: '/channels/performance/two-d' },
  { title: '2D', href: '/channels/performance/two-d' },
  { title: 'Compare 2D', href: '/channels/performance/compare-two-d' },
  { title: 'Treemap', href: '/channels/performance/treemap' },
  { title: 'Swarm', href: '/channels/performance/swarm' },
  { title: 'Notes', href: 'https://brick-river-8a5.notion.site/Channels-2D-1216777fc95580b4950bef02de3565d6', isExternal: true },
];

interface PerformanceDataItem {
  videoId: string;
  title: string;
  views: number;
  comments: number;
  likes: number;
  conversationRate: number;
  likeRate: number;
  contentType: string;
  channelId: string; // Added to track which channel the data belongs to
}

interface ChannelSummary {
    channelName: string;
    contentTypes: Array<keyof typeof contentTypeColors>; // Now explicitly typed to match our color configuration
  }

// Base content type colors (primary channel)
const contentTypeColors = {
  'Video': '#FF0000',
  'Short': '#FFA500',
  'Podcast': '#9C27B0',
  'Live': '#007BFF',
  'Premiere': '#607D8B',
  'Course': '#FF0000',
  'Music Video': '#FF0000'
};

// Secondary channel colors (lighter versions)
const secondaryContentTypeColors = {
  'Video': '#FF9999',
  'Short': '#FFD699',
  'Podcast': '#D699D6',
  'Live': '#99CCFF',
  'Premiere': '#B0C4CF',
  'Course': '#FF9999',
  'Music Video': '#FF9999'
};

const metricLabels: Record<MetricKey, string> = {
  views: 'Views',
  likes: 'Likes',
  comments: 'Comments',
  conversationRate: 'Conversation Rate',
  likeRate: 'Like Rate'
};

interface CustomTooltipProps {
  active?: boolean;
  payload?: any[];
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload }) => {
  if (active && payload && payload.length && payload[0].payload) {
    const data = payload[0].payload;
    return (
      <div style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}>
        <Text size="sm" fw={500}>{data.title || 'N/A'}</Text>
        <Text size="sm" c="dimmed">Channel: {data.channelName || 'N/A'}</Text>
        <Text size="sm">Type: {data.contentType || 'N/A'}</Text>
        <Text size="sm">Views: {formatNumber(data.views)}</Text>
        <Text size="sm">Likes: {formatNumber(data.likes)}</Text>
        <Text size="sm">Comments: {formatNumber(data.comments)}</Text>
        <Text size="sm">Conversation Rate: {data.conversationRate.toFixed(2)}%</Text>
        <Text size="sm">Like Rate: {data.likeRate.toFixed(2)}%</Text>
      </div>
    );
  }
  return null;
};

export function ChannelComparisonScatter() {
  const [primaryChannel, setPrimaryChannel] = useState<string | null>(null);
  const [secondaryChannel, setSecondaryChannel] = useState<string | null>(null);
  const [selectedXAxis, setSelectedXAxis] = useState<MetricKey>('views');
  const [selectedYAxis, setSelectedYAxis] = useState<MetricKey>('comments');
  const [performanceData, setPerformanceData] = useState<PerformanceDataItem[]>([]);
  const [channelSummaries, setChannelSummaries] = useState<Record<string, ChannelSummary>>({});
  const [visibleContentTypes, setVisibleContentTypes] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();

  // Effect for fetching data when either channel changes
  useEffect(() => {
    if (primaryChannel || secondaryChannel) {
      setLoading(true);
      setError(null);

      const channelsToFetch = [primaryChannel, secondaryChannel].filter(Boolean) as string[];
      
      Promise.all(
        channelsToFetch.map(channelId => 
          Promise.all([
            getChannelPerformanceData(channelId),
            getChannelSummary(channelId)
          ])
        )
      )
      .then(results => {
        const newPerformanceData: PerformanceDataItem[] = [];
        const newChannelSummaries: Record<string, ChannelSummary> = {};
        const allContentTypes = new Set<string>();

        results.forEach(([performanceResponse, summaryResponse], index) => {
            const channelId = channelsToFetch[index];
            const performance = performanceResponse.data.map((item: PerformanceDataItem) => ({
              ...item,
              channelId,
              channelName: summaryResponse.data.channelName
            }));
            
            newPerformanceData.push(...performance);
            newChannelSummaries[channelId] = summaryResponse.data;
            
            summaryResponse.data.contentTypes.forEach((type: keyof typeof contentTypeColors) => allContentTypes.add(type));
          });

        setPerformanceData(newPerformanceData);
        setChannelSummaries(newChannelSummaries);
        setVisibleContentTypes(Array.from(allContentTypes));
        setLoading(false);
      })
      .catch(err => {
        setError("Error fetching data. Please try again.");
        setLoading(false);
      });
    } else {
      setPerformanceData([]);
      setChannelSummaries({});
      setVisibleContentTypes([]);
    }
  }, [primaryChannel, secondaryChannel]);

  const toggleContentType = (contentType: string) => {
    setVisibleContentTypes(prev =>
      prev.includes(contentType)
        ? prev.filter(type => type !== contentType)
        : [...prev, contentType]
    );
  };

  const renderPerformanceScatter = () => (
    <ResponsiveContainer width="100%" height={700}>
      <ScatterChart margin={{ top: 20, right: 20, bottom: 100, left: 60 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          type="number"
          dataKey={selectedXAxis}
          name={metricLabels[selectedXAxis]}
          tickFormatter={(value: number) => formatNumber(value)}
          label={{ value: metricLabels[selectedXAxis], position: 'bottom', offset: 20 }}
        />
        <YAxis
          type="number"
          dataKey={selectedYAxis}
          name={metricLabels[selectedYAxis]}
          tickFormatter={(value: number) => formatNumber(value)}
          label={{ value: metricLabels[selectedYAxis], angle: -90, position: 'insideLeft', offset: -10 }}
        />
        <Tooltip content={<CustomTooltip />} />
        
        {/* Primary Channel Scatters */}
        {primaryChannel && visibleContentTypes.map((contentType) => (
          <Scatter
            key={`primary-${contentType}`}
            name={`${contentType} (${channelSummaries[primaryChannel]?.channelName})`}
            data={performanceData.filter(item => 
              item.channelId === primaryChannel && 
              item.contentType === contentType
            )}
            fill={contentTypeColors[contentType as keyof typeof contentTypeColors] || '#808080'}
            isAnimationActive={true}
            animationDuration={2000}
          />
        ))}

        {/* Secondary Channel Scatters */}
        {secondaryChannel && visibleContentTypes.map((contentType) => (
          <Scatter
            key={`secondary-${contentType}`}
            name={`${contentType} (${channelSummaries[secondaryChannel]?.channelName})`}
            data={performanceData.filter(item => 
              item.channelId === secondaryChannel && 
              item.contentType === contentType
            )}
            fill={secondaryContentTypeColors[contentType as keyof typeof secondaryContentTypeColors] || '#D3D3D3'}
            shape="cross"
            isAnimationActive={true}
            animationDuration={2000}
          />
        ))}
      </ScatterChart>
    </ResponsiveContainer>
  );

  return (
    <Stack gap="xs">
      {/* Navigation */}
      <Group gap={5}>
        {navItems.map((item, index) => (
          <React.Fragment key={item.title}>
            {index > 0 && index < 4 && <IconChevronRight size={14} color='#868e96'/>}
            {index > 3 && <Text size="sm" color="dimmed">|</Text>}
            {item.isExternal ? (
              <Text
                component="a"
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
                size="sm"
                c="#0982eb"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {item.title}
                <IconExternalLink size={14} style={{ marginLeft: 5 }} />
              </Text>
            ) : (
              <Text
                component={Link}
                to={item.href}
                size="sm"
                fw={index >= 3 && location.pathname === item.href ? 500 : 'normal'}
                td={index >= 3 && location.pathname === item.href ? 'underline' : 'none'}
                c={index < 2 ? 'dimmed' : '#0982eb'}
              >
                {item.title}
              </Text>
            )}
          </React.Fragment>
        ))}
      </Group>

      {/* Channel Selection and Controls */}
      <Stack gap="xs">
        <Group grow>
          <Paper p="md" radius="md" withBorder>
            <Text size="sm" fw={500} mb="xs">Select Channel 1 (round scatters)</Text>
            <ChannelPicker value={primaryChannel} onChange={setPrimaryChannel} />
          </Paper>
          <Paper p="md" radius="md" withBorder>
            <Text size="sm" fw={500} mb="xs">Select Channel 2 (cross scatters)</Text>
            <ChannelPicker value={secondaryChannel} onChange={setSecondaryChannel} />
          </Paper>
        </Group>

        <Group>
          <Paper p="md" radius="md" withBorder>
            <Text size="sm" fw={500} mb="xs">Select Metrics</Text>
            <ScatterMetricPicker
              xAxisMetric={selectedXAxis}
              yAxisMetric={selectedYAxis}
              onChange={(axis, value) => {
                if (axis === 'x') setSelectedXAxis(value);
                else setSelectedYAxis(value);
              }}
            />
          </Paper>
        </Group>

        {/* Main Visualization */}
        <Paper
          p="md"
          radius="md"
          withBorder
          style={{
            transition: 'box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out',
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.1)';
            e.currentTarget.style.transform = 'translateY(-5px)';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.boxShadow = '';
            e.currentTarget.style.transform = '';
          }}
        >
          {loading ? (
            <SkeletonLoader count={1} height={500} radius="sm" />
          ) : error ? (
            <Text color="red">{error}</Text>
          ) : performanceData.length > 0 ? (
            <>
              <Group align="center" mb="sm">
                <IconChartScatter size={20} />
                <Text size="md" fw={400} fs="italic">
                  {metricLabels[selectedXAxis]} and {metricLabels[selectedYAxis]} Comparison
                </Text>
              </Group>

              <Group mb="md">
                {Array.from(new Set(Object.values(channelSummaries).flatMap(summary => summary.contentTypes)))
                  .map((contentType: string) => (
                    <ClickableContentTypeBadge
                      key={contentType}
                      type={contentType}
                      isSelected={visibleContentTypes.includes(contentType)}
                      onClick={() => toggleContentType(contentType)}
                    />
                  ))}
              </Group>

              {renderPerformanceScatter()}
            </>
          ) : (
            <Text>Select two channels to compare their performance.</Text>
          )}
        </Paper>
      </Stack>
    </Stack>
  );
}