import React from 'react';
import { Card, Group, Text, Stack, Image, Box, Anchor, HoverCard, Divider } from '@mantine/core';
import { IconExternalLink } from '@tabler/icons-react';
import { ContentTypeBadge } from './ContentTypeBadge';
import { formatDate, formatSeconds } from '../../../utils/formatter';

interface VideoInfoCardProps {
  metadata: {
    id: string;
    title: string;
    publishDate: string;
    type: string;
    wikipediaTopic: string;
    modelTopic: string;
    videoLength: string;
  };
  firstTrackedDate: string;
}

interface CroppedThumbnailProps {
  videoId: string;
  title: string;
  type: string;
}

export const CroppedThumbnail: React.FC<CroppedThumbnailProps> = ({ videoId, title, type }) => {
  const isShort = type === 'Short';
  const thumbnailUrl = isShort
    ? `https://i.ytimg.com/vi/${videoId}/oar2.jpg`
    : `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;

  return (
    <Box
      style={{
        width: '100%',
        height: 0,
        paddingBottom: isShort ? '177.78%' : '56.25%', // 16:9 for regular videos, 9:16 for shorts
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Image
        src={thumbnailUrl}
        alt={title}
        fit="cover"
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: isShort ? 'auto' : '100%',
          height: isShort ? '100%' : 'auto',
          maxWidth: '100%',
          maxHeight: '100%',
        }}
      />
    </Box>
  );
};

export function VideoInfoCard({ metadata, firstTrackedDate }: VideoInfoCardProps) {
  const videoUrl = metadata.type === 'Short'
    ? `https://youtube.com/shorts/${metadata.id}`
    : `https://youtube.com/watch?v=${metadata.id}`;

  const isShort = metadata.type === 'Short';

  return (
    <Card 
      withBorder
      p="md"
      radius="md"
      style={{ 
        transition: 'box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out',
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.1)';
        e.currentTarget.style.transform = 'translateY(-5px)';
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.boxShadow = '';
        e.currentTarget.style.transform = '';
      }}
    >
      <Group align="flex-start" gap="md" wrap="nowrap">
        <Box style={{ width: isShort ? '30%' : '40%', maxWidth: isShort ? '200px' : '300px' }}>
          <HoverCard shadow="md" openDelay={500}>
            <HoverCard.Target>
              <div>
                <CroppedThumbnail videoId={metadata.id} title={metadata.title} type={metadata.type} />
              </div>
            </HoverCard.Target>
            <HoverCard.Dropdown>
              <Anchor 
                href={videoUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Group>
                  <Text size="sm">Watch on YouTube</Text>
                  <IconExternalLink size={14} />
                </Group>
              </Anchor>
            </HoverCard.Dropdown>
          </HoverCard>
        </Box>
        <Stack gap="xs" style={{ flex: 1 }}>
          <Text w={500}>{metadata.title}</Text>
          <ContentTypeBadge type={metadata.type} size="md" />
          <Text size="sm" color="dimmed">Published: {formatDate(metadata.publishDate)}</Text>
          <Text size="sm" color="dimmed">Length: {formatSeconds(parseInt(metadata.videoLength))}</Text>
          <Divider my="xs" />
          <Text size="sm" color={metadata.wikipediaTopic ? undefined : "dimmed"} fs={!metadata.wikipediaTopic ? "italic" : undefined}>
            YouTube's Topics: {metadata.wikipediaTopic || "Not assigned by YouTube"}
          </Text>
          <Text size="sm" color={metadata.modelTopic ? undefined : "dimmed"} fs={!metadata.modelTopic ? "italic" : undefined}>
            Model Topic: {metadata.modelTopic || "Not Modelled"}
          </Text>
          <Divider my="xs" />
          <Text size="xs" color="dimmed" fs="italic">First Ingested: {formatDate(firstTrackedDate)}</Text>
        </Stack>
      </Group>
    </Card>
  );
}
