import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine } from 'recharts';
import { Text, Group } from '@mantine/core';
import { formatNumber, formatDate } from '../../../utils/formatter';
import { IconChartAreaFilled } from '@tabler/icons-react';

interface TotalChartProps {
  chartData: ChartDataPoint[];
  metric: 'views' | 'likes' | 'comments';
  publishDate: string;
  firstTrackedDate: string;
  lastTrackedDate: string;
  updatedAtDate: string;
  contentType: string;
  videoTitle: string;
}

interface ChartDataPoint {
  date: string;
  daysLive: number;
  preTracking: number | null;
  tracked: number | null;
}

const CustomizedAxisTick: React.FC<any> = ({ x, y, payload }) => (
  <g transform={`translate(${x},${y})`}>
    <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)" fontSize={12}>
      {formatDate(payload.value)}
    </text>
  </g>
);

const getContentTypeColor = (contentType: string): string => {
  switch (contentType) {
    case 'Short': return '#FFA500';
    case 'Video': return '#FF0000';
    case 'Podcast': return '#9C27B0';
    case 'Live': return '#007BFF';
    case 'Premiere': return '#607D8B';
    case 'Music Video': return '#FF0000';
    case 'Course': return '#FF0000';
    default: return '#0d3fa6';
  }
};

export const TotalChart: React.FC<TotalChartProps> = ({
  chartData,
  metric,
  publishDate,
  firstTrackedDate,
  lastTrackedDate,
  updatedAtDate,
  contentType,
  videoTitle,
}) => {
  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      const value = data.tracked !== null ? data.tracked : data.preTracking;
      return (
        <div style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}>
          <Text size="sm" fw={500}>Day {data.daysLive}</Text>
          <Text size="sm" fs="italic" c="dimmed">{formatDate(data.date)}</Text>
          <Text size="sm" fw={500}>Total {metric}: {formatNumber(value as number)}</Text>
        </div>
      );
    }
    return null;
  };

  const contentTypeColor = getContentTypeColor(contentType);

  return (
    <>
      <Group align="center" mb="sm">
        <IconChartAreaFilled size={20} />
        <Text size="md" fw={400} fs="italic">
          Total {metric} for "{videoTitle.length > 50 ? videoTitle.substring(0, 47) + '[...]' : videoTitle}"
        </Text>
      </Group>
      <ResponsiveContainer width="100%" height={600}>
        <AreaChart
          data={chartData}
          margin={{ top: 10, right: 30, left: 0, bottom: 60 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            type="category"
            tick={<CustomizedAxisTick />}
            domain={[publishDate, lastTrackedDate]}
            ticks={[publishDate, firstTrackedDate, lastTrackedDate]}
          />
          <YAxis tickFormatter={(value) => formatNumber(value)} />
          <Tooltip content={<CustomTooltip />} />
          <defs>
            <linearGradient id="colorPreTracking" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#808080" stopOpacity={0.8}/>
              <stop offset="95%" stopColor="#808080" stopOpacity={0.2}/>
            </linearGradient>
            <linearGradient id="colorTracked" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={contentTypeColor} stopOpacity={1}/>
              <stop offset="95%" stopColor={contentTypeColor} stopOpacity={0.2}/>
            </linearGradient>
          </defs>
          <Area 
            type="monotone" 
            dataKey="preTracking"
            fill="url(#colorPreTracking)"
            stroke="#808080"
            strokeWidth={1}
            fillOpacity={1}
            isAnimationActive={true}
            animationDuration={2000}
            connectNulls
          />
          <Area 
            type="monotone" 
            dataKey="tracked"
            fill="url(#colorTracked)"
            stroke={contentTypeColor}
            strokeWidth={1}
            fillOpacity={1}
            isAnimationActive={true}
            animationDuration={2000}
            connectNulls
          />
          <ReferenceLine
            x={firstTrackedDate}
            stroke="gray"
            strokeDasharray="3 3"
          />
        </AreaChart>
      </ResponsiveContainer>
    </>
  );
};

export default TotalChart;