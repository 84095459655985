import React, { useState, useEffect } from 'react';
import { Group, Text, Stack, Grid, Card, Select, Checkbox, Progress, Tooltip, Divider } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import { IconChevronRight, IconExternalLink } from '@tabler/icons-react';
import { ChannelPicker } from '../../components/ChannelPicker';
import { SkeletonLoader } from '../../../core/SkeletonLoader';
import { getChannelSummary, getChannelChartData, getTop10Videos, getVideoTitles, getNormalizedViews, VideoTitleData, NormalizedViewsData } from '../../../../utils/api';
import { formatNumber, formatDate } from '../../../../utils/formatter';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import StackedAreaChart from '../../components/StackedAreaChart';
import SubscribersChart from '../../components/ChannelSubscriberChart';
import ViewsAllChart from '../../components/ChannelsViewsAllChart';
import LibrarySizeAllChart from '../../components/ChannelLibrarySize';
import { ContentTypeBadge } from '../../components/ContentTypeBadge';
import { ClickableContentTypeBadge } from '../../components/ClickableContentTypeBadge';
import { DailyChangeBarChart } from '../../components/DailyChangeBarChart';
import DailyViewsChart from '../../components/ChannelsViewsWithBadge';
import NormalizedViewsChart from '../../components/NormalizedViewsChart';

const ChangeText: React.FC<{ change: number | null | undefined }> = ({ change }) => {
  if (change === null || change === undefined) return null;
  return (
    <Text color={change >= 0 ? 'green' : 'red'}>
      {change > 0 ? '+' : ''}{formatNumber(change)}
    </Text>
  );
};

const EXCLUDED_CHANNEL_ID = 'UC3Bg_lsqN-07NhWsyWalSOQ';

interface ChannelSummary {
  channelName: string;
  channelIconUrl: string;
  totalSubscribers: number;
  subscribersChange: number;
  totalViews: number;
  viewsChange: number;
  totalVideos: number;
  videosChange: number;
  youtubeTopics: string;
  trackedVideosCount: number;
  libraryPercentage: number;
  firstTrackedVideoDate: string | null;
  firstTrackingDate: string;
  contentTypes: string[];
  videoTypeCounts: Record<string, number>;
  firstVideoDate: string;
  mostRecentUpload: string;
  lastUpdated: string;
}

interface SingleAreaChartData {
  chartData: Array<{
    date: string;
    preTracking?: number | null;
    tracked?: number | null;
    totalSubscribers?: number;
    totalViews?: number;
    totalVideos?: number;
  }>;
  firstVideoDate: string;
  firstTrackedDate: string;
  lastTrackedDate: string;
}

interface DailyChangeData {
  date: string;
  [key: string]: number | string;
}

// navItems for general channels section
const navItems = [
  { title: 'Channels', href: '/channels/channels/channel' },
  { title: 'Channels', href: '/channels/channels/channel' },
  { title: 'Channel', href: '/channels/channels/channel' },
  { title: 'Top Five', href: '/channels/channels/top-five' },
  { title: 'Compare Channels', href: '/channels/channels/compare' },
  { title: 'Timeline', href: '/channels/channels/timeline' },
  { title: 'Classification', href: '/channels/channels/classification' },
  { title: 'Notes', href: 'https://brick-river-8a5.notion.site/Channels-Channel-11f6777fc95580f78c07d2219639b06c', isExternal: true },
];

const contentTypeColors: Record<string, string> = {
  'Short': '#FFA500',
  'Video': '#FF0000',
  'Podcast': '#9C27B0',
  'Live': '#007BFF',
  'Premiere': '#607D8B',
  'Music Video': '#FF0000',
  'Course': '#FF0000',
};

export function ChannelSummary() {
  const [selectedChannel, setSelectedChannel] = useState<string | null>(null);
  const [summaryData, setSummaryData] = useState<ChannelSummary | null>(null);
  const [chartData, setChartData] = useState<any[]>([]);
  const [singleAreaChartData, setSingleAreaChartData] = useState<SingleAreaChartData | null>(null);
  const [selectedChart, setSelectedChart] = useState<string>('dailyViewsTotal');
  const [trackedOnly, setTrackedOnly] = useState<boolean>(true);
  const [top10Videos, setTop10Videos] = useState<any[]>([]);
  const [top10Type, setTop10Type] = useState<'daily' | 'total'>('daily');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [excludeShorts, setExcludeShorts] = useState(false);
  const [shortsCount, setShortsCount] = useState(0);
  const [contentTypes, setContentTypes] = useState<string[]>([]);
  const [visibleContentTypes, setVisibleContentTypes] = useState<string[]>([]);
  const [visibleAreas, setVisibleAreas] = useState<string[]>([]);
  const [dailyChangeData, setDailyChangeData] = useState<DailyChangeData[]>([]);
  const [dailyLibraryData, setDailyLibraryData] = useState<DailyChangeData[]>([]);
  const isBarChart = selectedChart === 'dailyViews' || selectedChart === 'dailyLibrary' || selectedChart === 'dailyViewsTotal' || selectedChart === 'normalizedViews';
  const [videoTitles, setVideoTitles] = useState<Record<string, VideoTitleData>>({});
const [normalizedViewsData, setNormalizedViewsData] = useState<NormalizedViewsData[]>([]);
  const location = useLocation();

  useEffect(() => {
    if (selectedChannel) {
      fetchSummaryData();
      fetchTop10Videos();
    }
  }, [selectedChannel]);

  useEffect(() => {
    if (selectedChannel && selectedChart) {
      fetchChartData();
    }
  }, [selectedChannel, selectedChart, trackedOnly]);

  useEffect(() => {
    if (selectedChannel && (
      selectedChart === 'dailyViews' || 
      selectedChart === 'dailyLibrary' || 
      selectedChart === 'dailyViewsTotal'
    )) {
      fetchDailyChangeData();
    }
  }, [selectedChannel, selectedChart]);

  useEffect(() => {
    if (selectedChannel) {
      fetchTop10Videos();
    }
  }, [selectedChannel, top10Type, excludeShorts]);

  useEffect(() => {
    if (summaryData) {
      setVisibleAreas(['untracked', ...summaryData.contentTypes]);
    }
  }, [summaryData]);

  const fetchSummaryData = async () => {
    if (!selectedChannel) return;
    setLoading(true);
    setError(null);
    try {
      const response = await getChannelSummary(selectedChannel);
      setSummaryData(response.data);
      setShortsCount(response.data.videoTypeCounts['Short'] || 0);
      setContentTypes(response.data.contentTypes);
      setVisibleContentTypes(response.data.contentTypes);
    } catch (err) {
      setError('Failed to fetch channel summary. Please try again.');
      console.error('Error fetching channel summary:', err);
    } finally {
      setLoading(false);
    }
  };

  const fetchChartData = async () => {
    if (!selectedChannel) return;
    setLoading(true);
    setError(null);
    try {
      let endpoint;
      
      if (selectedChart === 'normalizedViews') {
        const response = await getNormalizedViews(selectedChannel);
        console.log('Normalized Views Response:', response.data); // Debug log
        if (Array.isArray(response.data)) {  // Add type check
          setNormalizedViewsData(response.data);
          
          // Get unique video IDs from all dates
          const allVideoIds = new Set<string>();
          response.data.forEach(day => day.videoIds.forEach(id => allVideoIds.add(id)));
          console.log('Collected Video IDs:', allVideoIds); // Debug log
          
          if (allVideoIds.size > 0) {
            const titlesResponse = await getVideoTitles(selectedChannel, Array.from(allVideoIds));
            console.log('Video Titles Response:', titlesResponse.data); // Debug log
            setVideoTitles(titlesResponse.data);
          }
        }
      } else if (selectedChart.startsWith('stacked')) {
        endpoint = trackedOnly 
          ? `/channels/${selectedChannel}/stacked-${selectedChart.replace('stacked', '').toLowerCase()}-tracked` 
          : `/channels/${selectedChannel}/stacked-${selectedChart.replace('stacked', '').toLowerCase()}`;
        const response = await getChannelChartData(endpoint);
        setChartData(response.data);
      } else if (selectedChart === 'dailyViews' || selectedChart === 'dailyLibrary' || selectedChart === 'dailyViewsTotal') {
        // Handle daily change charts
        if (selectedChart === 'dailyViewsTotal') {
          const [viewsResponse, libraryResponse] = await Promise.all([
            getChannelChartData(`/channels/${selectedChannel}/daily-change/views`),
            getChannelChartData(`/channels/${selectedChannel}/daily-change/library_size`)
          ]);
          setDailyChangeData(viewsResponse.data);
          setDailyLibraryData(libraryResponse.data);
        } else {
          const type = selectedChart === 'dailyViews' ? 'views' : 'library_size';
          const response = await getChannelChartData(`/channels/${selectedChannel}/daily-change/${type}`);
          setDailyChangeData(response.data);
        }
      } else {
        switch (selectedChart) {
          case 'subscribers':
            endpoint = trackedOnly ? `/channels/${selectedChannel}/subscribers-tracked-charts` : `/channels/${selectedChannel}/subscribers-charts`;
            break;
          case 'viewsAll':
            endpoint = trackedOnly ? `/channels/${selectedChannel}/views-all-tracked` : `/channels/${selectedChannel}/views-all`;
            break;
          case 'videosAll':
            endpoint = trackedOnly ? `/channels/${selectedChannel}/library-size-all-tracked` : `/channels/${selectedChannel}/library-size-all`;
            break;
          default:
            throw new Error('Invalid chart type');
        }
        const response = await getChannelChartData(endpoint);
        setSingleAreaChartData(response.data);
      }
    } catch (err) {
      setError('Failed to fetch chart data. Please try again.');
      console.error('Error fetching chart data:', err);
    } finally {
      setLoading(false);
    }
  };

  const fetchDailyChangeData = async () => {
    if (!selectedChannel) return;
    setLoading(true);
    setError(null);
    try {
      if (selectedChart === 'dailyViewsTotal') {
        // Fetch both views and library data
        const [viewsResponse, libraryResponse] = await Promise.all([
          getChannelChartData(`/channels/${selectedChannel}/daily-change/views`),
          getChannelChartData(`/channels/${selectedChannel}/daily-change/library_size`)
        ]);
        setDailyChangeData(viewsResponse.data);
        setDailyLibraryData(libraryResponse.data);
      } else {
        const type = selectedChart === 'dailyViews' ? 'views' : 'library_size';
        const response = await getChannelChartData(`/channels/${selectedChannel}/daily-change/${type}`);
        setDailyChangeData(response.data);
      }
    } catch (err) {
      setError('Failed to fetch daily change data. Please try again.');
      console.error('Error fetching daily change data:', err);
    } finally {
      setLoading(false);
    }
  };

  const fetchTop10Videos = async () => {
    if (!selectedChannel) return;
    try {
      const response = await getTop10Videos(selectedChannel, top10Type, excludeShorts);
      setTop10Videos(response.data);
    } catch (err) {
      console.error('Error fetching top 10 videos:', err);
    }
  };

  const renderChannelKeyData = () => (
    <Card
        p="md" 
        radius="md" 
        withBorder
        style={{ 
              transition: 'box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out',
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.1)';
              e.currentTarget.style.transform = 'translateY(-5px)';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.boxShadow = '';
              e.currentTarget.style.transform = '';
            }}
      >
      <Stack>
        <Group>
          <Text size="sm">Subscribers: {formatNumber(summaryData?.totalSubscribers)} </Text>
          <ChangeText change={summaryData?.subscribersChange} />
        </Group>
        <Group>
          <Text size="sm">Total Views: {formatNumber(summaryData?.totalViews)} </Text>
          <ChangeText change={summaryData?.viewsChange} />
        </Group>
        <Group gap="xs" wrap="wrap"> 
          <Text size="sm">Content Types: </Text>
          {summaryData?.contentTypes.map((type) => (
            <ContentTypeBadge key={type} type={type} />
          ))}
        </Group>
        <Text size="sm">YouTube's Topics: {summaryData?.youtubeTopics}</Text>
        <Divider my="xs" />
        <Text size="sm">First Upload: {formatDate(summaryData?.firstVideoDate)}</Text>
        <Text size="sm">Most Recent Upload: {formatDate(summaryData?.mostRecentUpload)}</Text>
        <Divider my="xs" />
        <Text size="sm">First Tracked Video: {formatDate(summaryData?.firstTrackedVideoDate)}</Text>
        <Text size="sm">Tracking Started: {formatDate(summaryData?.firstTrackingDate)}</Text>
        <Divider my="xs" />
        <Text size="xs" c="dimmed" fs="italic">Last Updated: {formatDate(summaryData?.lastUpdated)}</Text>
      </Stack>
    </Card>
  );

  const renderLibraryComposition = () => (
    <Card
      p="md" 
      radius="md" 
      withBorder
      style={{ 
            transition: 'box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out',
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.1)';
            e.currentTarget.style.transform = 'translateY(-5px)';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.boxShadow = '';
            e.currentTarget.style.transform = '';
          }}
      >
      <Text size = "sm">Total Videos: {formatNumber(summaryData?.totalVideos)}</Text>
      <Text size = "sm" mt="xs">% of Library Tracked: {summaryData?.libraryPercentage.toFixed(2)}%</Text>
      <Divider my="xs" />
      <Text size = "sm">Tracked Videos: {summaryData?.trackedVideosCount}, of which: </Text>
      <Stack mt="xs">
        {Object.entries(summaryData?.videoTypeCounts || {}).map(([type, count]) => (
          <Text key={type} size = "sm">{type}: {formatNumber(count)}</Text>
        ))}
      </Stack>
      <Divider my="xs" />
      <Progress.Root size={30}>
      <Tooltip label={`Untracked: ${formatNumber((summaryData?.totalVideos || 0) - (summaryData?.trackedVideosCount || 0))}`}>
          <Progress.Section
            value={100 - (summaryData?.libraryPercentage || 0)}
            color="#C8C8C8"
          >
          </Progress.Section>
        </Tooltip>
        {Object.entries(summaryData?.videoTypeCounts || {}).map(([type, count]) => (
          <Tooltip key={type} label={`${type}: ${count}`}>
            <Progress.Section 
              value={(count / (summaryData?.totalVideos || 1)) * 100} 
              color={contentTypeColors[type] || '#808080'}
            >
            </Progress.Section>
          </Tooltip>
        ))}
      </Progress.Root>
    </Card>
  );

  const renderTop10Table = () => (
    <Card
      p="md" 
      radius="md" 
      withBorder
      style={{ 
            transition: 'box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out',
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.1)';
            e.currentTarget.style.transform = 'translateY(-5px)';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.boxShadow = '';
            e.currentTarget.style.transform = '';
          }}
        >
      <Group gap="apart" mb="md">
        <Text fs="italic" fw={500}>Yesterday's Top 10</Text>
          <Select
            value={top10Type}
            onChange={(value) => {
              if (value === 'daily' || value === 'total') {
                setTop10Type(value);
              }
            }}
            data={[
              { value: 'daily', label: 'Views Gained (Last 24 hours)' },
              { value: 'total', label: 'Total Views (Tracked Videos)' }
            ]}
            style={{ width: '100%', maxWidth: '300px' }}
          />
          {shortsCount >= 10 && (
            <Checkbox
              label="Exclude Shorts from table"
              checked={excludeShorts}
              onChange={(event) => setExcludeShorts(event.currentTarget.checked)}
            />
          )}
      </Group>
      <div className="ag-theme-quartz" style={{ height: 470, width: '100%' }}>
        <AgGridReact
          rowData={top10Videos}
          columnDefs={[
            { headerName: 'Rank', field: 'rank', width: 70 },
            { headerName: 'Title', field: 'title', flex: 1 },
            { headerName: 'Type', field: 'videoType', width: 100 },
            { headerName: 'Publish Date', field: 'publishDate', valueFormatter: (params) => formatDate(params.value), type: 'numericColumn' },
            { headerName: 'Views', field: 'views', valueFormatter: (params) => formatNumber(params.value), type: 'rightAligned' }
          ]}
        />
      </div>
    </Card>
  );

  const renderSingleAreaChart = () => {
    if (!singleAreaChartData) return null;

    const commonProps = {
      data: singleAreaChartData.chartData,
      startDate: singleAreaChartData.firstVideoDate,
      firstTrackedDate: singleAreaChartData.firstTrackedDate,
      lastTrackedDate: singleAreaChartData.lastTrackedDate
    };

    switch (selectedChart) {
      case 'subscribers':
        return <SubscribersChart {...commonProps} />;
      case 'viewsAll':
        return <ViewsAllChart {...commonProps} />;
      case 'videosAll':
        return <LibrarySizeAllChart {...commonProps} />;
      default:
        return null;
    }
  };

  const renderChart = () => {
    const isStackedChart = selectedChart.startsWith('stacked');
    const isDailyChangeChart = selectedChart === 'dailyViews' || selectedChart === 'dailyLibrary';
    const isDailyViewsTotalChart = selectedChart === 'dailyViewsTotal';
    const isNormalizedViewsChart = selectedChart === 'normalizedViews';
  
    if (isNormalizedViewsChart) {
      return (
        <NormalizedViewsChart
          data={normalizedViewsData}
          videoTitles={videoTitles}
        />
      );
    } else if (isStackedChart) {
      return (
        <>
          <Group mb="md">
            {['untracked', ...summaryData?.contentTypes || []].map((type) => (
              <ClickableContentTypeBadge
                key={type}
                type={type}
                isSelected={visibleAreas.includes(type)}
                onClick={() => {
                  if (visibleAreas.includes(type)) {
                    setVisibleAreas(visibleAreas.filter(t => t !== type));
                  } else {
                    setVisibleAreas([...visibleAreas, type]);
                  }
                }}
              />
            ))}
          </Group>
          <StackedAreaChart
            data={chartData}
            areas={visibleAreas.map(type => ({ key: type, color: contentTypeColors[type] || '#808080' }))}
            xAxisDataKey="date"
          />
        </>
      );
    } else if (isDailyChangeChart) {
      return (
        <>
          <Group mb="md">
            {['untracked', ...summaryData?.contentTypes || []].map((type) => (
              <ClickableContentTypeBadge
                key={type}
                type={type}
                isSelected={visibleAreas.includes(type)}
                onClick={() => {
                  if (visibleAreas.includes(type)) {
                    setVisibleAreas(visibleAreas.filter(t => t !== type));
                  } else {
                    setVisibleAreas([...visibleAreas, type]);
                  }
                }}
              />
            ))}
          </Group>
          <DailyChangeBarChart
            data={dailyChangeData}
            contentTypes={visibleAreas}
          />
        </>
      );
    } else if (isDailyViewsTotalChart) {
      return (
        <DailyViewsChart
          viewsData={dailyChangeData}
          libraryData={dailyLibraryData}
        />
      );
    } else {
      return renderSingleAreaChart();
    }
  };

  return (
    <Stack gap="md">
      <Group gap={5}>
        {navItems.map((item, index) => (
          <React.Fragment key={item.title}>
            {index > 0 && index < 3 && <IconChevronRight size={14} color='#868e96'/>}
            {index > 2 && <Text size="sm" color="dimmed">|</Text>}
            {item.isExternal ? (
              <Text
                component="a"
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
                size="sm"
                c="#0982eb"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {item.title}
                <IconExternalLink size={14} style={{ marginLeft: 5 }} />
              </Text>
            ) : (
              <Text
                component={Link}
                to={item.href}
                size="sm"
                fw={index >= 2 && location.pathname === item.href ? 500 : 'normal'}
                td={index >= 2 && location.pathname === item.href ? 'underline' : 'none'}
                c={index < 2 ? 'dimmed' : '#0982eb'}
              >
                {item.title}
              </Text>
            )}
          </React.Fragment>
        ))}
      </Group>
      <ChannelPicker value={selectedChannel} onChange={setSelectedChannel} />
      {loading ? (
        <SkeletonLoader count={4} height={200} />
      ) : error ? (
        <Text color="red" mt="md">{error}</Text>
      ) : summaryData ? (
        <Stack mt="md">
          <Grid>
            <Grid.Col span={6}>{renderChannelKeyData()}</Grid.Col>
            <Grid.Col span={6}>{renderLibraryComposition()}</Grid.Col>
          </Grid>
          {renderTop10Table()}
          <Card
            p="md" 
            radius="md" 
            withBorder
            style={{ 
              transition: 'box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out',
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.1)';
              e.currentTarget.style.transform = 'translateY(-5px)';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.boxShadow = '';
              e.currentTarget.style.transform = '';
            }}
          >
            <Group gap="apart" mb="md">
            <Select
              value={selectedChart}
              onChange={(value) => setSelectedChart(value || 'subscribers')}
              data={[
                { value: 'subscribers', label: 'Subscribers' },
                { value: 'viewsAll', label: 'Views (all content types)' },
                { value: 'videosAll', label: 'Library Size (all content types)' },
                ...(selectedChannel !== EXCLUDED_CHANNEL_ID ? [
                  { value: 'dailyViewsTotal', label: 'Total Daily Views' }, 
                  { value: 'normalizedViews', label: 'Normalized Views' },
                  { value: 'stackedViews', label: 'Stacked Views' },
                  { value: 'stackedLibrary', label: 'Stacked Library Size' },
                  { value: 'dailyViews', label: 'Daily Views Change' },
                { value: 'dailyLibrary', label: 'Daily Library Size Change' },
              ] : [])
            ]}
              style={{ width: '100%', maxWidth: '300px' }}
            />
            {!isBarChart && (
            <Checkbox
              label="Show tracked period only"
              checked={trackedOnly}
              onChange={(event) => setTrackedOnly(event.currentTarget.checked)}
            />
           )}
            </Group>
            {renderChart()}
          </Card>
        </Stack>
      ) : null}
    </Stack>
  );
}