import React from 'react';
import { DatePickerInput } from '@mantine/dates';

interface DatePickerSingleProps {
  value: Date | null;
  onChange: (date: Date | null) => void;
  label?: string;
  minDate?: Date;
  maxDate?: Date;
  excludeDate?: (date: Date) => boolean;
}

export const DatePickerSingle: React.FC<DatePickerSingleProps> = ({ 
  value, 
  onChange, 
  label,
  minDate,
  maxDate,
  excludeDate,
}) => {
  return (
    <DatePickerInput
      label={label}
      placeholder="Pick date"
      value={value}
      onChange={onChange}
      minDate={minDate}
      maxDate={maxDate}
      excludeDate={excludeDate}
      mx="auto"
      maw={400}
    />
  );
};