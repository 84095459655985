import React, { useState, useCallback, useRef, useEffect } from 'react';
import { ForceGraph2D } from 'react-force-graph';
import * as d3 from 'd3';
import { Paper, Group, Text, Stack, Grid, HoverCard, Box } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import { IconChevronRight, IconExternalLink, IconKey, IconArrowsMaximize, IconArrowsMinimize } from '@tabler/icons-react';
import { SkeletonLoader } from '../../../core/SkeletonLoader';
import { 
  processChannelNetworkData, 
  ChannelNetworkNode, 
  ChannelNetworkLink, 
  createNodeNeighborMap, 
  NodeNeighborMap, 
  filterGraphData,
  FilterMode,
  AgeCategory,
  filterNetworkByAge
} from '../../../../utils/channelNetworkUtils';
import { getChannelNetwork, getChannelTypes } from '../../../../utils/api';
import { ChannelPicker } from '../../components/ChannelPicker';
import { EdgeTypePicker } from '../../components/EdgeTypePicker';
import { NetworkFilter } from '../../components/NetworkFilter';
import { useFullscreen } from '@mantine/hooks';

const navItems = [
  { title: 'Channels', href: '/channels/channel' },
  { title: 'Networks', href: '/channels/networks/network'},
  { title: 'Network', href: '/channels/networks/network'},
  { title: 'Subgraph', href: '/channels/networks/subgraph'},
  { title: 'Notes', href: 'https://brick-river-8a5.notion.site/Channels-Network-11f6777fc95580009b04e2ad5f23c0ce', isExternal: true },
];

const nodeTypeColors = {
  'X': '#000000',
  'Shop': '#007D5E',
  'Short': '#FFA500',
  'Video': '#FF0000',
  'Amazon': '#232F3E',
  'Channel': '#FF0000',
  'Podcast': '#9C27B0',
  'External': '#0000FF',
  'Live': '#007BFF',
  'TikTok': '#FF0050',
  'Spotify': '#1ED760',
  'Facebook': '#1877F2',
  'Playlist': '#FF0000',
  'Premiere': '#607D8B',
  'Instagram': '#D300C5',
  'Music Video': '#FF0000',
  'Apple Podcast': '#D56DFB',
  'YouTube (Other)': '#FF0000',
} as const;

const edgeTypeColors = {
  'description': '#D3D3D3',
  'endscreen': '#0a326a',
  'shorts_related': '#ada476',
} as const;

export function ChannelNetworksFilters() {
  // State management
  const [selectedChannel, setSelectedChannel] = useState<string | null>(null);
  const [edgeTypes, setEdgeTypes] = useState<string[]>([]);
  const [nodeTypes, setNodeTypes] = useState<string[]>([]);
  const [rawGraphData, setRawGraphData] = useState<{ nodes: ChannelNetworkNode[], links: ChannelNetworkLink[] } | null>(null);
  const [filteredGraphData, setFilteredGraphData] = useState<{ nodes: ChannelNetworkNode[], links: ChannelNetworkLink[] } | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [filterMode, setFilterMode] = useState<FilterMode>(null);
  const [selectedEdgeTypes, setSelectedEdgeTypes] = useState<string[]>([]);
  const [selectedAgeCategories, setSelectedAgeCategories] = useState<AgeCategory[]>([]);

  // Highlight state
  const [highlightNodes, setHighlightNodes] = useState(new Set());
  const [highlightLinks, setHighlightLinks] = useState(new Set());
  const [hoverNode, setHoverNode] = useState<ChannelNetworkNode | null>(null);
  const [neighborMap, setNeighborMap] = useState<NodeNeighborMap>({});

  // Refs
  const fgRef = useRef<any>();
  const containerRef = useRef<HTMLDivElement>(null);
  const paperRef = useRef<HTMLDivElement>(null);
  const { toggle, fullscreen } = useFullscreen();

  // Effects
  useEffect(() => {
    if (selectedChannel) {
      fetchChannelTypes();
      fetchData();
    }
  }, [selectedChannel]);

  // Effect to handle edge type filtering
  useEffect(() => {
    if (!rawGraphData) return;

    let filtered = { ...rawGraphData };

    if (filterMode === 'edge' && selectedEdgeTypes.length > 0) {
      filtered = filterGraphData(filtered.nodes, filtered.links, selectedEdgeTypes);
    } else if (filterMode === 'age' && selectedAgeCategories.length > 0) {
      filtered = filterNetworkByAge(filtered.nodes, filtered.links, selectedAgeCategories);
    }

    setFilteredGraphData(filtered);
    const newNeighborMap = createNodeNeighborMap(filtered.nodes, filtered.links);
    setNeighborMap(newNeighborMap);

  }, [rawGraphData, filterMode, selectedEdgeTypes, selectedAgeCategories]);

  // Graph setup effect
  useEffect(() => {
    if (fgRef.current && filteredGraphData && filteredGraphData.nodes.length > 0) {
      const fg = fgRef.current;
      setupForceGraph(fg, filteredGraphData);
    }
  }, [filteredGraphData]);

  // Data fetching
  const fetchChannelTypes = async () => {
    if (!selectedChannel) return;
    try {
      const response = await getChannelTypes(selectedChannel);
      setEdgeTypes(response.data.edgeTypes);
      setNodeTypes(response.data.nodeTypes);
    } catch (err) {
      console.error("Error fetching channel types:", err);
      setError("Failed to fetch channel types");
    }
  };

  const fetchData = async () => {
    if (!selectedChannel) {
      setError("Please select a channel");
      return;
    }
    setLoading(true);
    setError(null);
    try {
      const response = await getChannelNetwork(selectedChannel);
      const processedData = processChannelNetworkData(response.data);
      if (processedData.nodes.length === 0 || processedData.links.length === 0) {
        setError("No network data available");
        setRawGraphData(null);
        return;
      }
      setRawGraphData(processedData);
    } catch (err) {
      setError("Failed to fetch network data");
      setRawGraphData(null);
    } finally {
      setLoading(false);
    }
  };

  // Graph setup helper
  const setupForceGraph = (fg: any, graphData: { nodes: ChannelNetworkNode[], links: ChannelNetworkLink[] }) => {
    // Clear existing forces
    fg.d3Force('link', null);
    fg.d3Force('charge', null);
    fg.d3Force('center', null);
    fg.d3Force('x', null);
    fg.d3Force('y', null);

    // Set up new forces
    fg.d3Force('link', d3.forceLink(graphData.links).id((d: any) => d.id).distance(65));
    fg.d3Force('charge', d3.forceManyBody().strength(-150));
    fg.d3Force('center', d3.forceCenter().strength(0.05));
    fg.d3Force('x', d3.forceX().strength(0.1));
    fg.d3Force('y', d3.forceY().strength(0.1));

    // Reheat simulation and zoom to fit
    fg.d3ReheatSimulation();
    setTimeout(() => {
      fg.zoomToFit(400, 1.2);
    }, 500);
  };

  // Node/Link handlers
  const handleNodeHover = useCallback((node: ChannelNetworkNode | null) => {
    highlightNodes.clear();
    highlightLinks.clear();
    if (node) {
      highlightNodes.add(node);
      const nodeNeighbors = neighborMap[node.id]?.neighbors || [];
      nodeNeighbors.forEach(neighborId => {
        const neighborNode = filteredGraphData?.nodes.find(n => n.id === neighborId);
        if (neighborNode) highlightNodes.add(neighborNode);
      });
      neighborMap[node.id]?.links.forEach(link => highlightLinks.add(link));
    }
    setHoverNode(node || null);
    setHighlightNodes(new Set(highlightNodes));
    setHighlightLinks(new Set(highlightLinks));
  }, [neighborMap, filteredGraphData]);

  const handleLinkHover = useCallback((link: ChannelNetworkLink | null) => {
    highlightNodes.clear();
    highlightLinks.clear();
    if (link) {
      highlightLinks.add(link);
      highlightNodes.add(link.source);
      highlightNodes.add(link.target);
    }
    setHighlightNodes(new Set(highlightNodes));
    setHighlightLinks(new Set(highlightLinks));
  }, []);

  // Render methods
  const renderLegend = () => (
    <Stack>
      <Text size="sm" w={500} mb="xs">Node Types</Text>
      {nodeTypes.map((type) => (
        <Group key={type} align="center" gap="xs">
          <Box style={{ 
            width: 12, 
            height: 12, 
            borderRadius: '50%', 
            backgroundColor: nodeTypeColors[type as keyof typeof nodeTypeColors] || '#808080' 
          }} />
          <Text size="xs">{type}</Text>
        </Group>
      ))}
      <Text size="sm" w={500} mt="md" mb="xs">Edge Types</Text>
      {edgeTypes.map((type) => (
        <Group key={type} align="center" gap="xs">
          <Box style={{ 
            width: 20, 
            height: 2, 
            backgroundColor: edgeTypeColors[type as keyof typeof edgeTypeColors] || '#D3D3D3' 
          }} />
          <Text size="xs">{type}</Text>
        </Group>
      ))}
    </Stack>
  );

  return (
    <Stack gap="xs">
      <Group gap={5}>
        {navItems.map((item, index) => (
          <React.Fragment key={item.title}>
            {index > 0 && index < 3 && <IconChevronRight size={14} color='#868e96'/>}
            {index > 2 && <Text size="sm" color="dimmed">|</Text>}
            {item.isExternal ? (
              <Text
                component="a"
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
                size="sm"
                c="#0982eb"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {item.title}
                <IconExternalLink size={14} style={{ marginLeft: 5 }} />
              </Text>
            ) : (
              <Text
                component={Link}
                to={item.href}
                size="sm"
                fw={index >= 2 && location.pathname === item.href ? 500 : 'normal'}
                td={index >= 2 && location.pathname === item.href ? 'underline' : 'none'}
                c={index < 2 ? 'dimmed' : '#0982eb'}
              >
                {item.title}
              </Text>
            )}
      </React.Fragment>
        ))}
      </Group>
        <Stack gap="xs">
      <Group align="flex-end" mb="md">
        <ChannelPicker value={selectedChannel} onChange={setSelectedChannel} />
        <NetworkFilter
            filterMode={filterMode}
            onFilterModeChange={setFilterMode}
            edgeTypes={edgeTypes}
            selectedEdgeTypes={selectedEdgeTypes}
            onEdgeTypesChange={setSelectedEdgeTypes}
            selectedAgeCategories={selectedAgeCategories}
            onAgeCategoriesChange={setSelectedAgeCategories}
          />
        <Box style={{ height: '36px', display: 'flex', alignItems: 'center' }}>
          <HoverCard width={280} shadow="md">
            <HoverCard.Target>
              <IconKey size={24} style={{ cursor: 'pointer' }} />
            </HoverCard.Target>
            <HoverCard.Dropdown>
              {renderLegend()}
            </HoverCard.Dropdown>
          </HoverCard>
        </Box>
      </Group>

      {loading ? (
        <SkeletonLoader count={1} height={600} radius="sm" />
      ) : (
        <Paper
        ref={paperRef}
        p="xs"
        shadow="xs"
        radius="sm"
        style={{ 
          backgroundColor: '#fafafa',
          overflow: 'hidden',
          position: 'relative' // For positioning the fullscreen button
        }}
      >
        {filteredGraphData ? (
          <>
            <Box
              style={{
                position: 'absolute',
                top: 10,
                right: 10,
                zIndex: 1000,
                cursor: 'pointer',
                backgroundColor: 'white',
                borderRadius: '4px',
                padding: '4px',
                boxShadow: '0 1px 3px rgba(0,0,0,0.1)'
              }}
              onClick={() => toggle()}
            >
              {fullscreen ? (
                <IconArrowsMinimize size={24} stroke={1.5} />
              ) : (
                <IconArrowsMaximize size={24} stroke={1.5} />
              )}
            </Box>
            <div 
              ref={containerRef}
              style={{ 
                width: '100%',
                height: fullscreen ? '100vh' : '600px',
                position: 'relative'
              }}
            >
              <ForceGraph2D
                ref={fgRef}
                graphData={filteredGraphData}
                nodeLabel={(node: ChannelNetworkNode) => `${node.title}<br />Type: ${node.node_type}`}
                nodeColor={(node: ChannelNetworkNode) => 
                  nodeTypeColors[node.node_type as keyof typeof nodeTypeColors] || '#808080'
                }
                linkColor={(link: ChannelNetworkLink) => 
                  edgeTypeColors[link.edge_type as keyof typeof edgeTypeColors] || '#D3D3D3'
                }
                linkWidth={(link: ChannelNetworkLink) => highlightLinks.has(link) ? 2 : 1}
                nodeRelSize={4}
                linkDirectionalParticles={2}
                linkDirectionalParticleWidth={2}
                linkDirectionalParticleSpeed={0.005}
                onNodeHover={handleNodeHover}
                onLinkHover={handleLinkHover}
                cooldownTime={5000}
                width={containerRef.current?.clientWidth}
                height={fullscreen ? window.innerHeight : 600}
              />
            </div>
          </>
        ) : (
          <Text>Please select a channel to view the network.</Text>
          )}
        </Paper>
      )}
    </Stack>
    </Stack>
  );
}