import React from 'react';
import {
  ComposedChart,
  Line,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { Text, Group } from '@mantine/core';
import { formatNumber, formatDate } from '../../../utils/formatter';
import { IconChartCovariate } from '@tabler/icons-react';

interface RelativeChartDataPoint {
  date: string;
  daysLive: number;
  views: number;
  bounds: [number, number];
}

interface VideoRelativeChartProps {
  data: RelativeChartDataPoint[];
  publishDate: string;
  contentType: string;
  videoTitle: string;
}

const getContentTypeColor = (contentType: string): string => {
  switch (contentType) {
    case 'Short': return '#FFA500';
    case 'Video': return '#FF0000';
    case 'Podcast': return '#9C27B0';
    case 'Live': return '#007BFF';
    case 'Premiere': return '#607D8B';
    case 'Music Video': return '#FF0000';
    case 'Course': return '#FF0000';
    default: return '#0d3fa6';
  }
};

const CustomTooltip: React.FC<any> = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}>
        <Text size="sm" fw={500}>Day {data.daysLive}</Text>
        <Text size="sm" fs="italic" c="dimmed">{formatDate(data.date)}</Text>
        <Text size="sm" fw={500}>Views: {formatNumber(data.views)}</Text>
        <Text size="sm">Low Performance: {formatNumber(data.bounds[0])}</Text>
        <Text size="sm">High Performance: {formatNumber(data.bounds[1])}</Text>
      </div>
    );
  }
  return null;
};

export const VideoRelativeChart: React.FC<VideoRelativeChartProps> = ({ data, publishDate, contentType, videoTitle }) => {
  const contentTypeColor = getContentTypeColor(contentType);

  return (
    <>
      <Group align="center" mb="sm">
        <IconChartCovariate size={20} />
        <Text size="md" fw={400} fs="italic">
          Expected Views for "{videoTitle.length > 50 ? videoTitle.substring(0, 47) + '[...]' : videoTitle}" 
        </Text>
      </Group>
      <ResponsiveContainer width="100%" height={600}>
        <ComposedChart
          data={data}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis 
            dataKey="daysLive"
            tickFormatter={(value) => `Day ${value}`}
          />
          <YAxis 
            tickFormatter={(value) => formatNumber(value)}
          />
          <Tooltip content={<CustomTooltip />} />
          <defs>
            <linearGradient id="boundsGradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#808080" stopOpacity={0.8}/>
              <stop offset="95%" stopColor="#808080" stopOpacity={0.2}/>
            </linearGradient>
          </defs>
          <Area
            type="monotone"
            dataKey="bounds"
            fill="url(#boundsGradient)"
            stroke="#808080"
            strokeWidth={0.1}
            fillOpacity={0.8}
            connectNulls
          />
          <Line
            type="monotone"
            dataKey="views"
            stroke={contentTypeColor}
            strokeWidth={3}
            dot={true}
            activeDot={{ r: 8 }}
            connectNulls
          />
        </ComposedChart>
      </ResponsiveContainer>
    </>
  );
};

export default VideoRelativeChart;