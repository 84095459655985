import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { CoreLayout } from '../core/CoreLayout';

const ProtectedRoute: React.FC = () => {
  const token = localStorage.getItem('token');

  if (!token) {
    return <Navigate to="/login" replace />;
  }

  return (
    <CoreLayout>
      <Outlet />
    </CoreLayout>
  );
};

export default ProtectedRoute;