import React, { useState, useEffect } from 'react';
import { Paper, Group, Text, Stack, Grid } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import { IconChevronRight, IconExternalLink } from '@tabler/icons-react';
import { ChannelPicker } from '../../components/ChannelPicker';
import { ContentTypePicker } from '../../components/ContentTypePicker';
import { VideoPicker } from '../../components/VideoPicker';
import { VideoInfoCard } from '../../components/VideoInfoCard';
import { PerformanceCard } from '../../components/PerformanceCard';
import DailyGraph from '../../components/VideoDailyGraph';
import { TotalChart } from '../../components/VideoTotalChart';
import { VideoRelativeChart } from '../../components/VideoRelativeChart';
import { SkeletonLoader } from '../../../core/SkeletonLoader';
import { 
  getChannelVideosPage, 
  getChannelVideosPagePerformance, 
  getVideoPerformanceDailyData, 
  getVideoPerformanceTotalData,
  getVideoPerformanceRelativeData,
  getVideoClassification,
  VideoClassification
} from '../../../../utils/api';
import { MetricPicker } from '../../components/VideoMetricPicker';
import { GraphTypePicker } from '../../components/VideoGraphTypePicker';

type MetricKey = 'views' | 'likes' | 'comments';
type GraphType = 'total' | 'daily' | 'relative';

interface VideoMetadata {
  id: string;
  title: string;
  publishDate: string;
  type: string;
  modelTopic: string;
  wikipediaTopic: string;
  videoLength: string;
  firstTrackedDate: string;
}

interface VideoPerformance {
  totalViews: number;
  totalLikes: number;
  totalComments: number;
  viewsChange: number | null;
  likesChange: number | null;
  commentsChange: number | null;
  conversationRate: number;
  likeRate: number;
  rankAll: number;
  rankType: number;
  lastUpdated: string;
}

const navItems = [
  { title: 'Channels', href: '/channels/channel' },
  { title: 'Videos', href: '/channels/video/video' },
  { title: 'Video', href: '/channels/video/video' },
  { title: 'Compare Videos', href: '/channels/video/video-comparison' },
  { title: 'Video Analysis', href: '/channels/video/video-analysis' },
  { title: 'Notes', href: 'https://brick-river-8a5.notion.site/Channels-Video-11f6777fc955803c8f47fe7a3a977b46', isExternal: true },
];

export function Video() {
  const [selectedChannel, setSelectedChannel] = useState<string | null>(null);
  const [selectedContentType, setSelectedContentType] = useState<string>('All');
  const [selectedVideo, setSelectedVideo] = useState<string | null>(null);
  const [metadata, setMetadata] = useState<VideoMetadata | null>(null);
  const [performance, setPerformance] = useState<VideoPerformance | null>(null);
  const [dailyData, setDailyData] = useState<any>(null);
  const [totalData, setTotalData] = useState<any>(null);
  const [relativeData, setRelativeData] = useState<any>(null);
  const [selectedMetric, setSelectedMetric] = useState<MetricKey>('views');
  const [selectedGraphType, setSelectedGraphType] = useState<GraphType>('relative');
  const [classification, setClassification] = useState<VideoClassification | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();

  useEffect(() => {
    if (selectedChannel && selectedVideo) {
      fetchVideoData();
    }
  }, [selectedChannel, selectedVideo]);

  const fetchVideoData = async () => {
    if (!selectedChannel || !selectedVideo) return;

    setLoading(true);
    setError(null);

    try {
      const [
        metadataResponse, 
        performanceResponse, 
        dailyDataResponse, 
        totalDataResponse,
        relativeDataResponse,
        classificationResponse
      ] = await Promise.all([
        getChannelVideosPage(selectedChannel, selectedVideo),
        getChannelVideosPagePerformance(selectedChannel, selectedVideo),
        getVideoPerformanceDailyData(selectedChannel, selectedVideo),
        getVideoPerformanceTotalData(selectedChannel, selectedVideo),
        getVideoPerformanceRelativeData(selectedChannel, selectedVideo),
        getVideoClassification(selectedChannel, selectedVideo).catch(err => {
          // Handle 404 gracefully - it just means no classification available
          if (err.response?.status === 404) {
            return { data: null };
          }
          throw err; // Re-throw other errors
        })
      ]);

      setMetadata(metadataResponse.data);
      setPerformance(performanceResponse.data);
      setDailyData(dailyDataResponse.data);
      setTotalData(totalDataResponse.data);
      setRelativeData(relativeDataResponse.data);
      setClassification(classificationResponse.data);
    } catch (err) {
      setError('Please select a video from this channel.');
      console.error('Error fetching video data:', err);
    } finally {
      setLoading(false);
    }
  };

  const renderChart = () => {
    if (!metadata) return null;
    
    switch (selectedGraphType) {
      case 'total':
        return (
          <TotalChart
            chartData={totalData.chartData}
            metric={selectedMetric}
            publishDate={totalData.publishDate}
            firstTrackedDate={totalData.firstTrackedDate}
            lastTrackedDate={totalData.lastTrackedDate}
            updatedAtDate={totalData.updatedAtDate}
            contentType={metadata.type}
            videoTitle={metadata.title}
          />
        );
      case 'daily':
        return (
          <DailyGraph
            data={dailyData.performanceData}
            metric={selectedMetric}
            publishDate={dailyData.publishDate}
            firstTrackedDate={dailyData.firstTrackedDate}
            lastUpdatedDate={dailyData.lastUpdatedDate}
            gaps={dailyData.gaps}
            isDay2Scenario={dailyData.isDay2Scenario}
            contentType={metadata.type}
            videoTitle={metadata.title}
          />
        );
      case 'relative':
        return (
          <VideoRelativeChart
            data={relativeData}
            publishDate={relativeData?.publishDate || ''}
            contentType={metadata.type}
            videoTitle={metadata.title}
          />
        );
      default:
        return <Text>Invalid chart type selected.</Text>;
    }
  };

  return (
    <Stack gap="md">
      <Group gap={5}>
        {navItems.map((item, index) => (
          <React.Fragment key={item.title}>
            {index > 0 && index < 3 && <IconChevronRight size={14} color='#868e96'/>}
            {index > 2 && <Text size="sm" color="dimmed">|</Text>}
            {item.isExternal ? (
              <Text
                component="a"
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
                size="sm"
                c="#0982eb"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {item.title}
                <IconExternalLink size={14} style={{ marginLeft: 5 }} />
              </Text>
            ) : (
              <Text
                component={Link}
                to={item.href}
                size="sm"
                fw={index >= 2 && location.pathname === item.href ? 500 : 'normal'}
                td={index >= 2 && location.pathname === item.href ? 'underline' : 'none'}
                c={index < 2 ? 'dimmed' : '#0982eb'}
              >
                {item.title}
              </Text>
            )}
      </React.Fragment>
        ))}
      </Group>
        <Group gap="apart" mb="md">
          <ChannelPicker value={selectedChannel} onChange={setSelectedChannel} />
          <ContentTypePicker
            channelId={selectedChannel}
            value={selectedContentType}
            onChange={(value) => setSelectedContentType(value || 'All')}
          />
          <VideoPicker
            channelId={selectedChannel}
            contentType={selectedContentType}
            onChange={setSelectedVideo}
          />
        </Group>
        {loading ? (
          <SkeletonLoader count={3} height={200} />
        ) : error ? (
          <Text color="red" mt="md">{error}</Text>
        ) : metadata && performance ? (
          <Grid>
            <Grid.Col span={4}>
              <PerformanceCard
                performance={performance}
                contentType={selectedContentType}
                lastUpdated={performance.lastUpdated}
                isOldWithLowViews={dailyData?.gaps.some((gap: any) => gap.type === 'post-tracking')}
                classification={classification}
              />
            </Grid.Col>
            <Grid.Col span={8}>
              <VideoInfoCard
                metadata={metadata}
                firstTrackedDate={metadata.firstTrackedDate}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <Stack gap="md">
              <Paper
                p="md" 
                radius="md" 
                withBorder
                style={{ 
                      transition: 'box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out',
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.1)';
                      e.currentTarget.style.transform = 'translateY(-5px)';
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.boxShadow = '';
                      e.currentTarget.style.transform = '';
                    }}
                  >
                <Group gap="apart" mb="md">
                  <GraphTypePicker
                    value={selectedGraphType}
                    onChange={setSelectedGraphType}
                  />
                  {selectedGraphType === 'daily' && (
                    <MetricPicker
                      value={selectedMetric}
                      onChange={setSelectedMetric}
                    />
                  )}
                </Group>
                {renderChart()}
              </Paper>
              </Stack>
            </Grid.Col>
          </Grid>
        ) : null}
    </Stack>
  );
}