import React from 'react';
import { Box, Text, Stack, Group } from '@mantine/core';
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from 'recharts';
import { formatNumber, formatDate } from '../../../utils/formatter';
import { VideoTitleData } from '../../../utils/api';
import { ContentTypeBadge } from './ContentTypeBadge';

interface NormalizedViewsData {
  date: string;
  baseline: number;
  recent: number;
  total: number;
  videoIds: string[];
}

interface NormalizedViewsChartProps {
  data: NormalizedViewsData[];
  videoTitles: Record<string, VideoTitleData>;
}

const NormalizedViewsChart: React.FC<NormalizedViewsChartProps> = ({ data, videoTitles }) => {
  // Calculate ticks for every 7 days
  const xAxisTicks = React.useMemo(() => {
    return data
      .map((item, index) => index % 7 === 0 ? item.date : null)
      .filter((date): date is string => date !== null);
  }, [data]);

  const CustomizedAxisTick = ({ x, y, payload }: any) => (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-35)"
        style={{ fontSize: '12px' }}
      >
        {formatDate(payload.value)}
      </text>
    </g>
  );

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const dayData = data.find(d => d.date === label);
      const recentVideos = dayData?.videoIds || [];

      return (
        <Box style={{
          backgroundColor: 'white',
          padding: '10px',
          border: '1px solid #ccc',
          borderRadius: '4px'
        }}>
          <Stack gap="xs">
            <Text size="sm" fw={500}>{formatDate(label)}</Text>
            <Text size="sm">Total Views: {formatNumber(payload[0].value + (payload[1]?.value || 0))}</Text>
            <Text size="sm">Baseline Views: {formatNumber(payload[0].value)}</Text>
            {recentVideos.length > 0 && (
              <>
                <Text size="sm">Recent Video Views: {formatNumber(payload[1]?.value || 0)}</Text>
                <Text size="sm" fw={500}>Recent Videos:</Text>
                {recentVideos.map(videoId => {
                  const video = videoTitles[videoId];
                  if (!video) return null;
                  return (
                    <Box key={videoId} pl="xs">
                      <Group gap="xs" align="center">
                        <ContentTypeBadge type={video.type} size="xs" />
                        <Text size="xs" lineClamp={1}>{video.title}</Text>
                      </Group>
                    </Box>
                  );
                })}
              </>
            )}
          </Stack>
        </Box>
      );
    }
    return null;
  };

  return (
    <ResponsiveContainer width="100%" height={600}>
      <AreaChart
        data={data}
        margin={{ top: 20, right: 30, left: 60, bottom: 60 }}
      >
        <defs>
          <linearGradient id="baselineGradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#FF0000" stopOpacity={0.8}/>
            <stop offset="95%" stopColor="#FF0000" stopOpacity={0.1}/>
          </linearGradient>
          <linearGradient id="recentGradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#0d3fa6" stopOpacity={0.8}/>
            <stop offset="95%" stopColor="#0d3fa6" stopOpacity={0.1}/>
          </linearGradient>
        </defs>
        
        <CartesianGrid strokeDasharray="3 3" />
        
        <XAxis
          dataKey="date"
          tick={<CustomizedAxisTick />}
          ticks={xAxisTicks}
          interval={0}
          padding={{ left: 20, right: 20 }}
          height={60}
        />
        
        <YAxis
          tickFormatter={formatNumber}
          width={60}
        />
        
        <Tooltip content={<CustomTooltip />} />
        
        <Area
          type="linear"
          dataKey="baseline"
          stackId="1"
          stroke="#FF0000"
          strokeWidth={2}
          fillOpacity={1}
          fill="url(#baselineGradient)"
          isAnimationActive={true}
          animationDuration={2000}
          activeDot={{ r: 8 }}
        />
        
        <Area
          type="linear"
          dataKey="recent"
          stackId="1"
          stroke="#0d3fa6"
          strokeWidth={4}
          fillOpacity={1}
          fill="url(#recentGradient)"
          isAnimationActive={true}
          animationDuration={2000}
          activeDot={{ r: 8 }}
          dot={true}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default NormalizedViewsChart;