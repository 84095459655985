import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { TextInput, PasswordInput, Button, Box, Text, Container, Stack, Blockquote } from '@mantine/core';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { IconInfoCircle } from '@tabler/icons-react';

interface Node extends d3.SimulationNodeDatum {
  id: number;
  group: number;
}

interface Link {
  source: number | Node;
  target: number | Node;
}

interface ChartType {
  update: (data: { nodes: Node[], links: Link[] }) => void;
}

const Login: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const svgRef = useRef<SVGSVGElement>(null);
  const [chart, setChart] = useState<ChartType | null>(null);

  useEffect(() => {
    if (svgRef.current) {
      const newChart = createForceDirectedGraph();
      setChart(newChart);
    }
  }, []);

  useEffect(() => {
    if (chart) {
      const data = generateData();
      chart.update(data);
    }
  }, [chart]);

  const generateData = () => {
    const nodes: Node[] = Array.from({ length: 50 }, (_, i) => ({
      id: i,
      group: Math.random() < 0.33 ? 0 : 1 // 33% red, 66% white
    }));
    const links: Link[] = Array.from({ length: 100 }, () => ({
      source: Math.floor(Math.random() * nodes.length),
      target: Math.floor(Math.random() * nodes.length)
    }));
    return { nodes, links };
  };

  const createForceDirectedGraph = (): ChartType => {
    const svg = d3.select(svgRef.current)
        .attr("width", "800px")
        .attr("height", "800px")
        .attr("style", "max-width: 100%; height: auto; background-color: #1a1b1e;");

    const g = svg.append("g");

    const zoom = d3.zoom()
        .scaleExtent([0.1, 4])
        .on("zoom", (event) => {
            g.attr("transform", event.transform);
        });

    svg.call(zoom as any);

    const simulation = d3.forceSimulation<Node>()
        .force("charge", d3.forceManyBody().strength(-30))
        .force("link", d3.forceLink<Node, Link>().id(d => d.id).distance(30))
        .force("x", d3.forceX())
        .force("y", d3.forceY());

    let link = g.append("g")
        .attr("stroke", "#ffffff")
        .attr("stroke-opacity", 0.8)
      .selectAll<SVGLineElement, Link>("line");

    let node = g.append("g")
      .selectAll<SVGCircleElement, Node>("circle");

    const ticked = () => {
      node.attr("cx", d => d.x ?? 0)
          .attr("cy", d => d.y ?? 0);

      link.attr("x1", d => (d.source as Node).x ?? 0)
          .attr("y1", d => (d.source as Node).y ?? 0)
          .attr("x2", d => (d.target as Node).x ?? 0)
          .attr("y2", d => (d.target as Node).y ?? 0);
    }

    simulation.on("tick", ticked);

    const drag = (simulation: d3.Simulation<Node, Link>) => {
      function dragstarted(event: d3.D3DragEvent<SVGCircleElement, Node, Node>) {
        if (!event.active) simulation.alphaTarget(0.3).restart();
        event.subject.fx = event.subject.x;
        event.subject.fy = event.subject.y;
      }
      
      function dragged(event: d3.D3DragEvent<SVGCircleElement, Node, Node>) {
        event.subject.fx = event.x;
        event.subject.fy = event.y;
      }
      
      function dragended(event: d3.D3DragEvent<SVGCircleElement, Node, Node>) {
        if (!event.active) simulation.alphaTarget(0);
        event.subject.fx = null;
        event.subject.fy = null;
      }
      
      return d3.drag<SVGCircleElement, Node>()
          .on("start", dragstarted)
          .on("drag", dragged)
          .on("end", dragended);
    }

    const update = ({nodes, links}: {nodes: Node[], links: Link[]}) => {
      const width = svgRef.current?.clientWidth || 500;
      const height = svgRef.current?.clientHeight || 500;

      simulation.force("x", d3.forceX(width / 2))
                .force("y", d3.forceY(height / 2));

      const old = new Map(node.data().map(d => [d.id, d]));
      nodes = nodes.map(d => Object.assign(old.get(d.id) || {}, d));
      links = links.map(d => Object.assign({}, d));

      node = node
        .data(nodes, d => d.id)
        .join(enter => enter.append("circle")
          .attr("r", 5)
          .call(drag(simulation) as any)
        )
        .attr("fill", d => d.group === 0 ? "#FF0000" : "#FFFFFF");

      link = link
        .data(links)
        .join("line");

      simulation.nodes(nodes);
      simulation.force<d3.ForceLink<Node, Link>>("link")?.links(links);
      simulation.alpha(1).restart();
    }

    return { update };
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/auth/login', { username, password });
      localStorage.setItem('token', response.data.token);
      navigate('/docs/new-one');
    } catch (error) {
      console.error('Login failed:', error);
      // Handle login error (show message to user)
    }
  };

  return (
    <Box style={{ display: 'flex', height: '100vh', margin: 0 }}>
      <Box style={{ 
        flex: 1,
        backgroundColor: '#1a1b1e', 
        padding: '2rem', 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center'
      }}>
        <div style={{ width: '100%', height: 'calc(100% - 200px)', position: 'relative' }}>
          <svg ref={svgRef} style={{ 
            width: '100%', 
            height: '100%', 
            position: 'absolute', 
            top: 0, 
            left: 0,
          }}></svg>
        </div>
      </Box>
      <Box style={{ 
        flex: 1,
        padding: '2rem',
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'space-between'
      }}>
        <Stack justify="center" style={{ flex: 1 }}>
          <Container size="xs">
            <Text size="xl" ta="center" mb="xl">Welcome to sven.dog</Text>
            <form onSubmit={handleSubmit}>
              <TextInput
                required
                label="Username"
                value={username}
                onChange={(event) => setUsername(event.currentTarget.value)}
                mb="md"
              />
              <PasswordInput
                required
                label="Password"
                value={password}
                onChange={(event) => setPassword(event.currentTarget.value)}
                mb="md"
              />
              <Button type="submit" fullWidth mt="md" color="#0982eb">
                Log in
              </Button>
            </form>
            <Text size="sm" mt="md" ta="center">
              Access via invite only. <a href="https://forms.gle/BX4qyAuTPjPDJnWG6" target="_blank" rel="noopener noreferrer">Request your invite here</a>.
            </Text>
            <Blockquote
              color="blue"
              icon={<IconInfoCircle />}
              mt="xl"
            >
              Welcome to sven.dog. The site is currently in beta (v1.0.4). Breakages may happen from time to time. The site is *not* mobile-friendly, and is best on a laptop.
            </Blockquote>
          </Container>
        </Stack>
        <Text size="xs" c="dimmed" ta="center" fs="italic">sven.dog/analytics © last.works 2024</Text>
      </Box>
    </Box>
  );
};

export default Login;