import React, { useState, useEffect, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, GridReadyEvent } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { getChannelVideosGridData } from '../../../utils/api';
import { formatNumber, formatDate, formatSeconds } from '../../../utils/formatter';

interface ChannelDataGridProps {
  channelId: string;
}

interface VideoData {
  title: string;
  publishDate: string;
  videoType: string;
  wikipediaTopicsPretty: string;
  topic_name: string;
  videoLength: number;
  lastUpdated: string;
  views: number;
  comments: number;
  likes: number;
  change24h: number;
}

export const ChannelDataGrid: React.FC<ChannelDataGridProps> = ({ channelId }) => {
  const [rowData, setRowData] = useState<VideoData[]>([]);
  const [loading, setLoading] = useState(false);

  const columnDefs: ColDef[] = useMemo(() => [
    { field: 'title', headerName: 'Title', flex: 2 },
    { field: 'publishDate', headerName: 'Publish Date', flex: 1, valueFormatter: (params) => formatDate(params.value) },
    { field: 'videoType', headerName: 'Type', flex: 1 },
    { field: 'wikipediaTopicsPretty', headerName: 'YouTube\'s Topics', flex: 2 },
    { field: 'topic_name', headerName: 'Model Topic', flex: 1 },
    { field: 'videoLength', headerName: 'Video Length', flex: 1, valueFormatter: (params) => formatSeconds(params.value), type: 'numericColumn'  },
    { field: 'lastUpdated', headerName: 'Last Updated', flex: 1, valueFormatter: (params) => formatDate(params.value) },
    { field: 'views', headerName: 'Views', flex: 1, valueFormatter: (params) => formatNumber(params.value), sort: 'desc', type: 'numericColumn' },
    { field: 'comments', headerName: 'Comments', flex: 1, valueFormatter: (params) => formatNumber(params.value), type: 'numericColumn' },
    { field: 'likes', headerName: 'Likes', flex: 1, valueFormatter: (params) => formatNumber(params.value), type: 'numericColumn' },
    { field: 'change24h', headerName: '24h Change', flex: 1, valueFormatter: (params) => formatNumber(params.value), type: 'numericColumn' },
  ], []);

  const defaultColDef = useMemo(() => ({
    sortable: true,
    resizable: true,
  }), []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Request a large number of rows to effectively get all data
        const response = await getChannelVideosGridData(channelId, 0, 10000, [{ colId: 'views', sort: 'desc' }]);
        setRowData(response.data.rows);
      } catch (error) {
        console.error('Error fetching channel videos data:', error);
      } finally {
        setLoading(false);
      }
    };

    if (channelId) {
      fetchData();
    }
  }, [channelId]);

  const onGridReady = (params: GridReadyEvent) => {
    params.api.sizeColumnsToFit();
  };

  return (
    <div className="ag-theme-quartz" style={{ height: 600, width: '100%' }}>
      <AgGridReact
        columnDefs={columnDefs}
        rowData={rowData}
        defaultColDef={defaultColDef}
        onGridReady={onGridReady}
        pagination={true}
        paginationPageSize={50}
      />
    </div>
  );
};
