import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine } from 'recharts';
import { Text } from '@mantine/core';
import { formatNumber, formatDate } from '../../../utils/formatter';

interface DailyChangeData {
  date: string;
  [key: string]: number | string;
}

interface DailyChangeBarChartProps {
  data: DailyChangeData[];
  contentTypes: string[];
}

const contentTypeColors: Record<string, string> = {
  'Short': '#FFA500',
  'Video': '#FF0000',
  'Podcast': '#9C27B0',
  'Live': '#007BFF',
  'Premiere': '#607D8B',
  'Music Video': '#FF0000',
  'Course': '#FF0000',
  'untracked': '#C8C8C8',
};

const CustomTooltip: React.FC<any> = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}>
        <Text size="sm" c="dimmed" fs="italic">{`Date: ${formatDate(label)}`}</Text>
        {payload.map((entry: any, index: number) => (
          <Text key={index} size="sm" style={{ color: entry.color }}>
            {`${entry.name}: ${formatNumber(entry.value)}`}
          </Text>
        ))}
      </div>
    );
  }
  return null;
};

export const DailyChangeBarChart: React.FC<DailyChangeBarChartProps> = ({ data, contentTypes }) => {
  const getXAxisTicks = () => {
    if (!data || data.length === 0) return [];
    const firstDate = data[0]?.date;
    const middleDate = data[Math.floor(data.length / 2)]?.date;
    const lastDate = data[data.length - 1]?.date;
    return [firstDate, middleDate, lastDate].filter(Boolean);
  };

  return (
    <ResponsiveContainer width="100%" height={600}>
      <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis 
          dataKey="date" 
          tickFormatter={formatDate}
          ticks={getXAxisTicks()}
        />
        <YAxis tickFormatter={(value) => formatNumber(value)} />
        <Tooltip content={<CustomTooltip />} />
        <ReferenceLine y={0} stroke="#000" />
        {contentTypes.map((type) => (
          <Bar 
            key={type} 
            dataKey={type} 
            fill={contentTypeColors[type] || '#808080'}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};