export const formatNumber = (num: number | undefined | null): string => {
  if (num === undefined || num === null) return 'N/A';
  
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(3) + 'B';
  } else if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M';
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'K';
  } else {
    return num.toString();
  }
};

export const formatDate = (dateString: string | undefined | null): string => {
  if (!dateString) return 'N/A';
  return new Date(dateString).toLocaleDateString();
};
  
export const formatPercentage = (num: number | undefined | null): string => {
  if (num === undefined || num === null) return 'N/A';
  return num.toFixed(2) + '%';
};
  
  export const formatRate = (num: number): string => {
    return num.toFixed(2);
  };
  
export const getMetricLabel = (metric: string | null | undefined): string => {
  if (!metric) return 'Value';
  const metricMap: Record<string, string> = {
    subscribers: 'Subscribers',
    viewsAll: 'Total Views',
    videosAll: 'Total Videos',
    viewsShort: 'Views (Short)',
    viewsVideo: 'Views (Video)',
    viewsPodcast: 'Views (Podcast)',
    videosShort: 'Library Size (Short)',
    videosVideo: 'Library Size (Video)',
    videosPodcast: 'Library Size (Podcast)',
  };
  return metricMap[metric] || 'Value';
};

export const getVideoMetricLabel = (metric: string): string => {
  const labels: { [key: string]: string } = {
    views: 'Views',
    likes: 'Likes',
    comments: 'Comments'
  };
  return labels[metric] || metric;
};

export function formatSeconds(seconds: number): string {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const parts = [
    hours.toString().padStart(2, '0'),
    minutes.toString().padStart(2, '0'),
    remainingSeconds.toString().padStart(2, '0')
  ];

  return parts.join(':');
}