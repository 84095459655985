import React, { useState, useCallback, useRef, useEffect } from 'react';
import { ForceGraph2D } from 'react-force-graph';
import * as d3 from 'd3';
import { Paper, Group, Text, Stack } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import { IconChevronRight, IconExternalLink } from '@tabler/icons-react';
import { SkeletonLoader } from '../../core/SkeletonLoader';
import { 
  processNetworkData, 
  createNodeNeighborMap, 
  SourceSubNode, 
  SourceSubLink, 
  NodeNeighborMap 
} from '../../../utils/sourceSubUtils';
import { getSourceSubNetwork, getSourceSubValueComparison, SourceSubValueComparisonData } from '../../../utils/api';
import { formatDate } from '../../../utils/formatter';
import { ViewTypePicker, ViewType } from '../components/SourceSubViewPicker';
import { ValueComparisonChart } from '../components/SourceSubValue';

const CHANNEL_ID = 'UCXl4i9dYBrFOabk0xGmbkRA';

const contentTypeColors: Record<string, string> = {
  'Video': '#FF0000',
  'Podcast': '#9C27B0',
  'Short': '#FFA500'
};

const navItems = [
  { title: 'Experiments', href: '/experiments/model-explorer' },
  { title: 'Model Explorer', href: '/experiments/model-explorer' },
  { title: 'Source / Subsidiary', href: '/experiments/source-subsidiary' },
  // { title: 'Analyses', href: '/experiments/analyses' },
  // { title: 'Videos -> Views', href: '/experiments/flow' },
  // { title: 'Thumbnail Scores', href: '/experiments/thumbnail
  { title: 'Notes', href: 'https://brick-river-8a5.notion.site/Experimental-Analyses-12c6777fc95580ed9a51c5a6948c208b', isExternal: true },
];

export function SourceSubNetwork() {
  const [graphData, setGraphData] = useState<{ nodes: SourceSubNode[], links: SourceSubLink[] } | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [highlightNodes, setHighlightNodes] = useState(new Set());
  const [highlightLinks, setHighlightLinks] = useState(new Set());
  const [hoverNode, setHoverNode] = useState<SourceSubNode | null>(null);
  const [neighborMap, setNeighborMap] = useState<NodeNeighborMap>({});

  const [selectedView, setSelectedView] = useState<ViewType>('network');
  const [valueData, setValueData] = useState<SourceSubValueComparisonData[] | null>(null);

  const fgRef = useRef<any>();
  const containerRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  useEffect(() => {
    fetchData();
  }, [selectedView]);

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      if (selectedView === 'network') {
        const { data } = await getSourceSubNetwork(CHANNEL_ID);
        const processedData = processNetworkData(data);
        setGraphData(processedData);
        const newNeighborMap = createNodeNeighborMap(processedData.nodes, processedData.links);
        setNeighborMap(newNeighborMap);
      } else {
        const { data } = await getSourceSubValueComparison(CHANNEL_ID);
        setValueData(data);
      }
    } catch (err) {
      console.error('Error fetching data:', err);
      setError(err instanceof Error ? err.message : 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (fgRef.current && graphData && graphData.nodes.length > 0 && graphData.links.length > 0) {
      const fg = fgRef.current;
      try {
        // Clear existing forces
        fg.d3Force('link', null);
        fg.d3Force('charge', null);
        fg.d3Force('center', null);
        fg.d3Force('x', null);
        fg.d3Force('y', null);

        // Set up new forces
        fg.d3Force('link', d3.forceLink(graphData.links).id((d: any) => d.id).distance(5));
        fg.d3Force('charge', d3.forceManyBody().strength(-75));
        fg.d3Force('center', d3.forceCenter());
        fg.d3Force('x', d3.forceX().strength(0.2));
        fg.d3Force('y', d3.forceY().strength(0.2));

        fg.d3ReheatSimulation();

        setTimeout(() => {
          const distRatio = 1;
          fg.zoomToFit(400, distRatio);
        }, 500);

        fg.zoom().on('zoom', () => {
          const { x, y, k } = d3.zoomTransform(fg.zoom().current);
          fg.zoom().transform(fg.zoom().current, d3.zoomIdentity.translate(x, y).scale(k));
        });
      } catch (error) {
        console.error("Error setting up force simulation:", error);
      }
    }
  }, [graphData]);

  const handleNodeHover = useCallback((node: SourceSubNode | null) => {
    highlightNodes.clear();
    highlightLinks.clear();
    if (node) {
      highlightNodes.add(node);
      const nodeNeighbors = neighborMap[node.id]?.neighbors || [];
      nodeNeighbors.forEach(neighborId => {
        const neighborNode = graphData?.nodes.find(n => n.id === neighborId);
        if (neighborNode) highlightNodes.add(neighborNode);
      });
      neighborMap[node.id]?.links.forEach(link => highlightLinks.add(link));
    }

    setHoverNode(node || null);
    updateHighlight();
  }, [neighborMap, graphData]);

  const getNodeColor = useCallback((node: SourceSubNode) => {
    return contentTypeColors[node.type] || '#808080';
  }, []);

  const getNodeCanvasObject = useCallback((node: SourceSubNode, ctx: CanvasRenderingContext2D, globalScale: number) => {
    const nodeColor = getNodeColor(node);
    const size = 3;
    const nodeSize = size * 1.5;

    ctx.beginPath();
    ctx.arc(node.x || 0, node.y || 0, nodeSize, 0, 2 * Math.PI, false);
    ctx.fillStyle = nodeColor;
    ctx.fill();
    
    ctx.strokeStyle = '#2a3345';
    ctx.lineWidth = 1.5 / globalScale;
    ctx.stroke();
  }, [getNodeColor]);

  const getNodeLabel = useCallback((node: SourceSubNode) => {
    return [
      `${node.title}`,
      `${node.type}`,
      `${node.sourcesubtype}`,
      `Published: ${formatDate(node.publish_date.value)}`
    ].join('<br />');
  }, []);

  const handleLinkHover = useCallback((link: SourceSubLink | null) => {
    highlightNodes.clear();
    highlightLinks.clear();

    if (link) {
      highlightLinks.add(link);
      highlightNodes.add(link.source);
      highlightNodes.add(link.target);
    }

    updateHighlight();
  }, []);

  const updateHighlight = () => {
    setHighlightNodes(new Set(highlightNodes));
    setHighlightLinks(new Set(highlightLinks));
  };

  return (
    <Stack gap="xs">
      <Group gap={5}>
        {navItems.map((item, index) => (
          <React.Fragment key={item.title}>
            {index > 0 && index < 2 && <IconChevronRight size={14} color='#868e96'/>}
            {index > 1 && <Text size="sm" color="dimmed">|</Text>}
            {item.isExternal ? (
              <Text
                component="a"
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
                size="sm"
                c="#0982eb"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {item.title}
                <IconExternalLink size={14} style={{ marginLeft: 5 }} />
              </Text>
            ) : (
              <Text
                component={Link}
                to={item.href}
                size="sm"
                fw={index >= 1 && location.pathname === item.href ? 500 : 'normal'}
                td={index >= 1 && location.pathname === item.href ? 'underline' : 'none'}
                c={index < 1 ? 'dimmed' : '#0982eb'}
              >
                {item.title}
              </Text>
            )}
          </React.Fragment>
        ))}
      </Group>

    {/* View Type Picker outside of any Paper component */}
    <Group mb="md">
      <ViewTypePicker 
        value={selectedView}
        onChange={setSelectedView}
      />
    </Group>

    {loading ? (
      <SkeletonLoader count={1} height={600} radius="sm" />
    ) : error ? (
      <Text color="red">{error}</Text>
    ) : selectedView === 'network' && graphData ? (
      // Dark background paper only for network visualization
      <Paper p="xs" shadow="xs" radius="sm" style={{ backgroundColor: '#2a3345' }}>
        <div ref={containerRef} style={{ width: '100%', height: '600px', position: 'relative' }}>
          <ForceGraph2D
            ref={fgRef}
            graphData={graphData}
            nodeLabel={getNodeLabel}
            nodeCanvasObject={getNodeCanvasObject}
            nodeCanvasObjectMode={() => 'replace'}
            nodeRelSize={4}
            linkColor={() => '#fafafa'}
            linkWidth={link => highlightLinks.has(link) ? 2 : 1}
            linkDirectionalParticles={2}
            linkDirectionalParticleWidth={2}
            linkDirectionalParticleSpeed={0.005}
            onNodeHover={handleNodeHover}
            onLinkHover={handleLinkHover}
            d3AlphaDecay={0.02}
            d3VelocityDecay={0.3}
            cooldownTicks={5000}
            width={containerRef.current?.clientWidth}
            height={600}
          />
        </div>
      </Paper>
    ) : selectedView === 'value' && valueData ? (
      // White background paper for recharts visualization
      <Paper 
        p="md" 
        radius="md" 
        withBorder
        style={{ 
          transition: 'box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out',
        }}
        onMouseEnter={(e) => {
          e.currentTarget.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.1)';
          e.currentTarget.style.transform = 'translateY(-5px)';
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.boxShadow = '';
          e.currentTarget.style.transform = '';
        }}
      >
        <ValueComparisonChart data={valueData} />
      </Paper>
    ) : (
      <Text color="white">No data available</Text>
    )}
  </Stack>
  );
}