import React from 'react';
import { ResponsiveContainer, ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { Text } from '@mantine/core';
import { formatNumber, formatDate } from '../../../utils/formatter';
import { SourceSubValueComparisonData } from '../../../utils/api';

interface ValueComparisonChartProps {
  data: SourceSubValueComparisonData[];
}

interface ChartPoint {
  index: number;  // Use numeric index for X position
  title: string;  // Keep title for display only
  publishDate: string;
  views: number;
  type: 'source' | 'total';
  subsidiaryCount: number;
  fill: string;
}

interface CustomTooltipProps {
  active?: boolean;
  payload?: any[];
  originalData: SourceSubValueComparisonData[];
}

const CustomTooltip = ({ active, payload, originalData }: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    const point = payload[0].payload;
    const baseData = originalData.find(d => d.title === point.title);
    if (!baseData) return null;

    return (
      <div style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}>
        <Text size="sm" fw={500}>{point.title}</Text>
        <Text size="sm">Published: {formatDate(point.publishDate)}</Text>
        <Text size="sm">Source Views: {formatNumber(baseData.sourceViews)}</Text>
        <Text size="sm">Total Views: {formatNumber(baseData.totalViews)}</Text>
        <Text size="sm">Subsidiary Videos: {formatNumber(baseData.subsidiaryCount)}</Text>
      </div>
    );
  }
  return null;
};

export function ValueComparisonChart({ data }: ValueComparisonChartProps) {
  // Sort data first
  const sortedOriginalData = [...data].sort(
    (a, b) => new Date(a.publishDate).getTime() - new Date(b.publishDate).getTime()
  );

  // Create chart data with indexed X positions
  const chartData: ChartPoint[] = sortedOriginalData.flatMap((item, index) => [
    {
      index,  // Use same index for both points
      title: item.title,
      publishDate: item.publishDate,
      views: item.sourceViews,
      type: 'source' as const,
      subsidiaryCount: item.subsidiaryCount,
      fill: '#9C27B0'
    },
    {
      index,  // Use same index for both points
      title: item.title,
      publishDate: item.publishDate,
      views: item.totalViews,
      type: 'total' as const,
      subsidiaryCount: item.subsidiaryCount,
      fill: '#0982eb'
    }
  ]);

  return (
    <ResponsiveContainer width="100%" height={600}>
      <ScatterChart margin={{ top: 20, right: 30, left: 60, bottom: 60 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis 
          dataKey="index"
          type="number"
          ticks={[...Array(sortedOriginalData.length)].map((_, i) => i)}  // Create ticks for each data point
          interval={0}  // Show all ticks
          domain={[0, sortedOriginalData.length - 1]}
          tick={({ x, y, payload }) => {
            const title = sortedOriginalData[payload.value].title;
            return (
              <g transform={`translate(${x},${y})`}>
                <text
                  x={0}
                  y={0}
                  dy={16}
                  textAnchor="end"
                  fill="#666"
                  transform="rotate(-35)"
                  fontSize={12}
                >
                  {title.substring(0, 20)}
                  {title.length > 20 ? '...' : ''}
                </text>
              </g>
            );
          }}
          padding={{ left: 10, right: 10 }}
          height={100}
        />
        <YAxis 
          type="number"
          scale="log"
          domain={['auto', 'auto']}
          tickFormatter={(value) => formatNumber(value)}
          label={{ value: "Views (note Log scale)", angle: -90, position: 'insideLeft', offset: -20 }}
        />
        <Tooltip content={(props) => <CustomTooltip {...props} originalData={sortedOriginalData} />} />
        <Scatter 
          data={chartData}
          fill="#8884d8"
        //   line={{ 
        //     stroke: '#0982eb',
        //     strokeWidth: 2
        //   }}
        //   lineType="monotone"
          dataKey="views"
          isAnimationActive={true}
          animationDuration={2000}
        />
      </ScatterChart>
    </ResponsiveContainer>
  );
}