import React, { useEffect, useState, useMemo } from 'react';
import { Text, Box, Group, Stack } from '@mantine/core';
import { IconChartScatter } from '@tabler/icons-react';
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  ReferenceArea,
} from 'recharts';
import { VideoClassification, VideoClassificationCategory, ChannelMedians } from '../../../utils/api';
import { ClassificationBadge } from './VideoClassificationBadge';
import { ContentTypeBadge } from '../components/ContentTypeBadge';
import { getChannelVideos } from '../../../utils/api';
import { formatNumber, formatPercentage } from '../../../utils/formatter';

interface ClassificationScatterProps {
  classifications: VideoClassification[];
  medians: ChannelMedians;
  channelName: string;
  channelId: string;
}

interface ExtendedChannelMedians extends ChannelMedians {
    totalViewsPercentiles: {
      p10: number;
      p50: number;
      p90: number;
    };
  }

interface EnhancedClassification extends VideoClassification {
  title?: string;
  contentType?: string;
}

const classificationColors: Record<VideoClassificationCategory, string> = {
  'UNICORN': '#FF1493',
  'STAR': '#FFD700',
  'EVERGREEN_PLUS': '#228B22',
  'EVERGREEN': '#006400',
  'ROCKET': '#FF4500',
  'ZOMBIE': '#9370DB',
  'FOSSIL': '#808080'
};

const CustomTooltip: React.FC<any> = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload as EnhancedClassification;
    return (
      <Box p="md" bg="white" style={{ border: '1px solid #ccc', borderRadius: '4px' }}>
        <Text size="sm" fw={500} mb="xs" lineClamp={2}>{data.title || 'Unknown Title'}</Text>
        <Stack gap="xs">
        <ClassificationBadge classification={data} />
          {data.contentType && <ContentTypeBadge type={data.contentType} />}
        </Stack>
        <Text size="sm" mt="xs">Total Views: {formatNumber(data.metrics.totalViews)}</Text>
        <Text size="sm">% of Total Views in Week 1: {formatPercentage(data.metrics.firstWeekViewsRatio)}</Text>
        {data.metrics.dailyViewChange !== null && (
          <Text size="sm">Daily Change: {formatNumber(data.metrics.dailyViewChange)}</Text>
        )}
      </Box>
    );
  }
  return null;
};

export const ClassificationScatter: React.FC<ClassificationScatterProps> = ({
  classifications,
  medians: rawMedians,
  channelName,
  channelId
}) => {
  const medians = rawMedians as ExtendedChannelMedians;
  const [enhancedData, setEnhancedData] = useState<EnhancedClassification[]>([]);

  // Calculate max total views for reference area bounds
  const maxTotalViews = useMemo(() => {
    return Math.max(
      ...classifications.map(c => c.metrics.totalViews)
    ) * 1.1; // Add 10% padding
  }, [classifications]);

  useEffect(() => {
    const enhanceClassifications = async () => {
      try {
        const videoDetails = await getChannelVideos(channelId, 'All');
        const enhanced = classifications.map((classification: VideoClassification) => ({
          ...classification,
          title: videoDetails.find((v: { id: string }) => v.id === classification.videoId)?.title,
          contentType: videoDetails.find((v: { id: string }) => v.id === classification.videoId)?.type
        }));
        setEnhancedData(enhanced);
      } catch (error) {
        console.error('Error fetching video details:', error);
        setEnhancedData(classifications); // Fallback to original data
      }
    };

    enhanceClassifications();
  }, [classifications, channelId]);

  // Group data by classification for separate scatters
  const groupedData = enhancedData.reduce((acc, item) => {
    const category = item.classification.category;
    if (!acc[category]) acc[category] = [];
    acc[category].push(item);
    return acc;
  }, {} as Record<VideoClassificationCategory, EnhancedClassification[]>);

  const formatNumber = (value: number) => {
    if (value >= 1_000_000) return `${(value / 1_000_000).toFixed(1)}M`;
    if (value >= 1_000) return `${(value / 1_000).toFixed(1)}K`;
    return value.toString();
  };

  return (
    <Box>
      <Group align="center" mb="md">
        <IconChartScatter size={20} />
        <Text size="md" fw={500}>Video Performance Classification for {channelName}</Text>
      </Group>

      <ResponsiveContainer width="100%" height={600}>
        <ScatterChart
          margin={{ top: 20, right: 20, bottom: 60, left: 60 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            type="number"
            dataKey={(c: EnhancedClassification) => c.metrics.totalViews}
            name="Total Views"
            label={{
              value: "Total Views",
              position: "bottom",
              offset: 40
            }}
            tickFormatter={formatNumber}
            domain={[0, maxTotalViews]}
          />
          <YAxis
            type="number"
            dataKey={(c: EnhancedClassification) => c.metrics.firstWeekViewsRatio}
            name="First Week Ratio"
            label={{
              value: "% of Total Views in Week 1",
              angle: -90,
              position: "insideLeft",
              offset: -20
            }}
            tickFormatter={(value) => `${value.toFixed(1)}%`}
          />

          <Tooltip content={<CustomTooltip />} />

          {/* Reference lines for medians */}
          {/* Total views median line */}
          <ReferenceLine
            x={medians.totalViews}
            stroke="#666"
            strokeDasharray="3 3"
          />
          {/* First week ratio median line */}
          <ReferenceLine
            y={medians.firstWeekRatio}
            stroke="#228B22"
            strokeDasharray="3 3"
          />
          {/* Unicorn threshold line */}
          <ReferenceLine
            x={medians.totalViewsPercentiles.p90}
            stroke="#FF1493"
            strokeDasharray="3 3"
          />

          {/* Reference Areas */}
          {/* Unicorn */}
          <ReferenceArea
            x1={medians.totalViewsPercentiles.p90}
            x2={maxTotalViews}
            y1={0}
            y2={100}
            fill="#FF1493"
            fillOpacity={0.05}
          />
          {/* Star */}
          <ReferenceArea
            x1={medians.totalViews}
            x2={medians.totalViewsPercentiles.p90}
            y1={medians.firstWeekRatio}
            y2={100}
            fill="#FFD700"
            fillOpacity={0.1}
          />
          {/* Evergreen */}
          <ReferenceArea
            x1={medians.totalViews}
            x2={medians.totalViewsPercentiles.p90}
            y1={0}
            y2={medians.firstWeekRatio}
            fill="#228B22"
            fillOpacity={0.1}
          />
          {/* Rocket */}
          <ReferenceArea
            x1={0}
            x2={medians.totalViews}
            y1={medians.firstWeekRatio}
            y2={100}
            fill="#FF4500"
            fillOpacity={0.1}
          />
          {/* Fossil */}
          <ReferenceArea
            x1={0}
            x2={medians.totalViews}
            y1={0}
            y2={medians.firstWeekRatio}
            fill="#808080"
            fillOpacity={0.1}
          />

          {/* Scatter plots for each classification */}
          {Object.entries(groupedData).map(([category, data]) => (
            <Scatter
              key={category}
              name={category}
              data={data}
              fill={classificationColors[category as VideoClassificationCategory]}
              isAnimationActive={true}
              animationDuration={2000}
            />
          ))}
        </ScatterChart>
      </ResponsiveContainer>
    </Box>
  );
};